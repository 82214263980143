import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { Segment } from 'semantic-ui-react';

import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import navigation from '@constants/navigation';
import {DeleteLicensor} from '@graphql/licensor/mutation';
import {GetLicensorsForView} from '@graphql/licensor/query';
import {GetCountries} from '@graphql/country/query';

import LicensorTable from '../components/LicensorTable';
import LicensorFilters from '../components/LicensorFilters';
import LicensorIndexButtons from '../components/LicensorIndexButtons';
import {exportExcel as licensorExportExcel} from '../utils/export/licensorExcel';
import {DEFAULT_FILTERS_VALUES} from '../../licensee/views/LicenseeIndex';

export class LicensorIndex extends React.Component{
    static propTypes = {
        areFiltersVisible: PropTypes.bool,
        DataLicensor: PropTypes.object.isRequired,
        DataCountries: PropTypes.object.isRequired,
        DeleteLicensor: PropTypes.func.isRequired,
        filters: PropTypes.shape({
            search: PropTypes.string,
            countryId: PropTypes.arrayOf(PropTypes.string),
        }),
        loadingRefetch: PropTypes.bool.isRequired,
        Modal: PropTypes.object,
        Menu: PropTypes.object,
        MessageBox: PropTypes.object,
    };

    static defaultProps = {
        filters: {
            search: null,
            countryId: [],
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            licensors: undefined,
            countries: [],
        };
    }

    componentDidMount() {
        document.title = 'Licensors - AV-CMP';
        this.props.Menu.storeMenu(navigation.mediaRights.key);
    }

    componentWillUnmount() {
        document.title = 'AVCMP';
    }

    componentDidUpdate(prevProps) {
        if (prevProps.DataCountries !== this.props.DataCountries || prevProps.DataLicensor !== this.props.DataLicensor) {
            const { DataCountries: { countries }, DataLicensor: { licensors } } = this.props;
            const nextState = {};

            if (countries && this.state.countries !== []) {
                nextState.countries = countries.map((country) =>
                    ({key: country.id, text: country.name.capitalizeFirstLetter(), value: country.id}));
            }

            if (licensors) {
                nextState.licensors = licensors;
            }

            this.setState(() => nextState);
        }
    }

    render() {
        const loading = (
                this.props.DataCountries.loading
                || this.props.DataLicensor.loading
                || this.props.loadingRefetch
            ),
            filtersProps = {
                loading: loading,
                data: {
                    countries: this.state.countries,
                    isVisible: this.props.areFiltersVisible,
                },
            };

        return (
            <div>
                <HeaderRenderer
                    buttons={LicensorIndexButtons}
                    exportExcelParams={licensorExportExcel(this.state.licensors)}
                    filters={LicensorFilters}
                    filtersProps={filtersProps}
                    filtersButtonName='Licensors'
                    loading={loading}
                    messagesBoxNames='licensorMessage'
                    pageTitle='Licensors'
                />
                <Segment basic className='content__table'>
                    <LicensorTable
                        licensors={this.state.licensors}
                        loading={loading}
                        onLicensorDelete={this.handleLicensorDelete}
                    />
                </Segment>
            </div>
        );
    }
}

const LicensorsWithQuery = compose(
    graphql(GetLicensorsForView, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    search: props.filters.search || LicensorIndex.defaultProps.filters.search,
                    country_id: props.filters.countryId || LicensorIndex.defaultProps.filters.countryId,
                },
            };
        },
        name: 'DataLicensor',
    }),
    graphql(GetCountries, {
        options: () => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'cache-and-network',
            };
        },
        name: 'DataCountries',
    }),
    graphql(DeleteLicensor, { name: 'DeleteLicensor' })
)(FilterQueryWrapper(LicensorIndex, {
    queryForRefresh: 'DataLicensor',
    filterUrls: ['licensors.index'],
}));

const mapDispatchToProps = mapModulesToProps(['MessageBox', 'Modal', 'Menu']);

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'Licensors', LicensorIndex.defaultProps.filters),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(LicensorsWithQuery, DEFAULT_FILTERS_VALUES)
);
