import EventsNonSportradarModel from './EventsNonSportradarModel';
import validate from './validator';
import {applyPresetFieldsForModel, presetFieldsForModel} from './EventModel';

export default function NonSportradarEventModel(isEdit = false) {
    const model = EventsNonSportradarModel();
    const presetFields = applyPresetFieldsForModel(isEdit);

    model.fields.event_preset = presetFields.event_preset;

    if (true === isEdit) {
        model.fields.as_additional_contents = presetFields.as_additional_contents;
    }

    model.validate = validate;

    delete model.fields.multiple[0].description.props.required;
    delete model.fields.end_datetime.props.required;

    model.fields = {...model.fields, ...presetFieldsForModel()};

    return model;
}
