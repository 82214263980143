import React from 'react';
import {isEmpty} from 'lodash';
import {useQuery} from '@apollo/client';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {showModal} from '@utils/modal';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import WnsStreamIndexButtons from '@modules/wnsStream/components/WnsStreamIndexButtons';
import {wnsStreamsExportExcel} from '@modules/wnsStream/utils/export/wnsStreamIndexExport';
import WnsStreamTable from '@modules/wnsStream/components/WnsStreamTable';
import {GetWorldNumberServicesForTable} from '@graphql/wnsStream/query';
import {renderModalError} from '@utils/forms';
import {getLink} from '@appComponents/Link';
import navigation from '@constants/navigation';
import mapModulesToProps from '@utils/mapModulesToProps';

export function WnsStreamsIndex({
    Menu,
}) {
    const {
        data: {
            worldNumberServices,
        } = {},
        error,
        loading: isLoading,
    } = useQuery(GetWorldNumberServicesForTable, { fetchPolicy: 'no-cache' });

    if (!isEmpty(error)) {
        showModal({
            isVisible: true,
            content: renderModalError('World Number Service', getLink('dashboard'), error.message, 'Back to mainpage'),
        });
    }

    React.useEffect(() => {
        Menu.storeMenu(navigation.lcrChannels.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                messagesBoxNames={'wnsMessageBox'}
                pageTitle='World Number Service streams'
                buttons={WnsStreamIndexButtons}
                exportExcelParams={wnsStreamsExportExcel(worldNumberServices)}
            />
            <WnsStreamTable
                data={worldNumberServices}
                loading={isLoading}
            />
        </div>
    );
}

WnsStreamsIndex.propTypes = {
    Menu: PropTypes.object,
};

export default withRouter(connect(null, mapModulesToProps(['Menu']))(WnsStreamsIndex));
