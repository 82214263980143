import {withApollo} from 'react-apollo';

import DefaultForm from '../../../app/components/DefaultForm';
import {getLink} from '../../../app/components/Link';
import {convertToInt} from '../../../../utils/helpers';
import {generateUsageTypesTree} from '../../../../utils/usageTypesTreeStructureGenerator';
import {getInitialSelectedUsageTypes, getInitialSavedUsageTypes} from '../../../../utils/usageTypesTreeSelectedValues';
import {getProductsFromEventAndRightScopes} from '../../../../utils/rightsScopeProducts';
import {GetOptionsForUsageTypes, GetSelectedUsageTypes} from '../../../../graphql/eventUsageTypes/query.graphql';

export class EventUsageTypesForm extends DefaultForm {
    state = {
        ...this.state,
        showSaveButton: true,
    };

    componentDidMount() {
        this.setUpdateSuccessCallback(() => {
            getLink('events.configuration.index', {
                id: this.props.formData.event.id,
            });
        });

        const eventSelectedUsageTypesPromise = new Promise((resolve) => {
                this.runApolloRequest('query', {
                    query: GetSelectedUsageTypes,
                    fetchPolicy: 'no-cache',
                    variables: {
                        eventId: convertToInt(this.props.formData.event.id),
                    },
                }).then((resultSelectedUsageTypes) => {
                    resolve(resultSelectedUsageTypes.data);
                }).catch(() => {
                    throw 'Problem with receive selected usage types for event';
                });
            }),
            usageTypesPromise = new Promise((resolve) => {
                this.runApolloRequest('query', {
                    query: GetOptionsForUsageTypes,
                    fetchPolicy: 'network-only',
                    variables: {
                        propertyLicence: convertToInt(this.props.formData.event.property_licence.id),
                    },
                }).then((resultOptionsUsageTypes) => {
                    resolve(resultOptionsUsageTypes.data);
                }).catch(() => {
                    throw 'Problem with receive options for usage types';
                });
            });

        Promise.all([eventSelectedUsageTypesPromise, usageTypesPromise])
            .then((result) => {
                /**
                 * Promise result from selected usage types
                 * @type {Object[]}
                 * **/
                const resultEventSelectedUsageTypes = result[0],
                    /**
                     * Promise result from available usage types
                     * @type {Object[]}
                     * **/
                    resultUsageTypes = result[1],
                    /**
                     * Get products (int[]) for render correct usage types tree
                     * @type {int[]}
                     */
                    productsByEventAndRightsScope = getProductsFromEventAndRightScopes(this.props.formData.event, resultUsageTypes.rightsScopes)
                        .map((productId) => convertToInt(productId)),
                    /**
                     * Tree structure for "usage types"
                     * @type {Object[]}
                     */
                    usageTypesTree = generateUsageTypesTree(
                        resultUsageTypes.products,
                        productsByEventAndRightsScope,
                        getInitialSelectedUsageTypes(resultEventSelectedUsageTypes.event.usage_types),
                        getInitialSavedUsageTypes(resultEventSelectedUsageTypes.event.usage_types)
                    );

                const isUsageType = Boolean(usageTypesTree.length);

                this.setField('usage_types', {
                    options: usageTypesTree,
                    showNoDataText: !isUsageType,
                    noDataText: 'There are no products with usage type support.',
                });
                this.setState(() => ({
                    showSaveButton: isUsageType,
                }));
            })
            .catch(() => {
                this.setField('usage_types', {
                    options: [],
                    showNoDataText: true,
                    noDataText: 'Problem with loading data. Please try again or contact support.',
                });
            });
    }

    /**
     * Prepare data before send it to mutation
     * @param {Object[]} submittedTreeData
     * @returns {{eventId: (number), usage_types: [], id: (null|number)}}
     */
    prepareDataForSubmit = (submittedTreeData) => {
        let dataToSave = [];

        if (undefined === submittedTreeData) {
            return Object.assign({}, this.props.Model.dataMap[this.props.Model.entityDataMapKey], submittedTreeData);
        }

        submittedTreeData.usage_types.forEach((usageTypesList) => {
            usageTypesList.children.forEach((usageType) => {
                dataToSave.push({
                    id: convertToInt(usageType.id),
                    product: convertToInt(usageTypesList.value),
                    usage_type: convertToInt(usageType.value),
                });
            });
        });

        return {
            id: convertToInt(this.props.formData.event.id),
            eventId: convertToInt(this.props.formData.event.id),
            usageTypes: dataToSave,
        };
    };

    /**
     * It's necessary because when ID is passed to the form, Delete button is created by default
     * @returns {null}
     */
    renderDeleteButton = () => null;

    renderSaveButton = () => {
        if (this.state.showSaveButton) {
            return super.renderSaveButton({content: 'Save'});
        }

        return null;
    };
}

export default withApollo(EventUsageTypesForm);
