import {gql} from 'react-apollo';

import apolloClient from '../apolloClient';
import * as actionTypes from './actionTypes';
import urls from '../modules/app/constants/urls';
import responseStatus from '../modules/app/constants/responseStatuses';
import {GetAuthenticatedUser} from '../graphql/user/query.graphql';

export const reportError = (data) => {
    const body = `
        URL: ${data.url} <br/>
        Error:\n ${data.error}
    `;

    if ('avcmp.sportradar.com' !== window.location.host)
    {
        return;
    }

    apolloClient.mutate({
        mutation: gql`mutation createErrorLog($data: String!) {
            createErrorLog(data: $data)
        }`,
        variables: {
            data: body,
        },
    });
};

export const authenticationCheck = () => {
    return {
        type: actionTypes.AUTHENTICATION_CHECK,
    };
};

export const saveMessage = (data) => {
    return {
        type: actionTypes.SAVE_MESSAGE_BOX,
        data: data,
    };
};

export const clearMessage = (data) => {
    return {
        type: actionTypes.CLEAR_MESSAGE_BOX,
        data: data,
    };
};

export const setClosedTimestamp = (data) => ({
    data: data,
    type: actionTypes.SET_CLOSED_TIMESTAMP,
});

export const authenticationFailure = () => {
    return {
        type: actionTypes.AUTHENTICATION_FAILURE,
    };
};

export const saveUser = (userData) => {
    return {
        type: actionTypes.SAVE_USER,
        data: userData,
    };
};

export const fetchAuthenticationFailure = (errorMessage) => {
    return {
        type: actionTypes.FETCH_AUTHENTICATION_FAILURE,
        errorMessage: errorMessage,
    };
};

export const authenticationSuccess = () => {
    return {
        type: actionTypes.AUTHENTICATION_SUCCESS,
    };
};

export const setFilter = (params = {}) => {
    return {
        type: actionTypes.SET_FILTER,
        data: params,
    };
};

export const setInitialFilter = (params = {}) => {
    return {
        type: actionTypes.SET_INITIAL_FILTER,
        data: params,
    };
};

export const resetFilter = (name) => {
    return {
        type: actionTypes.RESET_FILTER,
        data: name,
    };
};

export const toggleFiltersVisibility = (key = {}) => {
    return {
        type: actionTypes.FILTERS_VISIBILITY,
        data: key,
    };
};

export const sessionEnd = () => ({type: actionTypes.SESSION_END});

export const setModal = (params = {}) => {
    return {
        type: actionTypes.SET_MODAL,
        data: params,
    };
};

export const setModalConfirmation = (params = {}) => {
    return {
        type: actionTypes.SET_MODAL_CONFIRMATION,
        data: params,
    };
};

export const setModalError = (params = {}) => {
    return {
        type: actionTypes.SET_MODAL_ERROR,
        data: params,
    };
};

export const redirectToAuthLogin = (fromUrl, test = false) => {
    return {
        type: actionTypes.REDIRECT_TO_AUTH_LOGIN,
        fromUrl: fromUrl,
        test: test,
    };
};

export const paginate = (data) => {
    return {
        type: actionTypes.PAGINATOR_PAGINATE,
        data: data,
    };
};

export const paginatorChangePage = (index) => {
    return {
        type: actionTypes.PAGINATOR_CHANGE_PAGE,
        index: index,
    };
};

export const paginatorSort = (column) => {
    return {
        type: actionTypes.PAGINATOR_SORT,
        column: column,
    };
};

export function validateAuthentication() {
    return function (dispatch) {
        dispatch(authenticationCheck());

        return fetch(urls.authValidate, {
            credentials: 'same-origin',
        })
            .then(response => {
                if (responseStatus.HTTP_OK !== response.status) {
                    throw new Error('Bad response from server');
                }

                return response.json();
            })
            .then(
                json => {
                    if (!json.is_authenticated) {
                        dispatch(authenticationFailure());
                    } else {
                        apolloClient.query({
                            query: GetAuthenticatedUser,
                            fetchPolicy: 'network-only',
                        }).then(response => {
                            if(dispatch(saveUser(response.data.authenticatedUser))) {
                                dispatch(authenticationSuccess());
                            }
                        }).catch(response => {
                            dispatch(fetchAuthenticationFailure('GraphQL ' + response.toString()));
                        });
                    }
                }
            ).catch((error) => {
                dispatch(fetchAuthenticationFailure(error.toString()));
            });
    };
}


export const storeEntity = (entity = {}) => {
    return {
        type: actionTypes.ENTITY_STORE,
        data: entity,
    };
};

export const removeEntity = (name) => {
    return {
        type: actionTypes.ENTITY_REMOVE,
        name: name,
    };
};

export const storeMenu = (key) => {
    return {
        type: actionTypes.MENU_STORE,
        key: key,
    };
};

export const saveMenu = (key) => {
    return {
        type: actionTypes.MENU_SAVE,
        key: key,
    };
};

export const toggleSidebar = (key) => {
    return {
        type: actionTypes.MENU_TOGGLE,
        key: key,
    };
};

export const setTimezone = (timezone) => {
    return {
        type: actionTypes.SET_TIMEZONE,
        data: timezone,
    };
};

export const setRedirectLoaderVisibility = (params = {}) => {
    return {
        type: actionTypes.SET_REDIRECT_LOADER_VISIBILITY,
        params,
    };
};

export const setScrollPosition = (params = {}) => {
    return {
        type: actionTypes.SET_SCROLL,
        params,
    };
};

export const setTableParamsToStore = (params) => {
    return {
        type: actionTypes.SET_TABLE_PARAMS,
        params,
    };
};

export const toggleStreamPlaybackDebugMode = () => ({type: actionTypes.TOGGLE_STREAM_PLAYBACK_DEBUG_MODE});
