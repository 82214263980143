import PropTypes from 'prop-types';

import {SPORTS_COURT_LIST} from '@constants/variables';

import {convertToInt, readNested} from './helpers';
import {addHasMatchIdToStructure} from './eventObject';

/**
 * Check if sport is playing on court.
 *
 * @param {(int | string)} sportId - Sport ID, string will be parsed to int.
 *
 * @return {Boolean}
 */
export const isCourtSport = (sportId = null) => {
    return SPORTS_COURT_LIST.includes(convertToInt(sportId));
};

export const getSport = (event) => {
    if (event._hasMatchID) {
        return readNested(event.sr_event, ['match', 'tournament', 'tournament_category', 'sport'], {});
    }

    return readNested(event.sr_event, ['tournament', 'tournament_category', 'sport'], {});
};

/**
 * Get sport object {id, name} from event
 *
 * @param {Object} event Event object
 */
export const getSportObject = (event) => {
    event = addHasMatchIdToStructure(event);

    return getSport(event);
};

/**
 * Get sport name from event object.
 *
 * @param {object} event Event object.
 *
 * @return {String}
 */
export const getSportNameFromEvent = (event) => (
    getSport(addHasMatchIdToStructure(event)).name || '-'
);

getSport.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                tournament: PropTypes.shape({
                    tournament_category: PropTypes.shape({
                        sport: PropTypes.string,
                    }),
                }),
            }),
            tournament: PropTypes.shape({
                tournament_category: PropTypes.shape({
                    sport: PropTypes.string,
                }),
            }),
        }),
        _hasMatchID: PropTypes.bool.isRequired,
    }).isRequired,
};

getSport.defaultProps = {
    event: {
        sr_event: {
            match: {
                tournament: {
                    tournament_category: {
                        sport: null,
                    },
                },
            },
            tournament: {
                tournament_category: {
                    sport: null,
                },
            },
        },
    },
};
