import React from 'react';
import {withRouter} from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {Icon, IconInfoCircle} from '@appComponents/IconCollection';
import {OVERLAY_TYPE_SYNERGY_EVO_GFX} from '@constants/variables';
import {renderModalError} from '@utils/forms';
import {validatorTypes} from '@utils/validators';

class OverlayForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback(() => {
            this.pushHistory();
        });
        this.setDeleteSuccessCallback(() => {
            this.pushHistory();
        });
        this.setUpdateSuccessCallback(() => {
            this.pushHistory();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.formValues?.overlay_type
            && this.props.formValues?.overlay_type !== prevProps.formValues?.overlay_type) {
            if (OVERLAY_TYPE_SYNERGY_EVO_GFX === this.props.formValues?.overlay_type) {
                this.setField('url', {
                    labelDescription: <IconInfoCircle
                        tooltip={() => (
                            <div>Placeholder [MATCH_ID] will be replaced by the Sportradar match ID<br/>
                                Placeholder [EVENT_ID] will be replaced by the event ID.<br/>
                                Placeholder [TOKEN] will be replaced by the authentication token.
                            </div>
                        )}
                    />,
                });
            } else {
                this.setField('url', {
                    labelDescription: null,
                });
            }
        }

        if (prevProps.formValues.url !== this.props.formValues.url) {
            const isOverlayUrlValid = !validatorTypes.url.validate(this.props.formValues.url);
            const iconUrl = isOverlayUrlValid
                ? (
                    <Icon
                        className='--cursor-pointer'
                        name='external'
                        onClick={() => window.open(this.props.formValues.url)}
                    />
                )
                : null;

            this.setField('url', {
                description: iconUrl,
            });
        }
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );

    pushHistory = () => {
        this.props.history.push(getLink('propertyLicences.technicalSetup.overlays.index', {
            id: this.props.match.params.id,
        }));
    };

    prepareDataForSubmit = (data) => {
        return Object.assign({}, data, {
            propertyLicenceTechSetup: this.props.formParams.optionsVariables.technicalSetupId,
        });
    };

    renderErrors() {
        this.props.Modal.setModal({
            content: renderModalError(
                'Overlay',
                getLink('propertyLicences.technicalSetup.overlays.index', {
                    id: this.props.match.params.id,
                }),
                'Overlay not found'
            ),
            isVisible: true,
        });
    }

    renderCancelButton = () => null;
}

export default withRouter(OverlayForm);
