import React, {useState, useEffect} from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import {Loader} from 'semantic-ui-react';
import {useQuery} from '@apollo/client';
import {isEmpty as _isEmpty} from 'lodash';

import {GetEmailAlertPreviewData} from '@graphql/eventContent/query';
import EmailAlertPreviewModal from '@modules/eventContentAlert/components/EmailAlertPreviewModal';
import {sendEmailAlertNotification} from '@modules/eventContentAlert/utils/helpers';
import {showErrorModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';

const EmailPreview = ({stepsMethods, submittedData = {}}) => {
    const [dataForPreview, setDataForPreview] = useState(null);
    const [sending, setSending] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const {data, loading, error, refetch} = useQuery(GetEmailAlertPreviewData, {
        fetchPolicy: 'network-only',
        variables: submittedData,
    });

    if (!_isEmpty(error)) {
        showErrorModal({
            header: 'Error',
            redirectLink: getLink('livecontrol.index'),
            text: 'There was an error while fetching the data.',
            errorButtonIcon: 'arrow circle left',
            errorButtonText: 'Go back to live control',
        });

        setIsFetching(false);
    }

    useEffect(() => {
        if (data) {
            setDataForPreview(JSON.parse(data.eventContentAlertPreview));
            setIsFetching(false);
        }
    }, [data]);

    useEffect(() => {
        if (!_isEmpty(submittedData)) {
            setIsFetching(true);

            refetch()
                .then(() => {
                    setIsFetching(false);
                })
                .catch(() => {
                    setIsFetching(false);
                    showErrorModal({
                        header: 'Error',
                        redirectLink: getLink('livecontrol.index'),
                        text: 'There was an error while fetching the data.',
                        errorButtonIcon: 'arrow circle left',
                        errorButtonText: 'Go back to live control',
                    });
                });
        }
    }, [submittedData, refetch]);

    const emailContent = {
        ...dataForPreview,
        body: dataForPreview?.body?.replace('<table>', '<table class="ui celled striped compact table">'),
    };
    const sanitizedContent = DOMPurify.sanitize(emailContent.body);
    const isLiveControl = window.location.pathname.includes('live-control');

    const handleBackButtonClick = (e) => {
        stepsMethods.showPreviousStep(e);
    };

    if (loading || isFetching || !dataForPreview) {
        return (
            <Loader/>
        );
    }

    return (
        <EmailAlertPreviewModal
            dataForPreview={dataForPreview}
            submittedData={submittedData}
            sanitizedContent={sanitizedContent}
            handleBackButtonClick={handleBackButtonClick}
            sending={sending}
            sendEmail={() => sendEmailAlertNotification({setSending, submittedData, isLiveControl})}
        />
    );
};

EmailPreview.propTypes = {
    submittedData: PropTypes.object,
    stepsMethods: PropTypes.object,
};

export default EmailPreview;
