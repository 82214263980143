import {EVENT_GROUP_NOTIFICATION} from '@constants/resources';
import {EVENT_CONTENT_BOOKED_BY_CLIENT, EVENT_CONTENT_OFFERED_BY_CLIENT} from '@constants/variables';
import {CreateEventGroupNotification} from '@graphql/notifications/mutation';

import NotificationModel from './NotificationModel';
import {validate} from './validatorEventNotification';

export default function EventGroupNotificationModel() {
    const eventGroupNotificationModel = NotificationModel();

    eventGroupNotificationModel.messages.boxName.success = 'eventsIndex';
    eventGroupNotificationModel.messages.boxName.error = 'eventsIndex';

    eventGroupNotificationModel.resource = EVENT_GROUP_NOTIFICATION;
    eventGroupNotificationModel.entityLabel = 'EventGroupNotification';
    eventGroupNotificationModel.formName = 'eventGroupNotification';
    eventGroupNotificationModel.validate = validate;
    eventGroupNotificationModel.mutation.createEntity = CreateEventGroupNotification;
    eventGroupNotificationModel.fields.recipient_type.props.props.options = [
        {key: 'type1', value: EVENT_CONTENT_BOOKED_BY_CLIENT, text: 'At least one event is booked for LCO by the client'},
        {key: 'type2', value: EVENT_CONTENT_OFFERED_BY_CLIENT, text: 'At least one event is offered for LCO to the client'},
    ];

    return eventGroupNotificationModel;
}
