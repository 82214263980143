import React from 'react';
import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

import {Competitors} from '@modules/events/components/EventsTabsLayout/Details/EventDetails/column3/Competitors';
import {round} from '@utils/round';
import {venueCourtLabel, venueCourtValue} from '@utils/venue';
import { getTvChannelNamesFromEvent } from '@utils/tvChannels';

export const getFieldsList = (event) => {
    const fieldsColumn = [
        {
            name: 'Round',
            value: round(event),
        },
        {
            name: venueCourtLabel(event),
            value: venueCourtValue(event),
        },
    ];

    // Display label if sport supports TV Channel - even if particular match doesn't have assigned TV channels
    if (_get(event, 'property_licence.sport.has_tv_channel', false)) {
        fieldsColumn.push({
            name: 'TV channel',
            value: getTvChannelNamesFromEvent(event),
        });
    }

    fieldsColumn.push(
        {
            name: 'Competitors',
            value: <Competitors event={event}/>,
        }
    );

    fieldsColumn.push(
        {
            name: 'Description',
            value: event.description || '-',
        }
    );

    return fieldsColumn;
};

getFieldsList.propTypes = {
    event: PropTypes.shape({
        description: PropTypes.string,
    }).isRequired,
};

getFieldsList.defaultProps = {
    event: {
        description: null,
    },
};
