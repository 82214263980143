import React from 'react';
import PropTypes from 'prop-types';

import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {EVENT} from '@constants/resources';
import {Authorization} from '@appComponents/Authorization';
import {IconEdit} from '@appComponents/IconCollection';
import {CopyToClipboardRenderer} from '@appComponents/CopyToClipboardRenderer';
import {convertToInt} from '@utils/helpers';

import LinkingEventWithMatchOpenPopup
    from '../../../../../../views/EventsTabsLayout/LinkingEventWithMatch/LinkingEventWithMatchOpenPopup';

const authorizationEventUpdateObject = {
    [EVENT]: SECURITY_PRIVILEGES_UPDATE,
};

/**
 * Match ID
 */
export const MatchId = ({event}) => {
    /**
     * If there is:
     * - sportradar event
     * - match id
     */
    if (event.sr_event && event._hasMatchID) {
        return (
            <>
                <CopyToClipboardRenderer text={event.sr_event.match.id} value={event.sr_event.match.id}/>
                <Authorization authorization={authorizationEventUpdateObject}>
                    <LinkingEventWithMatchOpenPopup eventId={convertToInt(event.id)}>
                        <IconEdit className={'black'}/>
                    </LinkingEventWithMatchOpenPopup>
                </Authorization>
            </>
        );
    }

    return event.id;
};

MatchId.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.string.isRequired,
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
        _hasMatchID: PropTypes.bool.isRequired,
    }).isRequired,
};

MatchId.defaultProps = {
    event: {
        sr_event: {
            match: {
                id: null,
            },
        },
    },
};
