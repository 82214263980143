import React from 'react';
import {
    find as _find,
    get as _get,
    has as _has, isEmpty as _isEmpty,
    orderBy as _orderBy,
    union as _union,
} from 'lodash';
import moment from 'moment';

import {alertTemplatesList} from '@graphql/eventContent/query';
import DefaultForm from '@appComponents/DefaultForm';
import {
    EVENT_CONTENT_BOOKED_BY_CLIENT,
    EVENT_CONTENT_OFFERED_BY_CLIENT,
} from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import {WIZARD_TABS_VALIDATION_TEXT} from '@constants/messages';
import EmailPreview from '@modules/eventContentAlert/components/EmailPreview';

class EventContentAlertForm extends DefaultForm {
    prepareDataForSubmit = (data) => {
        return {
            additional_recipients: data.additional_recipients || [],
            eventContentId: convertToInt(this.props.eventContentId),
            template: convertToInt(data.template),
            message: data.message,
            recipients: this.state.recipients,
        };
    };

    setRecipientType = (startDatetime) => {
        let preselectTypes = [EVENT_CONTENT_BOOKED_BY_CLIENT];

        if (moment(startDatetime).isAfter(moment().add('30', 'm'))) {
            preselectTypes.push(EVENT_CONTENT_OFFERED_BY_CLIENT);
        }

        this.setField('recipient_type', {
            defaultValue: preselectTypes,
        });
    };

    getSelectedClients = (clientsArray) => {
        this.setState(() => ({
            recipients: clientsArray,
        }), this.checkRecipients);

        this.setField('recipients', {
            selected: clientsArray,
        });
    };

    parseDistributionsForList = (options) => {
        return _orderBy(
            options,
            ['product.id', 'distribution_type.id'],
            ['ASC', 'ASC']
        ).map((distribution) => {
            let productId = convertToInt(distribution.product.id);

            return {
                key: distribution.id,
                text: `${distribution.product.short_name} - ${distribution.distribution_type.name}`,
                value: productId,
            };
        });
    };

    componentDidMount() {
        this.setRecipientType(this.props.eventContentStartDatetime);
        this.setField('recipients', {
            getSelectedClients: this.getSelectedClients,
        });

        this.setOnChangeCallback({
            type: (data) => {
                this.typeOnChangeCallback(data.value);
            },
            template: (data) => {
                this.templateOnChangeCallback(data.value);
            },
            recipient_type: (data) => {
                this.setState(() => ({
                    recipientsType: data.value,
                }), this.setClientSelection);


            },
            distributions: (data) => {
                this.setState(() => ({
                    distributionsType: data.value,
                }), this.setClientSelection);

            },
            additional_recipients: (data) => {
                this.checkRecipients(data.value);
            },
        });
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate();
        super.receivedPropsValidator(prevProps);

        if (
            prevProps.GraphQLOptionsData.loading !== this.props.GraphQLOptionsData.loading
            && !this.props.GraphQLOptionsData.loading
            && this.props.preFillData
        ) {
            this.setField('type', {defaultValue: this.props.preFillData.type.toString()});
            this.typeOnChangeCallback(this.props.preFillData.type, true);
        }

        if (this.props.GraphQLOptionsData.loading) {
            return;
        }

        if ( 0 < this.props.formValues.distributions.length
            && 0 < this.props.formValues.recipient_type.length
            && !this.state.clientsSelected
        ) {
            this.setClientSelection(this.props);
            this.setState(() => ({clientsSelected: true}));
        }

        if (!this.state.distributionsSelected) {
            let distributionsList = _get(this.props, 'GraphQLOptionsData.distributions', null);

            if (!distributionsList) {
                return;
            }

            let distributions = [];

            distributionsList.forEach((distribution) => {
                distributions = _union(distributions, [convertToInt(distribution.product.id)]);
            });

            this.setField('distributions', {
                defaultValue: distributions,
                options: this.parseDistributionsForList(distributionsList),
            });
            this.setState(() => ({
                distributionsSelected: true,
            }));
        }

        if (!this.state.distributionObjectCreated) {
            let clientsList = _get(this.props, 'GraphQLOptionsData.clientListForEventContentAlerts', null);

            if (!clientsList) {
                return;
            }

            let distributionsObject = {
                [EVENT_CONTENT_BOOKED_BY_CLIENT]: {},
                [EVENT_CONTENT_OFFERED_BY_CLIENT]: {},
            };

            distributionsObject[EVENT_CONTENT_BOOKED_BY_CLIENT] =
                this.setDistributions(clientsList, distributionsObject[EVENT_CONTENT_BOOKED_BY_CLIENT] , 'bookings');
            distributionsObject[EVENT_CONTENT_OFFERED_BY_CLIENT] =
                this.setDistributions(clientsList, distributionsObject[EVENT_CONTENT_OFFERED_BY_CLIENT], 'offerings');

            this.setState(() => ({
                distributionObjectCreated: true,
                clientDistributionsObject: distributionsObject,
            }));
        }
    }

    typeOnChangeCallback = (value, preFillTemplate = false) => {
        this.setField('template', {loading: true, disabled: true});
        this.setField('message', {defaultValue: '', disabled: true});

        this.props.client.query({
            query: alertTemplatesList,
            variables: {
                alertTemplateType: value,
            },
        }).then((result) => {
            let templateProps = {
                disabled: false,
                loading: false,
                options: result.data.eventContentAlertTemplates,
            };

            if (preFillTemplate) {
                Object.assign(templateProps, {defaultValue: this.props.preFillData.template.toString()});
            }

            this.setField('template', templateProps);

            this.setState(() => ({
                templates: result.data.eventContentAlertTemplates,
            }), () => {
                if (preFillTemplate) {
                    this.templateOnChangeCallback(this.props.preFillData.template.toString());
                }
            });
        });
    };

    templateOnChangeCallback = (value) => {
        if (value) {
            let template = _find(this.state.templates, {id: value});

            this.setField('message', {disabled: false, defaultValue: template.message });
        } else {
            this.setField('message', {disabled: true});
        }
    };

    setClientSelection = (props = this.props) => {
        let clientsSelection = [];

        props.formValues.distributions.forEach((distribution) => {
            props.formValues.recipient_type.forEach((recipient_type) => {
                let clients = _get(this.state.clientDistributionsObject, `${recipient_type}.1.${distribution}` , []);

                clientsSelection = _union(clientsSelection, clients);
            });
        });

        this.setField('recipients', {selected: clientsSelection});
        this.setState(() => ({
            recipients: clientsSelection,
            defaultForm_disableSubmit: !(0 < clientsSelection.length),
        }));
    };

    renderSaveButton = () => {
        const additionalRecipients = _get(this.props, 'formValues.additional_recipients', []);

        return super.renderSaveButton({
            disabled: 0 === this.state.recipients?.length && _isEmpty(additionalRecipients),
            content: 'Preview',
            icon: null,
        });
    };

    checkRecipients = (additional_recipients = _get(this.props, 'formValues.additional_recipients', [])) => {
        let isFormSubmitDisabled = (
            0 === additional_recipients.length
            && 0 === _get(this.state, 'recipients', []).length
        );

        this.setState(() => ({
            defaultForm_disableSubmit: isFormSubmitDisabled,
        }));
    };

    setDistributions = (clientsList, distributionsObject = {}, key = 'bookings') => {
        clientsList.forEach((client) => {
            (client[key] || []).forEach((item) => {
                if (!_has(distributionsObject, `${item.distribution_type}.${item.product}`)) {
                    distributionsObject[item.distribution_type] = {
                        ...distributionsObject[item.distribution_type],
                        [item.product]: [],
                    };
                }

                let list = distributionsObject[item.distribution_type][item.product];

                distributionsObject[item.distribution_type][item.product] = _union(list, [client.id]);
            });
        });

        return distributionsObject;
    };

    onFormSubmit(formValues) {
        if (!_isEmpty(this.props.stepsMethods) && !this.props.stepsMethods.validateForms()) {
            this.setFormMessage(WIZARD_TABS_VALIDATION_TEXT(
                _get(this.props, 'Model.label', _get(this.props, 'Model.formName', ''))
                    .toLocaleLowerCase()
            ));
        }

        this.setState(() => ({
            defaultForm_errorShowed: false,
        }));

        const data = this.prepareDataForSubmit(formValues);
        this.props.MessageBox.removeMessage(this.state.defaultForm_formValidationMessageBoxName);
        this.props.stepsMethods.setStep({
            id: 'step2',
            pane: {
                content: <EmailPreview stepsMethods={this.props.stepsMethods} submittedData={data}/>,
            },
        });
        this.props.stepsMethods.showStep('step2');
    }
}

export default EventContentAlertForm;
