import React from 'react';

import {
    getClipNames,
    getName,
    getProductShortName,
    getSportNames, getTournamentCategoryNames,
} from '@modules/clipConfiguration/utils/propertyDecorator';
import {Authorization} from '@appComponents/Authorization';
import {IconEdit} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import {CLIP_CONFIGURATION} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';
import {TournamentsCell} from '@modules/clipConfiguration/utils/ClipConfigurationTournamentsCell';

const authorizationObject = {
    [CLIP_CONFIGURATION]: SECURITY_PRIVILEGES_READ,
};

export const tableRowRenderer = (column, row) => {
    switch (column) {
        case 'name':
            return getName(row);
        case 'product':
            return getProductShortName(row);
        case 'sports':
            return getSportNames(row);
        case 'tournamentCategories':
            return getTournamentCategoryNames(row);
        case 'tournaments':
            return (
                <TournamentsCell row={row} limit={10} />
            );
        case 'clips':
            return getClipNames(row);
        case 'actions':
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit
                            size='large'
                            link={getLink('clipConfiguration.edit', {id: row.id})}
                            resource={CLIP_CONFIGURATION}
                        />
                    </div>
                </Authorization>
            );
        default:
            return null;
    }
};
