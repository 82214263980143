import {gql} from '@apollo/client';
import {get as _get, sortBy as _sortBy, isEmpty as _isEmpty} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {
    getPresetList as GraphQLPresetList,
} from '@graphql/events/events';
import {rightsScopesDates as GraphQLRightsScopes} from '@graphql/propertyLicences/rightsScopes';
import {getCoverageOptions} from '@graphql/rightsScope/query';
import {GetStadiumsByTournaments} from '@graphql/stadium/query';
import {setErrorModal} from '@utils/modal';
import {transformMomentDatetimeToDate} from '@utils/date';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {
    COLOR_DEFAULT,
    EVENT_NON_SPORTRADAR_TYPE,
    EVENT_SPORTRADAR_TYPE,
    SPORTRADAR_EXISTING_EVENT,
} from '@constants/variables';

import {SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID} from './SportradarExistingEventByMatchModel';
import {renderModalErrorObject, setCanDeleteState} from '../utils/eventContentHelper';
import RightScope from './logic/RightScope';
import {CANT_DELETE_EVENT} from '../constants/messages';

class EventForm extends DefaultForm {
    addPresetDataForSubmit = (dataToSave, formData = {}) => {
        dataToSave.preset = {};

        if (!formData.preset_save) {
            return dataToSave;
        }

        const preset = {
            name: formData.preset_name,
        };

        if (formData.preset_id) {
            preset.id = convertToInt(formData.preset_id);
        }

        dataToSave.preset = preset;

        return dataToSave;
    };

    backToEventPage = () => {
        this.props.history.push(getLink('events.configuration.index', {id: this.props.formParams.id}));
    };

    getPresetsForPropertyLicence = (propertyLicenceId = null, eventId = null) => {
        this.setField('event_preset', {
            loading: true,
            defaultValue: null,
        });

        let presetsListObject = {};

        this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: gql(GraphQLPresetList),
            variables: {
                propertyLicence: propertyLicenceId && [convertToInt(propertyLicenceId)],
                excludeEvent: eventId && parseInt(eventId, 10),
            },
        }).then((response) => {
            let optionsForDropdown = [],
                presetsObject = {};

            let usedPreset = _get(this.state, `defaultForm_data.event.event_preset.id`, null);

            response.data.eventPresets.forEach((preset) => {
                presetsListObject[preset.id] = preset;

                optionsForDropdown.push({
                    key: preset.id,
                    value: preset.id,
                    text: preset.name + (usedPreset === preset.id ? ' (last applied preset)' : ''),
                });

                presetsObject[preset.id] = preset.event;

            });

            this.setState(() => ({
                presetsListObject,
            }));

            this.setField('event_preset', {
                disabled: false,
                loading: false,
                options: optionsForDropdown,
            });
        });
    };

    getContentCategoriesOptionsForDropdown = (propertyLicenceId, contentCategories, rightsScopes) => {
        let categoriesWithRightScopes = [],
            contentCategoriesArray = _get(contentCategories, `${propertyLicenceId}`, []);

        if (rightsScopes.all) {
            categoriesWithRightScopes = contentCategoriesArray;

            return categoriesWithRightScopes;
        }

        contentCategoriesArray.forEach((item) => {
            if (rightsScopes[parseInt(item.id)]) {
                categoriesWithRightScopes.push(item);
            }
        });

        return categoriesWithRightScopes;
    };

    setContentCategoryOptions = (propertyLicenceId, options = this.state.contentCategoriesOptions) => {
        this.setField('content_category', {
            disabled: true,
            loading: true,
            defaultValue: null,
        });

        this.getRightsScopes(propertyLicenceId).then((response) => {
            if (!propertyLicenceId) {
                return [];
            }

            this.setField('content_category', {
                disabled: false,
                loading: false,
                options: this.getContentCategoriesOptionsForDropdown(propertyLicenceId, options, response),
            });
        });
    };

    setCompetitorField = (competitor, eventType = EVENT_SPORTRADAR_TYPE) => {
        let eventKey = 'sr_event';

        if (eventType === EVENT_NON_SPORTRADAR_TYPE) {
            eventKey = 'av_event';
        }

        const competitorObject = _get(this.state.defaultForm_data, `event.${eventKey}.${competitor}`, null);

        competitorObject && this.setField(`${competitor}`, {
            options: [{
                key: competitorObject.id,
                text: `${competitorObject.name} (${competitorObject.id})`,
                value: competitorObject.id,
            }],
        });
    };

    getStadiumsByTournamentId = (tournamentId) => (
        this.runApolloRequest('query', {
            fetchPolicy: 'network-and-cache',
            query: GetStadiumsByTournaments,
            variables: {
                tournaments: [convertToInt(tournamentId)],
            },
        })
    );

    getStadiumsByTournamentData = (tournamentId) => (
        this.getStadiumsByTournamentId(tournamentId).then((response) => (
            sortDropdownOptionsAlphanumerically(response.data.stadiums.map(stadium => ({
                key: stadium.id,
                text: `${stadium.name}${stadium.city ? ` - ${stadium.city.name}` : ''} (${stadium.id})`,
                value: stadium.id,
            })))
        )).catch(() => {
            this.props.MessageBox.addMessage('formInnerErrorMessage', null, 'Can\'t get stadiums data.', 'error');
        })
    );

    getRightsScopes = (propertyLicenceId) => {
        return this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: gql(GraphQLRightsScopes),
            variables: {
                propertyLicenceId: parseInt(propertyLicenceId, 10),
            },
        }).then((response) => {
            if (!this.state.clipProviders) {
                this.setState(() => ({clipProvidersOptions: response.data.clipProviders}));

                this.setField('clip_provider', {
                    options: this.state.clipProvidersOptions,
                });
            }

            return this.setRightScopes(response.data.rightsScopes);
        }).catch(() => {
            this.setFormMessage('Can\'t get right scopes for selected property licence.');
        });
    };

    setRightScopes(data, key) {
        let rightsScopes = RightScope.perapreRightScopes(data, key);

        this.setState(() => ({
            rightsScopes: rightsScopes,
        }));

        return rightsScopes;
    }

    filterEndDatetime = (date) => {
        date = transformMomentDatetimeToDate(date);

        let startDatetime = _get(this.props, 'formValues.start_datetime', null);

        if (startDatetime) {
            startDatetime = startDatetime.substring(0, 10);
        }

        return date.isSameOrAfter(startDatetime);
    };

    resetCoverageField = () => {
        this.setField('coverage', {
            options: [],
            defaultValue: [],
            timestamp: Date.now(),
        });
    };

    getCoverageOptions = (propertyLicenceId, tournamentId, contentCategoryId, startDate) => {
        this.runApolloRequest('query', {
            fetchPolicy: 'network-only',
            query: getCoverageOptions,
            variables: {
                propertyLicence: convertToInt(propertyLicenceId),
                tournament: convertToInt(tournamentId),
                contentCategory: convertToInt(contentCategoryId),
                startDate: startDate,
            },
        }).then(({data}) => {
            const {coverageOptions: {products}} = data;

            this.setState(() => ({
                coverageOptions: products,
            }));

            if (!_isEmpty(products)) {
                this.setField('coverage', {
                    hidden: false,
                    options: this.state.coverageOptions,
                });
            } else {
                this.hideField('coverage');
            }
        }).catch(() => {
            if (!propertyLicenceId) {
                this.setFormMessage('Invalid parameters. Verify the property licence.');
            }

            if (!startDate) {
                this.setFormMessage('Invalid parameters. Verify start date.');
            }

            if (!tournamentId || !contentCategoryId) {
                this.setFormMessage('Invalid parameters. Verify the tournament/content category.');
            }
        });
    };

    areParamsValid = (prevProps, tournamentId, contentCategory, checkContentCategory, isExistingForm) => {
        const {property_licence, start_datetime, id} = this.props.formValues;
        const hasPropertyLicenceChanged = prevProps.formValues.property_licence !== property_licence;
        const hasStartDatetimeChanged = start_datetime !== prevProps.formValues.start_datetime;
        const hasContentCategoryChanged = contentCategory !== prevProps.formValues.content_category;
        const hasTournamentChanged = tournamentId !== prevProps.formValues.tournament_id;

        if (property_licence && (tournamentId || contentCategory) && start_datetime && hasStartDatetimeChanged && !id) {
            this.resetCoverageField();
        }

        return property_licence && (tournamentId || contentCategory) && start_datetime
            && (hasStartDatetimeChanged
                || (!isExistingForm && checkContentCategory && hasContentCategoryChanged)
                || (!isExistingForm && !checkContentCategory && hasTournamentChanged)
                || (isExistingForm && hasPropertyLicenceChanged)
            );
    };

    setCoverageField = (prevProps, tournamentId, contentCategory, checkContentCategory, isExistingForm) => {
        if (this.areParamsValid(prevProps, tournamentId, contentCategory, checkContentCategory, isExistingForm)) {
            const formValues = this.props.formValues;
            this.getCoverageOptions(
                formValues.property_licence,
                tournamentId,
                contentCategory,
                formValues.start_datetime
            );

            if (formValues.id) {
                const hasStartDatetimeChanged = this.props.formValues.start_datetime !== prevProps.formValues.start_datetime;
                this.prefillCoverageOnEditForm();

                if (prevProps.formValues.start_datetime && hasStartDatetimeChanged
                    && this.props.form !== SPORTRADAR_EXISTING_EVENT) {
                    this.resetCoverageField();
                }
            }
        }
    };

    prefillCoverageOnEditForm() {
        const selectedProducts = this.props.GraphQLEntityData?.event?.products?.map(
            (product) => convertToInt(product.id)
        );

        if (!_isEmpty(selectedProducts)) {
            this.setField('coverage', {
                defaultValue: selectedProducts,
            });
        }
    }

    toggleClipProviderDropdown(prevProps) {
        const {formValues} = this.props;
        const {coverage, start_datetime} = formValues;
        const hasCoverageChanged = coverage !== prevProps.formValues.coverage;

        if (!_isEmpty(coverage) && hasCoverageChanged && start_datetime) {
            this.setClipProvider();

            this.setField('clip_provider', {
                hidden: false,
            });
        } else if (!_isEmpty(prevProps.formValues.coverage) && hasCoverageChanged) {
            this.hideField('clip_provider');
        }
    }

    setClipProvider = () => {
        let selectedClipProvider;
        let selectedCoverages = this.props.formValues.coverage;
        const {coverageOptions} = this.state;

        if (!this.props.formValues.id) {
            selectedCoverages.map((coverageId) => {
                const coverageOption = coverageOptions?.find(option => option.id === coverageId);

                if (coverageOption?.providers?.[0]) {
                    selectedClipProvider = (coverageOption.providers[0]).toString();
                }
            });
        } else if (this.props.formValues.id) {
            selectedClipProvider = this.props.formValues.clip_provider;
        }

        this.setField('clip_provider', {
            defaultValue: selectedClipProvider,
        });
    };

    hideField = (fieldName) => {
        this.setField(fieldName, {
            hidden: true,
        });
    };

    componentWillMount() {
        super.componentWillMount();

        this.setOptionParsers({
            property_licence: (data) => {
                let propertyLicencesList = [];

                for (let i = 0; i < data.length; i++) {
                    const propertyLicence = data[i];
                    let contents = _get(propertyLicence, 'contents', []);

                    if (contents && 0 < contents.length
                        || this.props.Model.formId === SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID) {
                        propertyLicencesList.push({
                            id: propertyLicence.id,
                            key: propertyLicence.id,
                            text: `${propertyLicence.name} - ${propertyLicence.licensor.name}`,
                            value: propertyLicence.id,
                        });
                    }
                }

                return _sortBy(propertyLicencesList, (item) => item.text.toLocaleLowerCase());
            },
        });

        this.setOnChangeCallback({
            'preset_save': (data) => {
                let isPresetOverwriteHidden = true;

                if (!data.value) {
                    isPresetOverwriteHidden = false;
                }

                this.setField('preset_name', {
                    hidden: isPresetOverwriteHidden,
                });

                this.setField('preset_overwrite', {
                    hidden: !(!isPresetOverwriteHidden && !!this.props.formValues.event_preset),
                });
            },
            'preset_overwrite': (data) => {
                let eventPresetId = null;

                if (!data.value) {
                    eventPresetId = this.props.formValues.event_preset;
                }

                this.setField('preset_id', {
                    defaultValue: eventPresetId,
                });
            },
            'event_preset': (data) => {
                let eventPreset = _get(this.state, `presetsListObject.[${data.value}].event`),
                    color = _get(eventPreset, 'color', COLOR_DEFAULT),
                    isPresetOverwriteHidden = true;

                if (data.value) {
                    isPresetOverwriteHidden = false;
                }

                isPresetOverwriteHidden = !(!isPresetOverwriteHidden && !!this.props.formValues.preset_save);

                this.setField('preset_overwrite', {
                    hidden: isPresetOverwriteHidden,
                });

                let presetId = null;

                if (this.props.formValues.preset_overwrite) {
                    presetId = data.value;
                }

                this.setField('preset_id', {
                    defaultValue: presetId,
                });

                if (color) {
                    this.setField('color', {
                        defaultValue: '#' + color,
                    });
                }

                this.setField('description', {
                    defaultValue: _get(eventPreset, 'description', null),
                });

                this.setField('event_status', {
                    defaultValue: _get(this.props, 'formValues.event_status', null),
                    value: _get(this.props, 'formValues.event_status', null),
                });

                this.setField('notes', {
                    defaultValue: _get(eventPreset, 'notes', ''),
                });

                this.setField('preset_name', {
                    defaultValue: _get(this.state, `presetsListObject.[${data.value}].name`, null),
                });

                this.setField('as_additional_contents', {
                    hidden: !data.value,
                });

                const presetProductCoverage = _get(this.state, `presetsListObject.[${data.value}].event.products`, []);

                this.setState(() => ({
                    presetProducts: presetProductCoverage,
                }), () => {
                    this.setField('coverage', {
                        defaultValue: presetProductCoverage.map((product) => convertToInt(product.id)),
                        timestamp: Date.now(),
                    });
                });
            },
        });

        this.setField('start_datetime', {
            configuration: {
                ..._get(this.props, 'Model.fields.start_datetime.props.configuration', {}),
                filterDate: this.filterStartDatetime,
            },
        });

        this.setField('end_datetime', {
            configuration: {
                ..._get(this.props, 'Model.fields.start_datetime.props.configuration', {}),
                filterDate: this.filterEndDatetime,
            },
        });

        this.setValuesParsers({
            color: (value) => {
                if (value) {
                    return '#' + value;
                }

                return null;
            },
        });

        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink('events.configuration.index', {id: response.data.createSrEvent.event.id}));
        });
    }

    setStateHandler = (stateObject) => {
        this.setState(() => (stateObject));
    };

    componentDidMount() {
        const eventId = _get(
            this.props,
            'formParams.id',
            null
        );

        'undefined' == typeof this.state.canDelete
        && eventId
        && setCanDeleteState(eventId, this.setStateHandler, true);
    }

    redirectToEventEditPage = () => {
        window.location.href = getLink('events.edit', {
            id: _get(this.props, 'formParams.id', null),
        });
    };

    renderDeleteButton() {
        return this.state.canDelete
            ? super.renderDeleteButton()
            : super.renderDeleteButton({
                onClick: () => setErrorModal(
                    renderModalErrorObject(CANT_DELETE_EVENT, this.redirectToEventEditPage)
                ),
            });
    }
}

export default EventForm;
