import validators from '@utils/validators';
import {FIELD_REQUIRED, TOO_LONG_STRING_MAX_LENGTH} from '@constants/messages';
import {COMMENT_LIMIT} from '@constants/variables';

export const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.origin_stream_status, 'origin_stream_status'));

    return errors;
};

export const validateMultipleInput = (value) => {
    if (!value) {
        return FIELD_REQUIRED;
    }
};

export const validateCommentLength = (value) => {
    if (value && value.length > COMMENT_LIMIT) {
        return TOO_LONG_STRING_MAX_LENGTH(COMMENT_LIMIT);
    }
};
