import React from 'react';
import PropTypes from 'prop-types';
import {Image} from 'semantic-ui-react';

import {getProperty} from '../../../../../utils/EventsTabsLayout/Details/EventDetails/fields/common';
import urls from '../../../../../../app/constants/urls';

const getImage = (sportId) => {
    const imageUrl = `${urls.sportsImages.small}${sportId}.png`;

    return <Image src={imageUrl} className={'sportImage'}/>;
};

/**
 * Display value for Sport
 */
export const SportImage = ({event}) => {
    if (!event.sr_event) {
        return null;
    }

    const eventData = {
            data: event,
            srWithMatchID: ['match', 'tournament', 'tournament_category', 'sport'],
            srWithoutMatchID: ['tournament', 'tournament_category', 'sport'],
        },
        sportId = getProperty(eventData, 'id');

    return getImage(sportId);
};

SportImage.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.object,
    }).isRequired,
};
