import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';
import {tableRowRenderer} from '@modules/clipConfiguration/components/ClipConfigurationTableRowRenderer';
import {tableColumns} from '@modules/clipConfiguration/components/ClipConfigurationTableColumns';

const ClipConfigurationTable = ({data = [], loading = false}) => {
    return (
        <Table
            name='Clip configuration'
            columns={tableColumns}
            rowRenderer={tableRowRenderer}
            loading={loading}
            data={data}
            defaultSort='name'
            noDataText='No clip configurations found'
            pagination={true}
        />
    );
};

ClipConfigurationTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array,
};

export default ClipConfigurationTable;
