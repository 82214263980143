import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {get as _get} from 'lodash';

import {getLink} from '@appComponents/Link';
import DefaultForm from '@appComponents/DefaultForm';
import {removeMessageBox, showMessageBox} from '@utils/messageBox';

import {MESSAGE_BOX_USERS_IAM_STATUS_ERROR_NAME} from '../utils/variables';

export class EditUserGeneralInformationForm extends DefaultForm {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink('users.edit_resources', {
                id: response.data.updateUser.id,
            }));
        });

        this.setFallbackRoute(getLink('users.index'));
    }

    componentDidUpdate(prevProps) {
        const loading = _get(prevProps, 'GraphQLEntityData.loading', true);

        if (loading) {
            return;
        }

        const currentLoginDateIp = this.props.Model.fields.last_login_ip.props.defaultValue;

        const loginDate = _get(prevProps, 'GraphQLEntityData.user.login_datetime', null);
        const loginIp = _get(prevProps, 'GraphQLEntityData.user.login_ip', null);
        let loginDateIp = '';

        if (null === loginDate && null === loginIp) {
            loginDateIp = 'Has not logged in yet';
        } else {
            if (null !== loginDate) {
                loginDateIp += `${loginDate}`;
            }

            if (null !== loginIp) {
                loginDateIp += ` from IP ${loginIp}`;
            }
        }

        if ('undefined' === typeof currentLoginDateIp || loginDateIp !== currentLoginDateIp) {
            this.setField('last_login_ip', {
                defaultValue: loginDateIp,
            });
        }

        let messageError;

        if (null === _get(prevProps, 'GraphQLEntityData.user.iam_user', null)) {
            messageError = 'This Common-IAM user cannot be found. It might be that it was deleted.';
        } else if (true === _get(prevProps, 'GraphQLEntityData.user.iam_user.blocked', null)) {
            messageError = 'This Common-IAM user is disabled.';
        } else if (true !== _get(prevProps, 'GraphQLEntityData.user.iam_user.accessGranted', null)) {
            messageError = 'This Common-IAM user is not associated to the "AV-CMP" application.';
        }

        if (messageError) {
            showMessageBox(MESSAGE_BOX_USERS_IAM_STATUS_ERROR_NAME,
                `${messageError}`, null, 'error', true);
        } else {
            removeMessageBox(MESSAGE_BOX_USERS_IAM_STATUS_ERROR_NAME);
        }
    }

    prepareDataForSubmit = (data) => super.prepareDataForSubmit(data).user;

    renderSaveButton = () => super.renderSaveButton({content: 'Save'});

    renderCancelButton = () => null;

    renderDeleteButton = () => null;
}

export default withRouter(EditUserGeneralInformationForm);
