import React from 'react';
import PropTypes from 'prop-types';
import {Label} from 'semantic-ui-react';

/**
 * Display text with red color as warning
 */
export const RedLabel = ({label}) => (
    <Label color='red' size='small'>{label}</Label>
);

RedLabel.propTypes = {
    label: PropTypes.string,
};

RedLabel.defaultProps = {
    label: '',
};

/**
 * Display:
 * - yesValue for true (default Yes)
 * - noValue for false (default No)
 * - "-" for NULL
 * allow add red label if valueColorRed defined and return value === valueColorRed
 *
 * @param value Value to compare
 * @param valueColorRed If return value === valueColorRed, we should add red label
 * @param trueValue Value for Yes value (default 'Yes')
 * @param falseValue Value for No value (default 'No')
 * @returns {string|*}
 */
export const ValuesDashRedLabel = ({options}) => {
    const {value = null, valueColorRed = null, trueValue = 'Yes', falseValue = 'No'} = options;

    let returnValue = '';

    /**
     * No value (can be overwrite if noValue passed)
     */
    if (false === value) {
        returnValue = falseValue;
    /**
     * Yes value (can be overwrite if yesValue passed)
     */
    } else if (true === value) {
        returnValue = trueValue;
    /**
     * NULL - display "-"
     */
    } else if (null === value) {
        returnValue = '-';
    }

    /**
     * if we should add red label on value
     */
    if (valueColorRed && valueColorRed === returnValue) {
        return <RedLabel label={returnValue} />;
    }

    return returnValue;
};

ValuesDashRedLabel.propTypes = {
    options: PropTypes.shape({
        value: PropTypes.typ,
        valueColorRed: PropTypes.string,
        trueValue: PropTypes.string,
        falseValue: PropTypes.string,
    }),
};

ValuesDashRedLabel.defaultProps = {
    options: {
        value: null,
        valueColorRed: null,
        trueValue: 'Yes',
        falseValue: 'No',
    },
};
