import React from 'react';
import PropTypes from 'prop-types';
import {Button, Icon} from 'semantic-ui-react';

import Link from '@appComponents/Link';

const ClientPackageBackButton = (props) => {
    return (
        <div className='indexButtons'>
            { !props.packageId ? null : (
                <Link name='clients.packages.duplicate' params={{id: props.clientId, packagesId: props.packageId}}>
                    <Button className='button'>
                        <Icon className='clone circle left'/> Duplicate this package
                    </Button>
                </Link>
            )}
            <Link name='clients.packages.index' params={{id: props.clientId}}>
                <Button className='button-back'>
                    <Icon className='arrow circle left'/> Back to packages list
                </Button>
            </Link>
        </div>
    );
};

ClientPackageBackButton.propTypes = {
    clientId: PropTypes.number.isRequired,
    packageId: PropTypes.number,
};

export default ClientPackageBackButton;
