import React from 'react';
import PropTypes from 'prop-types';
import {Icon as SemanticIcon, Message} from 'semantic-ui-react';
import {get as _get} from 'lodash';

/**
 * Display some additional information about time near start/end time
 */
export const GetDateTimeFieldValue = (props) => {
    const {event, matchDateKey, label} = props;

    let eventDatetime = _get(event, matchDateKey, null);

    if (null !== eventDatetime) {
        eventDatetime = eventDatetime.slice(0, -3);
    } else {
        eventDatetime = '-';
    }

    if (false === _get(event, 'sr_event.synchronise_times', false)
        && _get(event, 'sr_event.id', false)
    ) {
        let dateTime = _get(event, `sr_event.match.${matchDateKey}`, null),
            content = eventDatetime;

        if (dateTime) {
            content = (
                <div className='startInfoContainer'>
                    {eventDatetime} <SemanticIcon name='warning circle sign' color='red'/>
                    <Message className='onHoverInfo'>
                        This time is not synchronised with Sportradar!<br/>
                        Original Sportradar {label} time: {dateTime ? dateTime.slice(0, -3) : ''}
                    </Message>
                </div>
            );
        }

        return content;
    }

    return eventDatetime;
};

GetDateTimeFieldValue.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            id: PropTypes.string,
            match: PropTypes.object,
            synchronise_times: PropTypes.bool,
        }),
    }).isRequired,
    matchDateKey: PropTypes.oneOf(['start_datetime', 'end_datetime']).isRequired,
    label: PropTypes.string.isRequired,
};

GetDateTimeFieldValue.defaultProps = {
    event: {
        sr_event: {
            id: null,
            match: null,
            synchronise_times: null,
        },
    },
};
