import React from 'react';
import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

import {convertToInt} from '@utils/helpers';
import {CopyToClipboardRenderer} from '@appComponents/CopyToClipboardRenderer';

import {isSportradarEvent} from '../../../../../utils/eventType';
import LinkingEventWithMatchOpenPopup
    from '../../../../../views/EventsTabsLayout/LinkingEventWithMatch/LinkingEventWithMatchOpenPopup';

/**
 * Display:
 * - event ID
 * - event + linking option
 *
 * for ID field
 */
export const EventId = ({event}) => {
    let eventId = event.id;

    /**
     * Sportradar Event
     * Without match id
     * allow us to link to match ID
     */
    if (isSportradarEvent(event) && !_get(event, 'sr_event.match.id', false)) {
        return (
            <React.Fragment>
                <CopyToClipboardRenderer text={eventId} value={eventId}/>
                &nbsp; - &nbsp;
                <LinkingEventWithMatchOpenPopup eventId={convertToInt(eventId)}>
                    Link to match ID
                </LinkingEventWithMatchOpenPopup>
            </React.Fragment>
        );
    }

    return <CopyToClipboardRenderer text={eventId} value={eventId}/>;
};

EventId.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.string.isRequired,
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
    }).isRequired,
};

EventId.defaultProps = {
    event: {
        sr_event: {
            match: {
                id: null,
            },
        },
    },
};
