import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty, sortBy as _sortBy} from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import useQuery from '@utils/hooks/useQuery';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {ClientWhitelistHeaderMessage} from '@modules/client/utils/whitelistingHelper';
import {convertToInt, digitsOnly, isUrlParamValid} from '@utils/helpers';
import {GetClientAvapiWhitelistedIps} from '@graphql/clientAvapiWhitelistedIp/query';
import {showErrorModal,showModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';
import {exportExcel} from '@modules/client/utils/export/avApiAccessWhitelistedIpsExcel';
import {prepareStringForCidrSorting} from '@utils/CidrHelpers';
import ClientAvapiWhitelistingIpIndexButtons from '@modules/client/components/ClientAvapiWhitelistingIpIndexButtons';
import ClientAvapiWhitelistingIpTable from '@modules/client/components/ClientAvapiWhitelistingIpTable';
import WhitelistedIpEdit from '@modules/client/views/WhitelistedIpEdit';
import {loadModalForm} from '@utils/disableCidrHelpers';
import {createForm} from '@utils/forms';
import {routes} from '@constants/routes';
import WhitelistedIpForm from '@modules/client/forms/WhitelistedIpForm';
import ClientAvapiModelEdit from '@modules/client/forms/ClientAvavpiModelEdit';

const ClientAvapiIndex = ({match, isVisible, history}) => {
    const [modalVisibility, setModalVisibility] = useState(isVisible);
    const [mutationSuccessCallback, setMutationSuccessCallback] = useState(null);
    const clientId = convertToInt(match.params.id);
    const whitelistedIpId = match.params.whitelistedIpId ? convertToInt(match.params.whitelistedIpId) : null;
    const {data = {}, loading, error} = useQuery({
        query: GetClientAvapiWhitelistedIps,
        options: {
            variables: {
                client: clientId,
                notifyOnNetworkStatusChange: true,
            },
            refetch: mutationSuccessCallback,
        },
    });
    const {
            add,
            edit,
        } = routes.clients.whitelistedIps.avApiAccess,
        whitelistedIpIndexLink = getLink('clients.whitelistedIps.avApiAccess.index', {id: convertToInt(clientId)});

    useEffect(() => {
        if (add.path === match.path && digitsOnly(clientId)) {
            showModal({
                isVisible: true,
                size: 'small',
                content: <WhitelistedIpEdit
                    clientId={clientId}
                    setMutationSuccessCallback={setMutationSuccessCallback}
                    isAvApiAccess={true}
                />,
            });
            setModalVisibility(true);
        } else if (edit.path === match.path && digitsOnly(clientId) && digitsOnly(whitelistedIpId)) {
            loadModalForm(
                createForm(
                    ClientAvapiModelEdit,
                    WhitelistedIpForm,
                    {
                        id: whitelistedIpId,
                        optionsVariables: {
                            client: convertToInt(clientId),
                        },
                        setMutationSuccessCallback,
                        isAvApiAccess: true,
                    }
                ),
                {clientId, whitelistedIpId},
                setModalVisibility
            );
        }
    }, [match.path]);

    useEffect(() => {
        if (isVisible !== modalVisibility && !isVisible) {
            history.push(whitelistedIpIndexLink);
        }
    }, [isVisible]);

    if (!_isEmpty(error)) {
        showErrorModal({
            header: 'Error',
            redirectLink: getLink('clients.edit', { id: clientId }),
            text: 'There was an error while fetching the data.',
            errorButtonIcon: 'arrow circle left',
            errorButtonText: 'Back to the client',
        });
    }

    if (!isUrlParamValid(whitelistedIpId)) {
        showErrorModal({
            header: 'Error',
            redirectLink: getLink('clients.whitelistedIps.avApiAccess.index', { id: clientId }),
            text: 'Whitelisted IP not found',
            errorButtonIcon: 'arrow circle left',
            errorButtonText: 'Back to the list',
        });
    }

    const {clientAvapiWhitelistedIps} = data;
    const AvApiAccessMessage = `
        The IP addresses defined here are the IP addresses of the client servers which need to access the AV-API. 
        The AV-API is not accessible publicly and its access is restricted by IP.
        <br/>
        Please use this feature wisely, whitelist IP addresses as little as possible, and do not use large IP ranges.
    `;

    return (
        <>
            <ClientWhitelistHeaderMessage message={AvApiAccessMessage}/>
            <HeaderRenderer
                buttons={ClientAvapiWhitelistingIpIndexButtons}
                buttonsProps={{client: convertToInt(clientId)}}
                exportExcelParams={exportExcel(_sortBy(clientAvapiWhitelistedIps, [prepareStringForCidrSorting]), clientId)}
                loading={loading}
                messagesBoxNames={['whitelistedIpMessage', 'formInnerErrorMessage']}
            />
            <ClientAvapiWhitelistingIpTable
                clientId={clientId}
                loading={loading}
                clientAvapiWhitelistedIps={clientAvapiWhitelistedIps}
                setMutationSuccessCallback={setMutationSuccessCallback}
            />
        </>
    );
};

ClientAvapiIndex.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    timezone: state.timezone.timezone,
    isVisible: state.modal.isVisible,
});

export default withRouter(connect(mapStateToProps)(ClientAvapiIndex));
