import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import mapModulesToProps from '@utils/mapModulesToProps';

import EnvironmentAwareLogo from './EnvironmentAwareLogo';
import Navigation from './Navigation';
import {navigationWithoutCurrentEnv} from '../utils/menuHelper';

class Sidebar extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        expanded: PropTypes.bool,
        Menu: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.width = {
            expanded: 260,
            offset: 65,
        };
    }

    toggleSidebar = () => {
        this.props.Menu.toggleSidebar(!this.props.expanded);
    };

    render() {
        return (
            <aside className={classnames(
                this.props.className,
                'sidebar',
                this.props.expanded ? 'sidebar--expanded' : 'sidebar--collapsed'
            )}>
                <EnvironmentAwareLogo
                    isExpanded={this.props.expanded}
                    sidebarWidthCollapsed={this.width.offset}
                />
                <Navigation
                    isExpanded={this.props.expanded}
                    menu={navigationWithoutCurrentEnv()}
                    toggleSidebar={this.toggleSidebar}
                    offset={this.width.offset}
                />
                <div className='sidebar__toggle' onClick={this.toggleSidebar}/>
            </aside>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        expanded: state.app.menu.expanded,
    };
};

const SidebarWithRedux = connect(mapStateToProps, mapModulesToProps(['Menu']), null, {pure: false})(Sidebar);

export default SidebarWithRedux;
