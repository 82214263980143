import React from 'react';

import {showErrorModal, showLoadingModal, showModal} from '@utils/modal';
import {getSrEventsWithSamePropertyLicence} from '../views/common/EventsMultipleActions';
import MultipleEventPresetApplyModalContent from '../components/MultipleEventPresetApplyModalContent';

export const showErrorNoEventsForPresetSelected = () => {
    showErrorModal({text: 'There are no events selected.', header: 'Invalid selection to apply preset'});
};

export const showErrorWrongEventsForPresetSelected = () => {
    showErrorModal({
        header: 'Invalid selection to apply preset',
        text: 'Applying a preset to multiple events is possible only for a selection of events which are '
            + 'associated to a same property licence having at least one preset.\nPlease correct your selection.',
    });
};

export const showErrorNoAvailablePresets = () => {
    showErrorModal({
        header: 'Invalid selection to apply preset',
        text: 'There are no available presets for current selection.',
    });
};

export const showErrorNoMatchesSelected = () => {
    showErrorModal({
        header: 'Invalid selection to create Sportradar events',
        text: 'There are no matches selected.',
    });
};

export const showErrorWrongMatchesSelected = () => {
    showErrorModal({
        header: 'Invalid selection to create Sportradar events',
        text: 'Multi event creation is possible only for a selection of matches which have media rights through at '
            + 'least one common property licence.\nPlease correct your selection.',
    });
};

export const showErrorOnSetCoverage = () => {
    showErrorModal({
        header: 'Invalid selection to set coverage',
        text: 'Setting coverage is possible only for a selection of events which are associated to a same property licence and ' +
            'which have media rights for at least one common product with event booking support. Please correct your selection.',
    });
};

export const showErrorNoEventsForSetCoverage = () => {
    showErrorModal({text: 'There are no events selected.', header: 'Invalid selection to set coverage'});
};

export const showMultipleEventPresetApplyModal = (queryForRefetch, eventsIds = []) => {
    showLoadingModal();
    getSrEventsWithSamePropertyLicence(eventsIds, (events) => {
        showModal({
            content: <MultipleEventPresetApplyModalContent
                data={events}
                queryForRefetch={queryForRefetch}
                modelObjectExtension={
                    {messages: {
                        boxName: {
                            delete: 'childEventsIndex',
                            success: 'childEventsIndex',
                        }},
                    }
                }
            />,
            className: 'multipleEventCreation',
            size: 'large',
        });
    });
};
