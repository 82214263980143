import {get as _get} from 'lodash';
import {Popup, TableCell} from 'semantic-ui-react';
import React from 'react';

import {getTournamentNames} from '@modules/clipConfiguration/utils/propertyDecorator';

export const TournamentsCell = ({row, limit = 10}) => {
    const tournaments = _get(row, 'tournaments', []);

    let content;

    if (limit >= tournaments.length) {
        content = (
            <>
                {getTournamentNames(row)}
            </>
        );
    } else {
        content = (
            <Popup
                trigger={(
                    <TableCell
                        as='div'
                        className='cell'
                        content={getTournamentNames(row, limit)}
                    />
                )}
                position={'top center'}
                className='--veryWidePopup'
                hoverable
                wide='very'
                content={(
                    <>
                        {getTournamentNames(row)}
                    </>
                )}
            />
        );
    }

    return content;
};
