import {
    getClipNames,
    getName,
    getProductShortName,
    getSportNames,
    getTournamentCategoryNames,
    getTournamentNames,
} from '@modules/clipConfiguration/utils/propertyDecorator';
import {columnObjectAlignCenter} from '@utils/tableHelpers';

export const tableColumns = {
    name: {
        label: 'Name',
        sorting: (row) => getName(row),
        className: 'nameClipConfigTable',
    },
    product: {
        label: 'Product',
        sorting: (row) => getProductShortName(row),
    },
    sports: {
        label: 'Sports',
        sorting: (row) => getSportNames(row),
    },
    tournamentCategories: {
        label: 'Categories',
        sorting: (row) => getTournamentCategoryNames(row),
    },
    tournaments: {
        label: 'Tournaments',
        sorting: (row) => getTournamentNames(row),
    },
    clips: {
        label: 'Clips',
        sorting: (row) => getClipNames(row),
    },
    actions: {
        ...columnObjectAlignCenter,
        label: 'Actions',
    },
};
