import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';

import {convertToInt} from '@utils/helpers';
import ClientVplLicenceKeyEdit from '@modules/client/views/ClientVplLicenceKeyEdit';
import {routes} from '@constants/routes';

const ClientVplLicenceKeyLayout = () => {
    const match = useRouteMatch();
    const [activeComponent, setActiveComponent] = useState(null);

    useEffect(() => {
        const {id, clientVplLicenceKeyId = null} = match.params;
        const {add, edit} = routes.clients.clientVideoPlayer;

        if (match.path === add.path) {
            setActiveComponent(
                <ClientVplLicenceKeyEdit
                    clientId={convertToInt(id)}
                />
            );
        } else if (match.path === edit.path) {
            setActiveComponent(
                <ClientVplLicenceKeyEdit
                    clientId={convertToInt(id)}
                    clientVplLicenceKeyId={clientVplLicenceKeyId}
                />
            );
        }
    }, [match.path]);

    return (
        <>
            {activeComponent}
        </>
    );
};

export default ClientVplLicenceKeyLayout;
