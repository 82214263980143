import React from 'react';
import PropTypes from 'prop-types';

import StepsContent from '@appComponents/StepsContent';

import EventContentAlertDetails from './EventContentAlertDetails';

const EventContentAlertSteps = (props) => {

    const tabs = [
        {
            id: 'step1',
            key: 'step1',
            menuItem: {content: '', hidden: true},
            pane: {
                content: <EventContentAlertDetails {...props} />,
            },
        },
        {
            id: 'step2',
            key: 'step2',
            menuItem: {content: '', hidden: true},
            pane: {
                content: null,
            },
        },
    ];

    return <StepsContent tabs={tabs} hideHeader={true} hideMenu={true} className={'alertStepsModal'} />;
};

EventContentAlertSteps.propTypes = {
    eventContentId: PropTypes.number.isRequired,
};
export default EventContentAlertSteps;
