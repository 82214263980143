import {get as _get} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {
    COMPETITOR_NOT_DEFINED, COMPETITOR_NOT_DEFINED_BOTH,
    EVENT_STATUS_PLANNED,
    SPORTS_COURT_LIST,
} from '@constants/variables';
import Form from '@appComponents/ReduxFormControls';
import {convertToInt} from '@utils/helpers';
import * as MESSAGES from '@constants/messages';
import {EVENT} from '@constants/resources';

import {applyPresetFieldsForModel, presetFieldsForModel} from '../forms/EventModel';
import {validateForExistingEvent} from './validator';
import * as formUtils from '../utils/formHelper';
import * as eventsGraphQl from '../../../graphql/events/events';
import { TvChannelsCreate } from './fields/TvChannels';

export const SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID = 'SportradarExistingEventAddModel';

export default function SportradarExistingEventByMatchModel(isEdit = false) {
    const renderTournamentInput = (props = {}) => {
        let tournament = '';

        if (_get(props, 'data.match.tournament.tournament_category.sport', false)) {
            const tournamentObject = props.data.match.tournament;

            tournament = `${tournamentObject.tournament_category.sport.name} > 
                ${tournamentObject.tournament_category.name} > 
                ${tournamentObject.name}`;
        }

        return <div><label>Tournament</label>
            <span>{tournament}</span>
        </div>;
    };
    renderTournamentInput.propTypes = {
        data: PropTypes.shape({
            match: PropTypes.object,
        }),
    };

    const renderCompetitorsInput = (props = {}) => {
        let competitorText = '';

        const homeCompetitorName = _get(props, 'data.match.home_competitor.name', COMPETITOR_NOT_DEFINED),
            awayCompetitorName = _get(props, 'data.match.away_competitor.name', COMPETITOR_NOT_DEFINED);

        if (homeCompetitorName === COMPETITOR_NOT_DEFINED && awayCompetitorName === COMPETITOR_NOT_DEFINED) {
            competitorText = COMPETITOR_NOT_DEFINED_BOTH;
        } else if (homeCompetitorName && awayCompetitorName) {
            competitorText = `${homeCompetitorName} : ${awayCompetitorName}`;
        }

        return <div><label>Competitors</label><span>
            {competitorText}
        </span></div>;
    };

    const renderStadiumInput = (props = {}) => {
        const sportId = convertToInt(
            _get(
                props,
                'data.match.tournament.tournament_category.sport.id',
                _get(props, 'data.event.sr_event.match.tournament.tournament_category.sport.id', 0)
            )
        );

        const stadiumCity = _get(props, 'data.match.stadium.city.name', false),
            text = _get(props, 'data.match.stadium.name', '---') + (stadiumCity ? ` - ${stadiumCity}` : '');

        return (
            <div>
                <label>{(SPORTS_COURT_LIST.includes(sportId)) ? 'Court' : 'Venue'}</label>
                <span>{text}</span>
            </div>
        );
    };

    const renderTitle = (props) => {
        const matchId = _get(props, 'match.id', '');

        return <div>Create Sportradar event (match ID {(matchId || '')})</div>;
    };

    return {
        entityLabel: 'Sportradar existing event',
        entityDataMapKey: 'match',
        label: 'Event',
        resources: EVENT,
        messages: {
            boxName: {
                delete: 'eventsIndex',
                success: 'event',
                error: 'eventsIndex',
            },
            text: MESSAGES,
            delete: MESSAGES.EVENT_DELETE_STATUS_TEXT_OBJECT,
            create: {
                ...MESSAGES.EVENT_CREATE_UPDATE_STATUS_TEXT_OBJECT,
                success: (data, dbResponse) => {
                    const id = _get(dbResponse, 'data.createSrEvent.event.id', null);

                    return `The event${id ? ` ${id}` : ''} has been saved successfully.`;
                },
            },
            update: MESSAGES.EVENT_CREATE_UPDATE_STATUS_TEXT_OBJECT,
        },
        title: renderTitle,
        formName: 'Sportradar existing event',
        formId: SPORTRADAR_EXISTING_EVENT_ADD_FORM_ID,
        validate: validateForExistingEvent,
        dataMap: {
            match: {
                start_datetime: '',
            },
            event: {
                sr_event: {
                    synchronise_times: true,
                },
                start_datetime: '',
                end_datetime: '',
                description: '',
                color: '3a87ad',
                event_status: {
                    id: null,
                },
                notes: '',
            },
        },
        mutation: {
            updateEntity: eventsGraphQl.srEventUpdateMutation,
            createEntity: eventsGraphQl.srEventCreateMutation,
            deleteEntity: eventsGraphQl.srEventDeleteMutation,
        },
        query: {
            getEntity: eventsGraphQl.existingSportradarEventByMatch,
            getOptions: eventsGraphQl.optionsQuery,
        },
        fields: {
            id: {
                dataMapKey: null,
                props: {
                    component: Form.SemanticInput,
                    hidden: true,
                    name: 'id',
                },
            },
            match_id: {
                dataMapKey: 'match.id',
                props: {
                    component: Form.SemanticInput,
                    hidden: true,
                    name: 'match_id',
                },
            },
            property_licence: {
                optionsKey: 'property_licences',
                props: {
                    component: formUtils.renderPropertyLicenceField,
                    required: true,
                    label: 'Property licence',
                    name: 'property_licence',
                    placeholder: 'Select property licence',
                    type: 'select',
                },
            },
            ...applyPresetFieldsForModel(isEdit),
            tournament: {
                dataMapkey: null,
                props: {
                    component: renderTournamentInput,
                    label: 'Tournament',
                    name: 'tournament',
                },
            },
            competitors: {
                dataMapKey: null,
                props: {
                    component: renderCompetitorsInput,
                    label: 'Competitors',
                    name: 'competitors',
                },
            },
            stadium: {
                dataMapKey: null,
                props: {
                    component: renderStadiumInput,
                    label: 'Venue',
                    name: 'stadium',
                },
            },
            tvChannels: {
                dataMapKey: null,
                props: {
                    component: TvChannelsCreate,
                    label: 'TV Channel',
                    name: 'tv_channels',
                },
            },
            synchronise: {
                dataMapKey: 'event.sr_event.synchronise_times',
                props: {
                    component: Form.SemanticInput,
                    name: 'synchronise_times',
                    type: 'checkbox',
                    label: 'Synchronise the start time with Sportradar?',
                },
            },
            start_datetime: {
                dataMapKey: 'match.start_datetime',
                props: {
                    component: Form.SemanticInput,
                    configuration: {
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                    },
                    type: 'date',
                    label: 'Start time',
                    required: true,
                    name: 'start_datetime',
                    disabled: true,
                    selected: null,
                },
            },
            end_datetime: {
                dataMapKey: 'event.end_datetime',
                props: {
                    configuration: {
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                    },
                    type: 'date',
                    component: Form.SemanticInput,
                    label: 'End time',
                    name: 'end_datetime',
                    selected: null,
                    disabled: true,
                },
            },
            multiple: [
                {
                    description: {
                        dataMapKey: 'event.description',
                        props: {
                            component: Form.SemanticInput,
                            label: 'Description',
                            name: 'description',
                            type: 'input',
                        },
                    },
                },
                {
                    color: {
                        dataMapKey: 'event.color',
                        props: {
                            component: Form.SemanticInput,
                            name: 'color',
                            type: 'color',
                        },
                        defaultValue: '3a87ad',
                    },
                },
            ],
            status: {
                dataMapKey: 'event.event_status.id',
                optionsKey: 'event_status',
                props: {
                    required: true,
                    component: Form.SemanticSelect,
                    name: 'event_status',
                    label: 'Status',
                },
                defaultValue: EVENT_STATUS_PLANNED + '',
            },
            coverage: {
                props: {
                    component: Form.SemanticCheckboxList,
                    hidden: true,
                    type: 'checkbox',
                    name: 'coverage',
                    label: 'Coverage',
                    allowChildless: true,
                    className: 'inlineCheckboxList',
                },
                defaultValue: [],
            },
            clip_provider: {
                dataMapKey: 'event.clip_provider.id',
                optionsKey: 'clip_provider',
                props: {
                    hidden: true,
                    component: Form.SemanticSelect,
                    label: 'Clip provider',
                    name: 'clip_provider',
                    required: true,
                },
            },
            notes: {
                dataMapKey: 'event.notes',
                props: {
                    component: Form.SemanticTextArea,
                    label: 'Notes',
                    name: 'notes',
                },
            },
            ...presetFieldsForModel(),
        },

    };
}
