import {contentBody} from './content';
import {BasicData} from '../fragments';

export const rightsScopesDates = `
query getRightScopes($propertyLicenceId: ID!) {
  clipProviders {
    id
    value: id
    key: id
    text: name
  }
  rightsScopes(filters: {propertyLicence: $propertyLicenceId}) {
    id
    clip_provider {
      ${BasicData}
    }
    start_date
    end_date
    usages {
      id
      product {
        id
        short_name
        has_event_booking_support
      }
    }
    contents{
      id
      tournament{
        id
        tournament_category {
          id
        }
      }
      content_category{
        id
      }
    }
  }
}`;

export const rightsScopes = `
query($propertyLicenceId: ID!) {
  rightsScopes(filters: {propertyLicence: $propertyLicenceId}) {
    id
    includes_all_content
    from:start_date
    to:end_date
    usages {
      id
      product {
        id
        short_name
      }
    }
    licensee {
      id
      name
    }
    contents {
      ${contentBody}
    }
    territory_selection_type {
      id 
      name 
    }
    countries {
      id
      name
    }
    country_subdivisions {
      id
      name
      iso_code
    }
    rights_scope_exclusivity {
      id
      name
    }
    rights_scope_stream_constraints {
      id
    }
    rights_scope_sub_licensing {
      id
      name
    }
  }
}`;
