import React from 'react';
import moment from 'moment';
import {
    get as _get,
    includes as _includes,
    forEach as _forEach,
    uniq as _uniq,
} from 'lodash';
import {Button, ButtonGroup, Dropdown, Header, Icon, Modal, Popup} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {gql, withApollo} from 'react-apollo';
import {convertToInt, getEncodingLogsInVideoManagementPlatformLink} from '@utils/helpers';
import {Authorization} from '@appComponents/Authorization';
import {ReportWorkingInvoiceableEvents} from '@graphql/reports/mutation';
import {showModalConfirmation} from '@utils/modal';
import {HideEventContent as hideEventContentMutation} from '@graphql/liveControl/mutation';
import {
    BOOKING,
    CLIENT,
    DISTRIBUTION,
    EVENT,
    EVENT_CONTENT,
    EVENT_CONTENT_ALERT,
    EVENT_INVOICE_STATUS,
    EVENT_REPORT,
    LIVE_CONTROL,
    OFFERING,
    STREAM,
} from '@constants/resources';
import {
    ALERT_TEMPLATE_INTERRUPTION_RESOLUTION,
    ALERT_TYPE_INERRUPTION,
    BROADCAST_STATUS_APPROVED,
    BROADCAST_STATUS_DELIVERED,
    BROADCAST_STATUS_ERROR,
    BROADCAST_STATUS_ON_AIR_BROADCAST,
    BROADCAST_STATUS_ON_AIR_READY,
    DEBUG_PLATFORM_LINK,
    ENCODING_END_MARGIN,
    DISTRIBUTION_TYPE_LIVE,
    ENCODING_STATUS_ENCODING,
    ENCODING_STATUS_ENDED,
    ENCODING_STATUS_ERROR,
    ENCODING_STATUS_PREPARING,
    ENCODING_STATUS_SCHEDULED,
    SECURITY_PRIVILEGES_READ,
    EVENT_STATUS_PLANNED,
    SECURITY_PRIVILEGES_UPDATE,
    SECURITY_PRIVILEGES_CREATE,
    SECURITY_PRIVILEGES_CREATE_READ,
    ORIGIN_STREAM_TYPE_HTTP,
    ORIGIN_STREAM_TYPE_RTMP,
    ORIGIN_STREAM_TYPE_SRT, BROADCAST_STATUS_CREATED,
} from '@constants/variables';
import ActionButton from '@appComponents/ButtonsCollection/ActionButton';
import ActionDropdownItem from '@appComponents/ButtonsCollection/ActionDropdownItem';
import {ButtonDropdown} from '@appComponents/ButtonCollection';
import {getLink} from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {refetchQueryByName} from '@utils/apollo';
import mapModulesToProps from '@utils/mapModulesToProps';
import {
    REPORT_MULTIPLE_AS_WORKING_AND_INVOICEABLE_MESSAGE,
    reportMultipleAsWorkingAndInvoiceableErrorHandler,
    reportMultipleAsWorkingAndInvoiceableResponseHandler,
} from '@modules/events/utils/reportMultipleAsWorkingAndInvoiceableHelper';
import EventContentAlertSteps from '@modules/eventContentAlert/views/EventContentAlertSteps';
import {IconListPlay} from '@appComponents/IconCollection';
import {HeaderEventContent} from '@modules/liveControl/views/StreamsPlayback/HeaderEventContent';
import SlateButton from '@modules/liveControl/components/Buttons/Slate';
import {OverlayButton, showOverlayButton} from '@modules/liveControl/components/Buttons/Overlay';
import ModalHeaderEventContentLiveControl from '@modules/liveControl/views/ModalLiveControlHeader';
import * as mutations from '@graphql/liveControl/actions';
import {
    getEventContentInputEncodingStatus,
    getMatchHasEnded,
} from '@graphql/liveControl/index';
import EventContentStreams from '@modules/liveControl/components/StreamsPlayback';

const LiveControlActions = (props) => {
    const {row, timezone} = props;

    const isSetAsDeliveredAutomaticallyAndHasMatch = () => (row.set_as_delivered_automatically && row.match_id);

    const authorizationLiveControlUpdateObject = {
            [LIVE_CONTROL]: SECURITY_PRIVILEGES_UPDATE,
        },
        authorizationLiveControlDistributionUpdateObject = {
            [LIVE_CONTROL]: SECURITY_PRIVILEGES_UPDATE,
            [DISTRIBUTION]: SECURITY_PRIVILEGES_UPDATE,
        },
        authorizationLiveControlEventContentUpdateObject = {
            [LIVE_CONTROL]: SECURITY_PRIVILEGES_UPDATE,
            [EVENT_CONTENT]: SECURITY_PRIVILEGES_UPDATE,
        },
        authorizationReadEventContentObject = {
            [EVENT_CONTENT]: SECURITY_PRIVILEGES_READ,
        },
        authorizationStreamReadObject = {
            [STREAM]: SECURITY_PRIVILEGES_READ,
        },
        authorizationCreateReportObject = {
            [EVENT]: SECURITY_PRIVILEGES_READ,
            [EVENT_REPORT]: SECURITY_PRIVILEGES_CREATE,
            [EVENT_INVOICE_STATUS]: SECURITY_PRIVILEGES_CREATE,
        },
        authorizationReadReportObject = {
            [EVENT]: SECURITY_PRIVILEGES_READ,
            [EVENT_REPORT]: SECURITY_PRIVILEGES_READ,
            [EVENT_INVOICE_STATUS]: SECURITY_PRIVILEGES_READ,
        },
        authorizationCreateAlertObject = {
            [EVENT_CONTENT_ALERT]: SECURITY_PRIVILEGES_CREATE_READ,
            [DISTRIBUTION]: SECURITY_PRIVILEGES_READ,
            [BOOKING]: SECURITY_PRIVILEGES_READ,
            [OFFERING]: SECURITY_PRIVILEGES_READ,
            [CLIENT]: SECURITY_PRIVILEGES_READ,
        },
        authorizationReportAsWorkingAndInvoiceableObject ={
            [EVENT_REPORT]: [SECURITY_PRIVILEGES_CREATE],
            [EVENT_INVOICE_STATUS]: [SECURITY_PRIVILEGES_CREATE],
        };

    const hasMatchEnded = () => {
        return new Promise((resolve, reject) => {
            if (isSetAsDeliveredAutomaticallyAndHasMatch()) {
                props.client.query({
                    fetchPolicy: 'network-only',
                    query: gql(getMatchHasEnded),
                    variables: {
                        id: row.match_id,
                    },
                }).then((result) => {
                    resolve(result.data.match.has_ended);
                }).catch(() => {
                    reject();
                });
            } else {
                resolve(false);
            }
        });
    };

    const showModal = (params) => {
        if (undefined === params.header) {
            params.header = null;
        }

        props.Modal.setModal({
            isVisible: true,
            content: null,
            ...params,
        });
    };

    const showStopEncodingErrorModal = () => {
        props.Modal.setModal({
            content: (
                <div>
                    <Header icon='warning' color='red' content={'Stop encoding'}/>
                    <Modal.Content>
                        {'Couldn\'t fetch match status.'}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={closeModal} className='button-back'>
                            <Icon className='close'/> {'Close'}
                        </Button>
                    </Modal.Actions>
                </div>
            ),
            header: null,
            isVisible: true,
        });
    };

    const showBroadcastStatusErrorModal = (text = null) => {
        props.Modal.setModal({
            content: (
                <div>
                    <Header icon='warning' color='red' content={'Broadcast status'}/>
                    <Modal.Content>
                        {text || `
                            The encoding status must be "Encoding" in order to set the broadcast status
                            "On Air Ready" or "On Air Broadcast".
                        `}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={closeModal} className='button-back'>
                            <Icon className='close'/> {'Close'}
                        </Button>
                    </Modal.Actions>
                </div>
            ),
            header: null,
            isVisible: true,
        });
    };

    const closeModal = () => {
        props.Modal.setModal({
            isVisible: false,
        });
    };

    /**
     * At least one encoding target with Sportradar encoders: count(encoding targets with encoder_type_id = 1) > 0
     *
     * @returns {boolean}
     */
    const hasSrTarget = (row = props.row) => {
        if (row.distributions_by_product) {
            return undefined !== row.distributions_by_product.find((element) => {
                return 0 < element.sr_encoding_targets_count;
            });
        }

        return false;
    };

    /**
     * Encoding status is "Preparing", "Encoding", "Error", or "Scheduled":
     * event_content_input.encoding_status_id IN (1, 2, 4, 6)
     *
     * @returns {bool}
     */
    const isPreparingEncodingErrorScheduledStatus = () => {
        const requiredEncodingStatus = [
            ENCODING_STATUS_PREPARING, ENCODING_STATUS_ENCODING, ENCODING_STATUS_ERROR, ENCODING_STATUS_SCHEDULED,
        ];

        return _includes(requiredEncodingStatus, row.encoding_status_id);
    };

    /**
     * Has at least one encoding target with scoreboard overlay(support), slate(support)
     *
     * TODO: Body of function should be replaced by code below (need to be tested) to limit unnecessary loops!
     *
     * **CODE**
     * const checkEncodingTargetSlateSupport = (encodingTarget) => {
     *   return !encodingTarget.encoding_target_sr[column]
     * };
     *
     * props.row.distributions_by_product.every(function(item) {
     *      encodingTargetHasSlateSupport = !item.encoding_targets.every(checkEncodingTargetSlateSupport);
     *
     *      return !encodingTargetHasSlateSupport
     *  });
     * **END CODE**
     *
     *  @returns {boolean}
     */
    const hasAtLeastOneTargetByBoolColumn = (column) => {
        if (hasSrTarget()) {
            const filteredTargets = row.distributions_by_product.filter((element) => {
                return element.encoding_targets && undefined !== element.encoding_targets.find((target) => {
                    return true === target.encoding_target_sr[column];
                });
            });

            return 0 < filteredTargets.length;
        }

        return false;
    };

    /**
     * Get encoding target IDs with scoreboard overlay(support), slate(support)
     *
     * @returns {object}
     */
    const getTargetIDsAndFirstValueByBoolColumn = (column, row = props.row, columnValue = null) => {
        let targets = [],
            value = null;

        if (hasSrTarget()) {
            _forEach(row.distributions_by_product, (element) => {
                _forEach(element.encoding_targets, (target) => {
                    if (true === target.encoding_target_sr[column]) {
                        targets.push(target.id);

                        if (!value && columnValue) {
                            value = _get(target, `encoding_target_sr.${columnValue}`, null);
                        }
                    }
                });
            });
        }

        return {ids: targets, value: value};
    };

    const updateEncoding = (action) => {
        let variables = {id: row.id};

        if ('start' === action) {
            setUpdateInProgressModal('Starting the encoding...');
            executeUpdate(mutations.startEncoding, variables, action);
        } else if ('stop' === action) {
            setUpdateInProgressModal('Stopping the encoding...');
            executeUpdate(mutations.stopEncoding, variables, action);
        } else if ('reset' === action) {
            Object.assign(
                variables,
                {
                    datetime: null,
                    nullInt: null,
                    nullString: null,
                    endMargin: (row.event_content_end_datetime || row.match_id) ? ENCODING_END_MARGIN : null,
                }
            );

            setUpdateInProgressModal('Resetting the encoding...');
            executeUpdate(mutations.resetEncoding, variables, action);
        } else {
            return false;
        }
    };

    const setUpdateInProgressModal = (updateText) => {
        props.Modal.setModalConfirmation({
            text: updateText,
        });
    };

    const executeUpdate = (mutation, variables, action) => {
        props.client.mutate({
            mutation: gql(mutation),
            variables: variables,
        }).then(() => {
            props.Modal.setModal({
                isVisible: false,
            });
            refetchQueryByName('LiveControl');
        }).catch(() => {
            props.Modal.setModal({
                hideButtons: false,
                text: <span>
                    <span className='error'>Failed to {action} the encoding</span>
                    <br/>
                    Try again?
                </span>,
            });
        });
    };

    const startEncoding = () => {
        updateEncoding('start');
    };

    const stopEncoding = () => {
        updateEncoding('stop');
    };

    const resetEncoding = () => {
        updateEncoding('reset');
    };

    /**
     * Display only if the event content has at least one encoding target with Sportradar encoders,
     * if encoding has not started and if the start of the encoding is not being requested.
     *
     * @returns {JSX}
     */
    const renderStartEncodingButton = () => {
        let startEncodingButton = null;

        // count(encoding targets with encoder_type_id = 1) > 0 AND event_content_input.encoding_status_id IS NULL
        // AND (event_content_input.encoding_start_datetime IS NULL OR event_content_input.encoding_start_datetime > NOW())
        if (null !== row.event_content_input_id
            && (null !== row.rtmp_origin_stream_name
                || null !== row.sdi_entry_point_name
                || null !== row.udp_entry_point_name
                || null !== row.http_origin_entry_point_url
                || null !== row.mediaconnect_entry_point_id
                || null !== row.srt_origin_entry_point_url
            )
            && hasSrTarget()
            && null === row.encoding_status_id
            && (null === row.encoding_start_datetime
                || moment.tz(timezone).isBefore(moment.tz(row.encoding_start_datetime, timezone))
            )
            && row.event_status_id === EVENT_STATUS_PLANNED
        ) {
            startEncodingButton = <ActionButton
                popup={{content: 'Start encoding'}}
                button={{icon: 'play', color: 'green'}}
                actionParams={{
                    hideButtons: false,
                    header: (<ModalHeaderEventContentLiveControl event={row} title='Start encoding' />),
                    text: 'Are you sure you want to start the encoding now?',
                    onYes: startEncoding,
                }}
                onClick={showModal}
            />;
        }

        return startEncodingButton;
    };

    /**
     * Display only if the encoding status is "Preparing", "Encoding", "Error", or "Scheduled"
     * and if the stop of the encoding is not being requested.
     *
     * @returns {JSX}
     */
    const renderStopEncodingButton = () => {
        let stopEncodingButton = null;

        // event_content_input.encoding_status_id IN (1, 2, 4, 6)
        // AND (event_content_input.encoding_end_datetime IS NULL OR event_content_input.encoding_end_datetime > NOW())
        if (null !== row.event_content_input_id && hasSrTarget() && isPreparingEncodingErrorScheduledStatus()
            && (null === row.encoding_end_datetime
                || moment.tz(timezone).isBefore(moment.tz(row.encoding_end_datetime, timezone))
            )
        ) {
            stopEncodingButton = <ActionButton
                popup={{content: 'Stop encoding'}}
                button={{icon: 'stop', color: 'orange'}}
                actionParams={{
                    hideButtons: false,
                    header: (<ModalHeaderEventContentLiveControl event={row} title='Stop encoding' />),
                    onYes: stopEncoding,
                }}
                onClick={showStopEncodingModal}
            />;
        }

        return stopEncodingButton;
    };

    const showStopEncodingModal = (params) => {
        let text = [
            'Are you sure you want to stop the encoding now?',
        ];

        if (isSetAsDeliveredAutomaticallyAndHasMatch()) {
            hasMatchEnded().then((data) => {
                if (data) {
                    text.push(`
                         Since the match has ended, the broadcast status will automatically be changed to "Delivered".
                    `);
                }

                params = addTextToParamsForStopEncodingModal(text, params);
                showModal(params);
            }).catch(() => {
                showStopEncodingErrorModal();
            });
        } else {
            params = addTextToParamsForStopEncodingModal(text, params);
            showModal(params);
        }
    };

    const addTextToParamsForStopEncodingModal = (text, params) => (
        Object.assign({}, params, {
            text: (
                <div>
                    {text.map((line, index) => (<p key={index}>{line}</p>))}
                </div>
            ),
        })
    );

    /**
     * Display only if event content has at least one encoding target with Sportradar encoders,
     * and if the encoding status is "Ended"
     * or if the start of the encoding is being requested but encoding has not started yet.
     *
     * @returns {JSX}
     */
    const renderResetEncodingButton = () => {
        let resetEncodingButton = null;

        // count(encoding targets with encoder_type_id = 1) > 0
        // AND (event_content_input.encoding_status_id IN (5)
        //      OR (event_content_input.encoding_status_id IS NULL
        //          AND event_content_input.encoding_start_datetime IS NOT NULL
        //          AND event_content_input.encoding_start_datetime < NOW()
        //      )
        // )
        if (null !== row.event_content_input_id && hasSrTarget()
            && (ENCODING_STATUS_ENDED === row.encoding_status_id
                || (null === row.encoding_status_id
                    && null !== row.encoding_start_datetime
                    && moment.tz(timezone).isAfter(moment.tz(row.encoding_start_datetime, timezone))
                )
            )
        ) {
            resetEncodingButton = <ActionButton
                popup={{content: 'Reset encoding'}}
                button={{icon: 'repeat', color: 'blue'}}
                actionParams={{
                    hideButtons: false,
                    header: (<ModalHeaderEventContentLiveControl event={row} title='Reset encoding' />),
                    text: 'Are you sure you want to reset the encoding now?',
                    onYes: resetEncoding,
                }}
                onClick={showModal}
            />;
        }

        return resetEncodingButton;
    };

    const updateTargets = (currentValue, targetsToUpdate, propertyToUpdate) => {
        props.Modal.setModalConfirmation({
            text: `${currentValue ? 'Removing' : 'Showing'} the ${propertyToUpdate}...`,
        });

        let positionId;

        _forEach(row.distributions_by_product, (distribution) => {
            _forEach(distribution.encoding_targets, (target) => {
                positionId = _get(target, 'encoding_target_sr.scoreboard_overlay_position.id', null);

                if (null !== positionId) {
                    positionId = convertToInt(positionId);

                    return false; // break the targets loop on a first found position
                }
            });

            if (positionId) {
                return false; // break the distributions loop if positionId is defined
            }
        });


        let videoPlatformControllerMutation = mutations.setScoreboardOverlayViaVideoPlatformController,
            videoPlatformControllerVariables = {
                eventContentId: convertToInt(row.event_content_id),
                encodingDatacenterName: row.encoding_datacenter_name,
            };

        if ('slate' === propertyToUpdate) {
            videoPlatformControllerMutation = mutations.setSlateViaVideoPlatformController;
            videoPlatformControllerVariables = Object.assign(videoPlatformControllerVariables, {
                slateStatus: !currentValue,
            });
        } else {
            videoPlatformControllerVariables = Object.assign(videoPlatformControllerVariables, {
                scoreboardOverlayStatus: !currentValue,
                scoreboardOverlayPosition: positionId,
            });
        }

        props.client.mutate({
            mutation: gql(videoPlatformControllerMutation),
            variables: videoPlatformControllerVariables,
        }).then(() => {
            props.client.mutate({
                mutation: gql(mutations.updateLiveControlTargets(
                    targetsToUpdate,
                    propertyToUpdate.split(' ').join('_')
                )),
                variables: {
                    val: !currentValue,
                },
            }).then(() => {
                props.Modal.setModal({
                    isVisible: false,
                });
                refetchQueryByName('LiveControl');
            }).catch(() => {
                props.Modal.setModal({
                    hideButtons: false,
                    text: <span>
                        <span className='error'>Failed to set {propertyToUpdate}!</span>
                        <br/>
                        Try again?
                    </span>,
                });
            });
        }).catch((error) => {
            props.Modal.setModal({
                isVisible: false,
            });

            props.MessageBox.addMessage(
                'liveControl',
                `An error occurred while trying to change the ${propertyToUpdate} status via the `
                + 'Video Platform Controller API.',
                `${error}`,
                'error'
            );
        });
    };

    /**
     * Display only if event content has at least one encoding target with scoreboard overlay support
     * and if encoding has not started or if the encoding status is "Preparing", "Encoding", "Error", "Scheduled".
     *
     * Scoreboard overlay is active if the event content has at least one encoding target with scoreboard overlay
     * ("encoding_target_sr.has_scoreboard_overlay = 1"). Otherwise, it is not active.
     *
     * @returns {JSX.Element}
     */
    const renderScoreBoardButton = () => {
        let button = null;

        // encoding_target_sr.has_scoreboard_overlay_support = 1
        // AND (event_content_input.encoding_status_id IS NULL OR event_content_input.encoding_status_id in (1, 2, 4, 6)
        if (showScoreboardButton()) {
            const hasOverlay = hasAtLeastOneTargetByBoolColumn('has_scoreboard_overlay'),
                targetsToUpdate = getTargetIDsAndFirstValueByBoolColumn('has_scoreboard_overlay_support', row).ids;
            const icon = <span>SB</span>,
                tooltipText = `${hasOverlay ? 'Remove' : 'Show'} scoreboard overlay`,
                modalText = `Are you sure you want to ${hasOverlay ? 'remove' : 'show'} the scoreboard overlay?`;

            button = <ActionButton
                popup={{content: tooltipText}}
                button={{
                    content: icon,
                    className: 'icon ' + (hasOverlay ? '--no-text-decoration' : '--line-through'),
                }}
                actionParams={{
                    hideButtons: false,
                    header: (<ModalHeaderEventContentLiveControl event={row} title={tooltipText} />),
                    text: modalText,
                    onYes: () => updateTargets(hasOverlay, targetsToUpdate, 'scoreboard overlay'),
                }}
                onClick={showModal}
            />;
        }

        return (
            <Authorization authorization={authorizationLiveControlUpdateObject}>
                {button}
            </Authorization>
        );
    };


    const updateBroadcastStatus = (statusNew, statusOld, distributionsToUpdate) => {
        props.Modal.setModalConfirmation({
            header: <Header>Change broadcast status</Header>,
            text: 'Updating the broadcast status...',
        });

        props.client.mutate({
            mutation: gql(mutations.setBroadcastStatus(distributionsToUpdate)),
            variables: {
                broadcastStatus: statusNew,
            },
        }).then(() => {
            props.Modal.setModal({
                isVisible: false,
            });

            if (statusOld !== statusNew && [statusOld, statusNew].includes(BROADCAST_STATUS_ERROR)) {
                let preFillData = (BROADCAST_STATUS_ERROR === statusOld)
                    ? {
                        template: ALERT_TEMPLATE_INTERRUPTION_RESOLUTION,
                        type: ALERT_TYPE_INERRUPTION,
                    }
                    : null;

                openAlertModal(preFillData);
            }
            refetchQueryByName('LiveControl');
        }).catch(() => {
            props.Modal.setModal({
                hideButtons: false,
                text: <span>
                    <span className='error'>Failed to change the broadcast status!</span>
                    <br/>
                    Try again?
                </span>,
            });
        });
    };

    /**
     * Write the selected value in the column "distribution.broadcast_status_id" for all the distributions
     * of the event content which have "Live" as distribution type (distribution.distribution_type_id = 1)
     *
     * The selected value should be the value from "distribution.broadcast_status_id" for the distributions
     * of the event content which have "Live" as distribution type (distribution.distribution_type_id = 1).
     * If there are different values for the distributions, just take the first not null value.
     * If all values are null, don't select anything in the dropdown (and show empty option "Broadcast status").
     *
     * @returns {JSX}
     */
    const renderBroadcastStatusDropdown = () => {
        let activeStatus = null,
            buttonText = 'Broadcast status',
            distributionsToUpdate = [],
            isBroadcastStartingAutomatically = false,
            broadcastStatusesFromLiveDistributions = [];

        if (row.distributions_by_product) {
            _forEach(row.distributions_by_product, (element) => {
                _forEach(element.distribution_types, (distrType) => {
                    if (DISTRIBUTION_TYPE_LIVE === distrType.distribution_type_id) {
                        activeStatus = activeStatus ? activeStatus : distrType.broadcast_status_id;
                        distributionsToUpdate.push(distrType.id);
                        broadcastStatusesFromLiveDistributions.push(distrType.broadcast_status_id);

                        if (!isBroadcastStartingAutomatically
                            && row.match_id
                            && row.start_broadcast_automatically
                            && (BROADCAST_STATUS_ON_AIR_READY === activeStatus
                                || BROADCAST_STATUS_APPROVED === activeStatus
                                || BROADCAST_STATUS_CREATED === activeStatus
                            )
                        ) {
                            isBroadcastStartingAutomatically = true;
                        }
                    }
                });
            });
        }

        const areSameBrodcastStatuses = 1 === _uniq(broadcastStatusesFromLiveDistributions).length,
            hasDropdownErrorState = !areSameBrodcastStatuses && 1 < broadcastStatusesFromLiveDistributions.length;

        if (0 === distributionsToUpdate.length) {
            return null;
        }

        const actionParams = {
            hideButtons: false,
            header: (<ModalHeaderEventContentLiveControl event={row} title='Change broadcast status' />),
            text: 'Are you sure you want to change the broadcast status?',
        };

        const broadcastOptions = [
            {
                key: BROADCAST_STATUS_ON_AIR_READY,
                item: {
                    value: BROADCAST_STATUS_ON_AIR_READY,
                    text: 'On Air Ready',
                    active: BROADCAST_STATUS_ON_AIR_READY === activeStatus,
                },
                actionParams: {
                    ...actionParams,
                    onYes: () => updateBroadcastStatus(BROADCAST_STATUS_ON_AIR_READY, activeStatus, distributionsToUpdate),
                },
                onClick: showBroadcastStatusModal,
            },
            {
                key: BROADCAST_STATUS_ON_AIR_BROADCAST,
                item: {
                    value: BROADCAST_STATUS_ON_AIR_BROADCAST,
                    text: 'On Air Broadcast',
                    active: BROADCAST_STATUS_ON_AIR_BROADCAST === activeStatus,
                },
                actionParams: {
                    ...actionParams,
                    onYes: () => updateBroadcastStatus(BROADCAST_STATUS_ON_AIR_BROADCAST, activeStatus, distributionsToUpdate),
                },
                onClick: showBroadcastStatusModal,
            },
            {
                key: BROADCAST_STATUS_ERROR,
                item: {
                    value: BROADCAST_STATUS_ERROR,
                    text: 'Error',
                    active: BROADCAST_STATUS_ERROR === activeStatus,
                },
                actionParams: {
                    ...actionParams,
                    onYes: () => updateBroadcastStatus(BROADCAST_STATUS_ERROR, activeStatus, distributionsToUpdate),
                },
                onClick: showModal,
            },
            {
                key: BROADCAST_STATUS_DELIVERED,
                item: {
                    text: 'Delivered',
                    active: BROADCAST_STATUS_DELIVERED === activeStatus,
                },
                actionParams: {
                    ...actionParams,
                    onYes: () => updateBroadcastStatus(BROADCAST_STATUS_DELIVERED, activeStatus, distributionsToUpdate),
                },
                onClick: showModal,
            },
            {
                key: BROADCAST_STATUS_APPROVED,
                item: {
                    text: 'Approved',
                    active: BROADCAST_STATUS_APPROVED === activeStatus,
                },
                actionParams: {
                    ...actionParams,
                    onYes: () => updateBroadcastStatus(BROADCAST_STATUS_APPROVED, activeStatus, distributionsToUpdate),
                },
                onClick: showModal,
            },
            {
                key: BROADCAST_STATUS_CREATED,
                item: {
                    value: BROADCAST_STATUS_CREATED,
                    text: 'Created',
                    active: BROADCAST_STATUS_CREATED === activeStatus,
                },
                actionParams: {
                    ...actionParams,
                    onYes: () => updateBroadcastStatus(BROADCAST_STATUS_CREATED, activeStatus, distributionsToUpdate),
                },
                onClick: showModal,
            },
        ];

        _forEach(broadcastOptions, (option) => {
            if (option.item.active) {
                buttonText = option.item.text;
            }
        });

        return <>
            <Authorization authorization={authorizationLiveControlDistributionUpdateObject}>
                {isBroadcastStartingAutomatically
                    && <Popup content='Broadcast will be started automatically'
                        position='top center'
                        wide='very'
                        trigger={<Button className='broadcastAutomationInfo'>A</Button>}
                    />
                }
                <ButtonDropdown text={hasDropdownErrorState ? 'Mixed' : buttonText} rightIcon error={hasDropdownErrorState}>
                    {broadcastOptions.map((item, index) => (<ActionDropdownItem {...item} key={index}/>))}
                </ButtonDropdown>
            </Authorization>
        </>;
    };

    const showBroadcastStatusModal = (params) => {
        const errorMessage = 'Failed to fetch encoding status.';

        if (!hasSrTarget(row)) {
            showModal(params);
        } else if (row.event_content_input_id && hasSrTarget(row)) {
            props.client.query({
                fetchPolicy: 'network-only',
                query: gql(getEventContentInputEncodingStatus),
                variables: {
                    id: row.event_content_input_id,
                },
            }).then((result) => {
                let encodingStatus = _get(result, 'data.eventContentInput.encoding_status.id', null);

                (ENCODING_STATUS_ENCODING !== convertToInt(encodingStatus))
                    ? showBroadcastStatusErrorModal()
                    : showModal(params);
            }).catch(() => {
                showBroadcastStatusErrorModal(errorMessage);
            });
        } else {
            showBroadcastStatusErrorModal(errorMessage);
        }
    };

    /**
     * Run in "Hide event content" modal
     * after click Yes button in confirmation dialog
     */
    const hideEventContent = () => {
        props.client.mutate({
            mutation: hideEventContentMutation,
            variables: {
                id: row.id,
                hide_in_live_control: true,
            },
        }).then(() => {
            props.Modal.setModalConfirmation({
                isVisible: false,
            });
            refetchQueryByName('LiveControl');
        });
    };

    const openModalToConfirmHideEventContent = () => {
        props.Modal.setModalConfirmation({
            header: (<ModalHeaderEventContentLiveControl event={row} title='Hide event content' />),
            text: (
                <>
                    Are you sure you want to hide this event content? <br />
                    It will not appear in the Live Control anymore for all users.
                </>
            ),
            onYes: hideEventContent,
        });
    };

    const renderExtraFeaturesDropdown = () => {
        return (
            <Dropdown
                button
                className='icon --defaultPadding'
                icon='ellipsis horizontal'
                simple
            >
                <Dropdown.Menu>
                    {renderEncodingLogButton()}
                    {renderDebugPlatformButton()}
                    {renderAlertButton()}
                    {renderReportButton()}
                    {renderReportAsWorkingAndInoviceableButton()}
                    <Authorization authorization={authorizationLiveControlUpdateObject}>
                        <Dropdown.Item icon='eye slash outline' onClick={openModalToConfirmHideEventContent} text='Hide '/>
                    </Authorization>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const renderEncodingLogButton = () => (
        (row.encoding_first_start_datetime)
            ? <Dropdown.Item icon='file alternate outline' onClick={openEncodingLog} text='Encoding log'/>
            : null
    );

    const openEncodingLog = () => {
        window.open(getEncodingLogsInVideoManagementPlatformLink(row.id, row.encoding_datacenter_name), '_blank');
    };

    const renderDebugPlatformButton = () => (
        (row.encoding_first_start_datetime)
            ? <Dropdown.Item icon='bug' onClick={openDebugPlatform} text='Debug platform'/>
            : null
    );

    const openDebugPlatform = () => {
        window.open(`${DEBUG_PLATFORM_LINK}/#/events/event/${row.id}`, '_blank');
    };

    const renderAlertButton = () => {

        return (
            <Authorization authorization={authorizationCreateAlertObject}>
                <Dropdown.Item icon='warning circle' onClick={onClickOpenAlertModal} text='Send alert to clients'/>
            </Authorization>
        );
    };

    const onClickOpenAlertModal = () => {
        openAlertModal();
    };

    const openAlertModal = (preFillData = null) => {
        showModal({
            isVisible: true,
            size: 'large',
            content:
                <EventContentAlertSteps
                    eventContentId={row.event_content_id}
                    messageBoxName='liveControl'
                    eventContentStartDatetime={row.event_content_start_datetime}
                    preFillData={preFillData}
                />,
        });
    };

    const renderReportButton = () => {
        if (row.event_report_id) {
            return (
                <Authorization authorization={authorizationReadReportObject}>
                    <Dropdown.Item
                        icon='clipboard list'
                        onClick={openReportLink}
                        text='See the report'
                    />
                </Authorization>
            );
        }

        return (
            <Authorization authorization={authorizationCreateReportObject}>
                <Dropdown.Item
                    icon='clipboard list'
                    onClick={openReportLink}
                    text='Create a report'
                />
            </Authorization>
        );
    };

    const openReportLink = () => {
        window.open(getLink('events.report', {id: row.event_id}), '_blank');
    };

    const renderPlayOriginStreamButton = () => {
        const originStreamTypeHTTP = row.origin_stream_type_id === ORIGIN_STREAM_TYPE_HTTP;
        const originStreamTypeRTMP = row.origin_stream_type_id === ORIGIN_STREAM_TYPE_RTMP;
        const originStreamTypeSRT = row.origin_stream_type_id === ORIGIN_STREAM_TYPE_SRT;
        const isDisabled = !(originStreamTypeHTTP && row.http_origin_entry_point_url
            || originStreamTypeRTMP && row.rtmp_origin_stream_name_id
            || originStreamTypeSRT && row.srt_origin_entry_point_url
        );

        let linkName = '';

        if (originStreamTypeHTTP) {
            linkName = 'events.streams.play.debug.origin';
        }

        if (originStreamTypeRTMP || originStreamTypeSRT) {
            linkName = 'events.streams.play.m3u';
        }

        const button = (
                <ActionButton
                    popup={{content: 'Play origin stream'}}
                    button={{
                        icon: 'play circle outline',
                        disabled: isDisabled,
                        className: (0 < row.streams_count) ? '--noRightRadius' : null,
                    }}
                />
            ),
            withLink = (children) => (
                <LinkWithAuthorization
                    authorization={authorizationReadEventContentObject}
                    newWindow={true}
                    link={{
                        name: linkName,
                        params: {id: row.event_id, eventContentId: row.id},
                    }}
                    disabled={isDisabled}
                >
                    {children}
                </LinkWithAuthorization>
            );

        return (
            <Authorization authorization={authorizationReadEventContentObject}>
                {(isDisabled) ? button : withLink(button)};
            </Authorization>
        );
    };

    const checkSlateConditions = () => {
        if (0 < props.row.slates.length
            && props.row.event_content_input_id
            && (null === props.row.encoding_status_id || isPreparingEncodingErrorScheduledStatus())) {

            return hasAtLeastOneTargetByBoolColumn('has_slate_support');
        }

        return false;
    };

    const renderStreamPlaybackListButton = () => {
        let button = null;

        if (0 < row.streams_count) {
            return (
                <Authorization authorization={authorizationStreamReadObject}>
                    <ActionButton
                        popup={{content: 'Play output streams'}}
                        button={{icon: <IconListPlay />}}
                        actionParams={{
                            className: 'streamPlayback',
                            content: <EventContentStreams
                                eventId={convertToInt(row.event_id)}
                                eventContentId={convertToInt(row.event_content_id)}
                                closeModal={closeModal}
                                modal={props.Modal}
                            />,
                            size: 'large',
                        }}
                        onClick={showModal}
                    />
                </Authorization>
            );
        }

        return button;
    };

    const showScoreboardButton = () => (
        null !== row.event_content_input_id
        && hasAtLeastOneTargetByBoolColumn('has_scoreboard_overlay_support')
        && (null === row.encoding_status_id || isPreparingEncodingErrorScheduledStatus())
    );

    const renderReportAsWorkingAndInoviceableButton = () => {
        if (null === _get(row, 'event_report_id', null)
        || 0 < row.distributions_by_product.length) {

            return (
                <Authorization authorization={authorizationReportAsWorkingAndInvoiceableObject}>
                    <Dropdown.Item
                        icon='clipboard check'
                        onClick={onClickOpenReportAsWorkingAndInvoiceable}
                        text='Report as working and invoiceable'/>
                </Authorization>
            );
        }
    };

    const onClickOpenReportAsWorkingAndInvoiceable = () => {
        const reportAsWorkingAndInvoiceableErrorHandler = () => {
            reportMultipleAsWorkingAndInvoiceableErrorHandler(
                'liveControl',
                'Selected event could not be reported successfully as working and invoiceable'
            );
            closeModal();
        };

        showModalConfirmation({
            header: (
                <>
                    <Header content={REPORT_MULTIPLE_AS_WORKING_AND_INVOICEABLE_MESSAGE} />
                    <HeaderEventContent eventContentDetails={row} />
                </>
            ),
            text: 'Are you sure that this event was delivered without any issues and should be reported as working '
                + 'and invoiceable for all products?',
            size: 'small',
            onYes: () => {
                props.client.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: ReportWorkingInvoiceableEvents,
                    variables: {
                        events: [row.event_id],
                    },
                }).then((response) => {
                    reportMultipleAsWorkingAndInvoiceableResponseHandler(
                        response,
                        reportAsWorkingAndInvoiceableErrorHandler,
                        'liveControl',
                        'Selected event was reported as working and invoiceable successfully'
                    );
                    refetchQueryByName('LiveControl');
                    closeModal();
                }).catch(() => {
                    reportAsWorkingAndInvoiceableErrorHandler();
                });
            },
        });
    };

    return (
        <div className='liveControlActionButtons'>
            <ButtonGroup size='mini' className='actionButton' style={{display: 'block', whiteSpace: 'nowrap'}}>
                <Authorization authorization={authorizationLiveControlUpdateObject}>
                    {renderStartEncodingButton()}
                    {renderStopEncodingButton()}
                </Authorization>
                <Authorization authorization={authorizationLiveControlEventContentUpdateObject}>
                    {renderResetEncodingButton()}
                </Authorization>
            </ButtonGroup>
            {
                (showScoreboardButton() || checkSlateConditions() || showOverlayButton(row))
                    ? (
                        <ButtonGroup size='mini' className='actionButton' style={{display: 'block', whiteSpace: 'nowrap'}}>
                            {renderScoreBoardButton()}
                            <OverlayButton row={row} authorizationObject={authorizationLiveControlUpdateObject} />
                            {checkSlateConditions() && <SlateButton
                                {...props}
                                getTargetIDsAndFirstValueByBoolColumn={getTargetIDsAndFirstValueByBoolColumn}
                            />}
                        </ButtonGroup>
                    )
                    : null
            }
            <ButtonGroup size='mini' className='actionButton' style={{display: 'block', whiteSpace: 'nowrap'}}>
                {renderBroadcastStatusDropdown()}
            </ButtonGroup>
            <ButtonGroup size='mini' className='actionButton' style={{display: 'block', whiteSpace: 'nowrap'}}>
                {renderPlayOriginStreamButton()}
                {renderStreamPlaybackListButton()}
            </ButtonGroup>
            <ButtonGroup size='mini' className='actionButton' style={{display: 'block', whiteSpace: 'nowrap'}}>
                {renderExtraFeaturesDropdown()}
            </ButtonGroup>
        </div>
    );
};

LiveControlActions.propTypes = {
    client: PropTypes.object,
    MessageBox: PropTypes.object.isRequired,
    Modal: PropTypes.object,
    row: PropTypes.object.isRequired,
    timezone: PropTypes.string,
};

export default withApollo(connect(
    state => ({timezone: state.timezone.timezone}),
    mapModulesToProps(['MessageBox', 'Modal'])
)(LiveControlActions));
