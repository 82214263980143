import React from 'react';
import PropTypes from 'prop-types';

import {
    GetDateTimeFieldValue,
} from '../../../../../components/EventsTabsLayout/Details/EventDetails/common/GetDateTimeFieldValue';

export const getFieldsList = (event) => {
    return [
        {
            name: 'Status',
            value: (event.event_status || {}).name,
            id: (event.event_status || {}).id,
        },
        {
            name: 'Start time',
            value: <GetDateTimeFieldValue event={event} label={'start'} matchDateKey={'start_datetime'}/>,
        },
        {
            name: 'End time',
            value: <GetDateTimeFieldValue event={event} label={'end'} matchDateKey={'end_datetime'}/>,
        },
    ];
};

getFieldsList.propTypes = {
    event: PropTypes.shape({
        event_status: PropTypes.shape({
            name: PropTypes.string,
        }),
    }).isRequired,
};

getFieldsList.defaultProps = {
    event: {
        event_status: {
            name: null,
        },
    },
};
