import PropTypes from 'prop-types';
import {get as _get} from 'lodash';

import {
    getListOfCodesForDeviceCategories,
    getListOfCountryCodesForDeviceCategories,
    getListOfDeviceCategoriesForGeoRestrictions,
} from '@utils/countryHelpers';
import {generateBlackoutZonesRow, getBlackoutZonesFromGeoRestrictions} from '@utils/blackoutZones';
import {getYesOrNo} from '@utils/helpers';
import {getClipNames} from '@modules/clipConfiguration/utils/propertyDecorator';

export const exportExcel = (data, eventId) => ({
    dataExport: data,
    filename: `event_${eventId}_event_bookings`,
    parseData: eventBooking => [
        eventBooking.id,
        eventBooking.client.name,
        eventBooking.product.name,
        getClipNames(eventBooking),
        getListOfDeviceCategoriesForGeoRestrictions(eventBooking.event_booking_geo_restrictions).join(', '),
        getListOfCodesForDeviceCategories(eventBooking.event_booking_geo_restrictions),
        getListOfCountryCodesForDeviceCategories(eventBooking.event_booking_geo_restrictions).length.toString(),
        generateBlackoutZonesRow(
            getBlackoutZonesFromGeoRestrictions(eventBooking.event_booking_geo_restrictions),
            true
        ),
        _get(eventBooking, 'client_package.name', ''),
        _get(eventBooking, 'booking_type.name', ''),
        _get(eventBooking, 'creation_datetime', ''),
        _get(eventBooking, 'creation_user.name', ''),
        getYesOrNo(eventBooking.is_cancelled),
        eventBooking.is_cancelled ? _get(eventBooking, 'cancellation_user.name', '') : null,
    ],
    path: 'events.eventBookings.index.path',
    titles: [
        'ID',
        'Client',
        'Product',
        'Clips',
        'Device categories',
        'Countries',
        '#Countries',
        'Blackout Zones',
        'Package',
        'Type',
        'Time',
        'Booked by',
        'Cancelled?',
        'Cancelled by',
    ],
});

exportExcel.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    eventId: PropTypes.number,
    event_booking_geo_restrictions: PropTypes.array,
};
