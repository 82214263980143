import React from 'react';
import PropTypes from 'prop-types';
import {Message, Table} from 'semantic-ui-react';
import {isEmpty as _isEmpty} from 'lodash';

const EmailPreviewAlertTable = ({data}) => {
    if (_isEmpty(data)) {
        return (
            <div className='table-container'>
                <Message>
                    <Message.Header>
                        {'No clients selected'}
                    </Message.Header>
                </Message>
            </div>
        );
    }

    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Client</Table.HeaderCell>
                    <Table.HeaderCell>Recipients</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    Object.entries(data).map(([name, email]) => (
                        <Table.Row key={name}>
                            <Table.Cell>{name}</Table.Cell>
                            <Table.Cell>{email.join(', ')}</Table.Cell>
                        </Table.Row>
                    ))
                }
            </Table.Body>
        </Table>
    );
};

EmailPreviewAlertTable.propTypes = {
    data: PropTypes.object,
};
export default EmailPreviewAlertTable;
