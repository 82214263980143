import React from 'react';
import PropTypes from 'prop-types';
import {Header} from 'semantic-ui-react';
import {get as _get} from 'lodash';

import {
    EVENT_INVOICE_STATUS,
    EVENT_REPORT,
} from '@constants/resources';
import {IconInfoCircleChangelog} from '@appComponents/IconCollection';
import Form from '@appComponents/ReduxFormControls';
import * as eventsGraphQl from '@graphql/events/eventsReport';
import {GetEventReportsFilter} from '@graphql/events/query';
import * as messages from '@constants/messages';

import * as validateReport from './validatorReport';

export default function EventsReportModel() {
    const renderInvoiceStatusFields = (props) => {
        let inputsToRender = [];
        const inputsData = _get(props, 'inputsData', []),
            defaultValues = {},
            data = _get(props, 'eventInvoiceStatuses', []);

        data.forEach((dataRow) => {
            defaultValues[dataRow.product.id] = {
                id: dataRow.id,
                invoice_status: dataRow.invoice_status.id,
                creation_datetime: dataRow.creation_datetime,
                creation_user: dataRow.creation_user,
                update_datetime: dataRow.update_datetime,
                update_user: dataRow.update_user,
            };
        });

        inputsData.forEach((item, index) => {
            let description = null;
            const updateTime = _get(defaultValues, `${item.id}.update_datetime`, null);

            if (updateTime) {
                description = <IconInfoCircleChangelog
                    tooltipProps={{hoverable: true, position: 'top left'}}
                    resources={EVENT_INVOICE_STATUS}
                    data={defaultValues[item.id]}
                />;
            }

            inputsToRender.push(
                <div key={`invoiceStatus${item.id}`}>
                    <Form.FormRow
                        defaultValue={_get(defaultValues, `${item.id}.id`, '')}
                        component={Form.SemanticInput}
                        name={`invoice_statuses.${index}.id`}
                        hidden={true}
                    />
                    <Form.FormRow
                        component={Form.SemanticInput}
                        defaultValue={item.id}
                        hidden={true}
                        name={`invoice_statuses.${index}.product`}
                    />
                    <Form.FormRow
                        required
                        options={props.options}
                        description={description}
                        defaultValue={_get(defaultValues, `${item.id}.invoice_status`, '')}
                        placeholder={'Select invoice status'}
                        validate={validateReport.validateMultipleInput}
                        name={`invoice_statuses.${index}.invoice_status`}
                        component={Form.SemanticSelect}
                        label={item.short_name}
                    />
                </div>
            );
        });

        if (0 === inputsData.length) {
            inputsToRender = <p>This event does not contain any distribution for any invoiceable product.</p>;
        }

        return <div style={{'alignItems': 'baseline', 'flexFlow': 'column'}}>{inputsToRender}</div>;
    };

    renderInvoiceStatusFields.propTypes = {
        options: PropTypes.array,
    };

    const renderLabel = (props) => {
        let marginClass = '';

        if (props.margin) {
            marginClass = '--margin_top-2rem';
        }

        return <Header className={`--border_bottom ${marginClass}`}>{props.label}</Header>;
    };

    renderLabel.propTypes = {
        label: PropTypes.string,
        margin: PropTypes.bool,
    };

    return {
        test: {
            renderInvoiceStatusFields,
            renderLabel,
        },
        entityDataMapKey: 'eventReports.0',
        entityLabel: 'report',
        formName: 'Report',
        formValidationMessageBoxName: 'eventReportFormValidationBox',
        resources: EVENT_REPORT,
        showChangeLog: true,
        validate: validateReport.validate,
        title: () => {
            return null;
        },
        mutation: {
            updateEntity: eventsGraphQl.updateEventReportMutation,
            createEntity: eventsGraphQl.createEventReportMutation,
        },
        query: {
            getEntity: GetEventReportsFilter,
            getOptions: eventsGraphQl.dropDownLists,
        },
        messages: {
            boxName: {
                delete: 'eventsIndex',
                success: 'event',
                error: 'event',
            },
            text: messages,
        },
        dataMap: {
            eventReports: {
                event: '',
                origin_stream_status: {
                    id: '',
                },
                comment: '',
            },
        },
        fields: {
            origin_stream_header: {
                dataMapKey: 'separator.origin_stream',
                props: {
                    component: renderLabel,
                    label: 'Origin stream and issues',
                    name: 'origin_stream_and_issues_header',
                },
            },
            id: {
                component: Form.IdField,
                dataMapKey: 'eventReports.0.id',
                props: {
                    label: '',
                    name: 'id',
                    hidden: true,
                },
            },
            origin_stream_status: {
                dataMapKey: 'eventReports.0.origin_stream_status.id',
                optionsKey: 'originStreamStatuses',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Status',
                    name: 'origin_stream_status',
                    className: '--auto_width',
                    placeholder: 'Select status',
                    allowClear: false,
                    required: true,
                },
            },
            event_issue_types: {
                props: {
                    className: 'inline',
                    component: Form.SemanticCheckboxList,
                    label: 'Issue types',
                    name: 'event_issue_types',
                    inline: true,
                },
            },
            comment: {
                component: Form.FormRowNotes,
                dataMapKey: 'eventReports.0.comment',
                props: {
                    name: 'comment',
                    label: 'Comment',
                    validate: validateReport.validateCommentLength,
                },
            },
            invoice_status_header: {
                dataMapKey: 'separator.invoice_status',
                props: {
                    margin: true,
                    component: renderLabel,
                    label: 'Invoice status',
                    name: 'invoice_status_header',
                },
            },
            invoice_statuses: {
                optionsKey: 'invoiceStatuses',
                dataMapKey: 'eventInvoiceStatuses',
                props: {
                    component: renderInvoiceStatusFields,
                    optionsKey: 'invoiceStatuses',
                    name: 'invoice_statuses',
                },
            },
        },
    };
}
