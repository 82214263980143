import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import {has as _has} from 'lodash';

import {
    LIVE_DATA_CLIENT_MATCH_ENDED_LINK,
    LIVE_DATA_CLIENT_MATCH_NO_ENDED_LINK,
} from '@constants/variables';

import {isSportradarEvent} from '../../../../utils/eventType';

/**
 * Open the match in Scout Management Platform (SMP)
 */
class MatchLiveDataClient extends React.Component {
    /**
     * Open platform in new tab
     */
    openLiveDataClient = () => {
        const matchId = this.props.event.sr_event.match.id,
            openURL = (this.props.event.sr_event.match.has_ended)
                ? `${LIVE_DATA_CLIENT_MATCH_ENDED_LINK}${matchId}`
                : `${LIVE_DATA_CLIENT_MATCH_NO_ENDED_LINK}${matchId}`;

        window.open(openURL, '_blank');
    };

    render() {
        /**
         * This option is available only if the event is a Sportradar event
         * and
         * with sr_event.match_id is not NULL.
         */
        if (!isSportradarEvent(this.props.event) || !_has(this.props.event, 'sr_event.match.id')) {
            return null;
        }

        return (
            <Dropdown.Item
                icon='external alternate'
                key={`openLiveDataClient`}
                onClick={this.openLiveDataClient}
                text='Open the match in Live Data Client (LDC)'
            />
        );
    }
}

MatchLiveDataClient.propTypes = {
    event: PropTypes.shape({
        sr_event: PropTypes.shape({
            match: PropTypes.shape({
                id: PropTypes.string,
                has_ended: PropTypes.bool,
            }),
        }),
    }),
};

MatchLiveDataClient.defaultProps = {
    event: {
        sr_event: {
            match: {
                id: null,
                has_ended: null,
            },
        },
    },
};

export default MatchLiveDataClient;
