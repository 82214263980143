import * as actionTypes from '../actions/actionTypes';

export const initialState = {
    validatingAuth: false,
    loggedIn: false,
    shouldRedirect: false,
    errorMessage: null,
    sessionEnd: false,
};

/**
 * @param state
 * @param action
 * @returns {*}
 */
const security = (state = initialState, action = null) => {
    switch (action.type) {
        case actionTypes.AUTHENTICATION_CHECK: {
            return {
                ...state,
                validatingAuth: true,
            }
        }
        case actionTypes.AUTHENTICATION_SUCCESS: {
            return {
                ...state,
                validatingAuth: false,
                loggedIn: true,
                shouldRedirect: false,
            }
        }
        case actionTypes.AUTHENTICATION_FAILURE: {
            return {
                ...state,
                validatingAuth: true,
                loggedIn: false,
                shouldRedirect: true,
            }
        }
        case actionTypes.FETCH_AUTHENTICATION_FAILURE: {
            return {
                ...state,
                errorMessage: action.errorMessage,
                validatingAuth: false,
                loggedIn: false,
                shouldRedirect: false,
            }
        }
        case actionTypes.SESSION_END: {
            return {
                ...state,
                sessionEnd: true,
            }
        }
        case actionTypes.SAVE_USER: {
            let privileges = {};

            for(var i in action.data.resources) {
                if (typeof(action.data.resources[i]) === "object") {
                    const object = action.data.resources[i];
                    privileges = Object.assign(privileges, {[parseInt(object.id)]: object.privileges})
                }
            }

            return {
                ...state,
                ...{
                    user : {
                        privileges: privileges,
                        name: action.data.name,
                        id: action.data.id,
                    }
                }
            }
        }
        case actionTypes.REDIRECT_TO_AUTH_LOGIN: {
            !action.test && window.location.replace(action.fromUrl)

            return initialState
        }

        default:
            return state
    }
};

export default security;
