import {convertToInt} from '@utils/helpers';

const wnsStreamTableColumns = {
    id: {
        label: 'ID',
        sorting: row => convertToInt(row.id),
        header: {
            align: 'center',
        },
        content: {align: 'center'},
    },
    name: {
        label: 'Name',
        sorting: row => row.name,
        header: {
            align: 'left',
        },
        content: {align: 'left'},
    },
    stream_name: {
        label: 'Stream name',
        sorting: row => row.stream_name,
        header: {
            align: 'left',
        },
        content: {align: 'left'},
    },
    actions: {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {align: 'center'},
    },
};

export default wnsStreamTableColumns;
