import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';

import {Authorization} from '@appComponents/Authorization';
import {convertToInt} from '@utils/helpers';
import {getLink} from '@appComponents/Link';
import {IconEdit} from '@appComponents/IconCollection';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {MEDIA_CONNECT_ORIGIN_ENTRY_POINT} from '@constants/resources';
import Table from '@appComponents/Table';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';

const authorizationObject = {
    [RESOURCES.MEDIA_CONNECT_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ,
};

export const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {
        label: 'Name',
        sorting: (row) => row.name.toLowerCase(),
    },
    'encoding_datacenter': {
        label: 'Encoding datacenter',
        sorting: (row) => _isEmpty(row.encoding_datacenter) ? '' : row.encoding_datacenter.name.toLowerCase(),
    },
    'mediaconnect_origin_feeds': {
        label: 'Feeds',
        sorting: (row) => _isEmpty(row.mediaconnect_origin_feeds) ? '' : row.mediaconnect_origin_feeds[0].url,
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
};

const renderMediaConnectIdColumn = (column, row) => {
    return (
        <LinkWithAuthorization
            authorization={authorizationObject}
            link={{
                name: 'mediaConnect.edit',
                params: {
                    id: row.id,
                },
            }}
        >
            {row[column]}
        </LinkWithAuthorization>
    );
};

const renderMediaConnectNameColumn = (column, row) => {
    return (
        <div>
            <div>
                {(_isEmpty(row[column]))
                    ? ''
                    :
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: 'mediaConnect.edit',
                            params: {
                                id: row.id,
                            },
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                }
            </div>
            <div>
                {(!_isEmpty(row.description)) && <p className='muted'>{row.description}</p>}
            </div>
        </div>
    );
};

const renderMediaConnectFeedsColumn = (row) => {
    if (_isEmpty(row.mediaconnect_origin_feeds)) {
        return null;
    }

    const rows = row.mediaconnect_origin_feeds.map(feed => (
        <div key={feed.feed_number}>
            {feed.url}
        </div>
    ));

    return <div>{rows}</div>;
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'id':
            return renderMediaConnectIdColumn(column, row);
        case 'name':
            return renderMediaConnectNameColumn(column, row);
        case 'encoding_datacenter':
            return row.encoding_datacenter.name;
        case 'mediaconnect_origin_feeds':
            return renderMediaConnectFeedsColumn(row);
        case 'actions': {
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit
                            size='large'
                            link={getLink('mediaConnect.edit', {
                                id: row.id,
                            })}
                            resource={MEDIA_CONNECT_ORIGIN_ENTRY_POINT}
                        />
                    </div>
                </Authorization>
            );
        }
        default:
            return null;
    }
};

const MediaConnectTable = ({mediaconnectOriginEntryPoints, loading}) => {
    return (
        <Table
            name='mediaConnectTable'
            columns={columns}
            data={mediaconnectOriginEntryPoints}
            loading={loading}
            rowRenderer={rowRenderer}
            noDataText='No Media Connect origin entry points found'
        />
    );
};

MediaConnectTable.propTypes = {
    mediaconnectOriginEntryPoints: PropTypes.array,
    loading: PropTypes.bool,
};

export default MediaConnectTable;
