import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {EVENT} from '@constants/resources';
import {SetCoverageToEvents} from '@graphql/events/mutation';
import {GetOptionsForSetCoverageForm} from '@graphql/events/query';

import validatorSetCoverage from './validatorSetCoverage';

export default function SetCoverageModel() {
    return {
        title: null,
        formName: 'SetCoverageToMultipleEvents',
        entityDataMapKey: 'SetCoverageToMultipleEvents',
        resources: EVENT,
        validate: validatorSetCoverage,
        dataMap: {
            products: [],
        },
        fields: {
            products: {
                optionsKey: 'coverage',
                props: {
                    className: 'inline',
                    component: Form.SemanticCheckboxList,
                    label: 'Coverage',
                    name: 'coverage',
                    inline: true,
                },
            },
            clip_provider: {
                optionsKey: 'clipProviders',
                props: {
                    className: '--small-width',
                    hidden: true,
                    component: Form.SemanticSelect,
                    label: 'Clip provider',
                    name: 'clip_provider',
                    required: true,
                },
            },
        },
        messages: {
            boxName: {
                delete: 'eventsIndex',
                success: 'eventsIndex',
                error: 'formInnerErrorMessage',
            },
            text: {
                ...MESSAGES,
                SAVED: () => 'The selected events have had their coverage set successfully.',
                FORM_ERROR: () => 'The selected events could not have their coverage set successfully.',
                NOT_SAVED: () => '',
            },
        },
        mutation: {
            updateEntity: SetCoverageToEvents,
        },
        query: {
            getOptions: GetOptionsForSetCoverageForm,
        },
    };
}
