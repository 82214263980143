import {isEmpty as _isEmpty} from "lodash";

import { checkDateRange } from '../../../../utils/date';

const RightScope = {
    /**
     * Group right scopes (by property license)
     * for checking if date is between "start_date" and "end_date"
     * @param rightScopesForPropertyLicense
     * @param key
     */
    perapreRightScopes: (rightScopesForPropertyLicense, key = "tournament") => {
        let rightsScopes = {};

        const KEY_ALL = "all";

        rightScopesForPropertyLicense.forEach((rightScope) => {
            const contents = rightScope.contents;

            if (0 === contents.length) {
                if (!rightsScopes.all) {
                    rightsScopes[KEY_ALL] = []
                }

                rightsScopes[KEY_ALL].push({
                    start_date: rightScope.start_date,
                    end_date: rightScope.end_date
                });
            }

            contents.forEach((content) => {
                if (!rightsScopes[content[key].id]) {
                    rightsScopes[content[key].id] = [];
                }

                rightsScopes[content[key].id].push({
                    start_date: rightScope.start_date,
                    end_date: rightScope.end_date
                });
            });
        });

        return rightsScopes;
    },

    /**
     * Check, if exist tournament/content category (non SR event) - KEY
     * and our date is between right scopes date_start and date_end
     * @returns {boolean}
     */
    isValidDate: (dateCompareMoment, rightsScopes, tournamentId = null) => {
        /**
         * No right scopes for all event contents and event content for specific tournament/content category
         */
        if (_isEmpty(rightsScopes.all) && _isEmpty(rightsScopes[tournamentId])) {
            return false;
        }

        /**
         * Rights scopes includes all content
         */
        if (!_isEmpty(rightsScopes.all)) {
            for (let allIndex = 0; allIndex < rightsScopes.all.length; allIndex++) {
                const dates = rightsScopes.all[allIndex];

                if (checkDateRange(dateCompareMoment, dates)) {
                    return true;
                }
            }
        }

        /**
         * Right scopes with specific tournament id / content category id
         */
        if (!_isEmpty(rightsScopes[tournamentId])) {
            for (let i = 0; i < rightsScopes[tournamentId].length; i++) {
                const dates = rightsScopes[tournamentId][i];

                if (checkDateRange(dateCompareMoment, dates)) {
                    return true;
                }
            }
        }

        return false;
    }

};

export default RightScope;
