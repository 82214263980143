import {get as _get} from 'lodash';

import {convertToInt} from '@utils/helpers';

export const prepareContentsOptions = (contents = []) => (
    contents.slice().sort((a, b) => {
        if (!a.content_category && !a.tournament) {
            return 0;
        }

        const aName = a.content_category ? a.content_category.name : a.tournament.name,
            aParent = a.content_category ? a.content_category.parent.name : a.tournament.tournament_category.name,
            bName = b.content_category ? b.content_category.name : b.tournament.name,
            bParent = b.content_category ? b.content_category.parent.name : b.tournament.tournament_category.name;

        if (aParent < bParent) {
            return -1;
        }

        if (aParent > bParent) {
            return 1;
        }

        if (aName < bName) {
            return -1;
        }

        if (aName > bName) {
            return 1;
        }
    }).map((content) => {
        let label, parentLabel;

        if (null !== content.content_category) {
            label = content.content_category.name;

            if (null !== content.content_category.parent) {
                parentLabel = content.content_category.parent.name;
            }
        } else if (null !== content.tournament) {
            label =  content.tournament.name;

            if (null !== content.tournament.tournament_category) {
                parentLabel = content.tournament.tournament_category.name;
            }
        } else {
            return false;
        }

        return {
            key: parentLabel + ' > ' + label,
            value: convertToInt(content.id),
        };
    })
);

export const selectionHasEventBookingSupport = (products, selectedProducts) => {
    for (const element of selectedProducts) {
        const foundProduct = products.find(product => convertToInt(product.id) === element);

        if (foundProduct?.has_event_booking_support) {
            return true;
        }
    }

    return false;
};

export const createUsageTreeOptions = (products = [], usages = []) => {
    const selectedOptions = {};

    if (usages) {
        usages.forEach((usage) => {
            if (usage) {
                selectedOptions[
                    `product-${usage.product.id}.device_category-${usage.device_category.id}.way_of_transmissions-${usage.way_of_transmission.id}`
                ] = usage.id;
            }
        });
    }

    return products.map((product) => ({
        value: product.id,
        label: product.short_name,
        name: 'product',
        children: product.device_categories.map((category) => ({
            value: category.id,
            label: category.name,
            name: 'device_category',
            children: product.ways_of_transmission.map((transmission) => {
                const id = _get(
                    selectedOptions,
                    `product-${product.id}.device_category-${category.id}.way_of_transmissions-${transmission.id}`,
                    null
                );

                return {
                    id: id,
                    value: transmission.id,
                    label: transmission.name,
                    name: 'ways_of_transmissions',
                    selected: null !== id,
                };
            }),
        })),
    }));
};
