import React from 'react';
import {Field} from 'redux-form';
import moment from 'moment/moment';
import PropTypes from 'prop-types';

import Form from '@appComponents/ReduxFormControls';
import {getTodayDate, getTomorrowDate} from '@utils/date';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
const TIME_FORMAT = 'HH:mm';

export const BookingsDatesFields = (props) => {
    const {
        loading,
        clientFilters,
    } = props;

    const {
        onChange,
        fromMaxDate,
        fromMaxDateDefault,
        fromMaxTime,
        toMinDate,
        toMinDateDefault,
        toMinTime,
        restoreFilterValues,
    } = props.dateConfigProps;

    return (
        <>
            <Field
                className='--minimalWidth --datetimeWithoutSeconds'
                component={Form.ReduxInput}
                configuration={{
                    onChange: onChange,
                    calendarClassName: 'filterDatepicker',
                    dateFormat: DATE_FORMAT,
                    maxDate: fromMaxDate || fromMaxDateDefault,
                    maxTime: fromMaxTime || moment().hours(23).minutes(59),
                    minTime: moment().hours(0).minutes(0),
                    shouldCloseOnSelect: false,
                    showTimeSelect: true,
                    timeFormat: TIME_FORMAT,
                    timeIntervals: 5,
                    portalId: 'root',
                }}
                defaultValue={getTodayDate()}
                disabled={loading}
                name='from'
                placeholder='From...'
                setValue={restoreFilterValues(clientFilters, 'from', getTodayDate())}
                type='date'
            />
            <Field
                className='--minimalWidth --datetimeWithoutSeconds'
                component={Form.ReduxInput}
                configuration={{
                    onChange: onChange,
                    calendarClassName: 'filterDatepicker',
                    dateFormat: DATE_FORMAT,
                    minDate: toMinDate || toMinDateDefault,
                    maxTime: moment().hours(23).minutes(59),
                    minTime: toMinTime || moment().hours(0).minutes(0),
                    shouldCloseOnSelect: false,
                    showTimeSelect: true,
                    timeFormat: TIME_FORMAT,
                    timeIntervals: 5,
                    portalId: 'root',
                }}
                defaultValue={getTomorrowDate()}
                disabled={loading}
                name='to'
                placeholder='To...'
                setValue={restoreFilterValues(clientFilters, 'to', getTomorrowDate())}
                type='date'
            />
        </>
    );
};

BookingsDatesFields.propTypes = {
    loading: PropTypes.bool,
    clientFilters: PropTypes.object,
    dateConfigProps: PropTypes.object,
    restoreFilterValues: PropTypes.func,
};
