import React, {useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {get as _get} from 'lodash';

import {showModal} from '@utils/modal';
import {renderModalError} from '@utils/forms';

// eslint-disable-next-line react/prop-types
export const TitleFromQuery = ({children, entity, errorBackLink, query, path, variables}) => {
    // eslint-disable-next-line react/prop-types
    if (!variables.id) {
        return `Add new ${entity}`;
    }
    // eslint-disable-next-line react/prop-types
    const upperCasedTitle = entity && entity.charAt(0).toUpperCase() + entity.slice(1),
        {data, error} = useQuery(query,{
            variables,
            fetchPolicy: 'no-cache',
        });

    useEffect(() => {
        (error && !children) && showModal({
            isVisible: true,
            content: renderModalError(
                upperCasedTitle,
                errorBackLink
            ),
        });
    }, [error]);

    if (children) {
        return (<div>
            {
                children({
                    data,
                    error,
                })
            }
        </div>);
    }

    const name = _get(data, path, null);

    if (!upperCasedTitle) {
        return name;
    }

    return `${upperCasedTitle}` + (name ? ` "${name}"` : '');
};
