import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {compose} from 'redux';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {Segment} from 'semantic-ui-react';
import {forEach as _forEach, get as _get, uniq as _uniq} from 'lodash';

import {convertBooleanValueForFilters, dropdownParser, getSearchFilters} from '@utils/filters';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {GetDropdownData, GetClientEventBookingsData} from '@graphql/clientEventBooking/query';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {convertToInt} from '@utils/helpers';
import {showConstraintsModal, skipSearch} from '@modules/client/utils/constraintsTables';
import {getTodayDate, getTomorrowDate} from '@utils/date';
import {mapPropertyLicencesForDropdown} from '@utils/propertyLicence';
import ClientEventBookingsTable from '@modules/client/components/ClientEventBookingsTable';
import {exportExcel as clientEventBookingsExportExcel} from '@modules/client/utils/export/clientEventBookingsExcel';
import {getCompetitors, getContentTierName} from '@modules/client/utils/clientBookingTable';
import {routes} from '@constants/routes';
import StepsContent from '@appComponents/StepsContent';
import {getLink} from '@appComponents/Link';
import {createForm} from '@utils/forms';
import ClientEventBookingGeneralInformationEditModel
    from '@modules/client/forms/ClientEventBooking/ClientEventBookingGeneralInformationEditModel';
import EventBookingClipsEditForm from '@modules/eventBooking/forms/EventBookingClipsEditForm';
import EventBookingBlackoutZonesEditForm from '@modules/eventBooking/forms/EventBookingBlackoutZonesEditForm';
import EventBookingGeneralInformationEditForm from '@modules/eventBooking/forms/EventBookingGeneralInformationEditForm';
import ClientEventBookingBlackoutZonesModel from '@modules/client/forms/ClientEventBooking/ClientEventBookingBlackoutZonesModel';
import mapModulesToProps from '@utils/mapModulesToProps';
import {getEventBookingFormParams} from '@modules/eventBooking/forms/helpers/helpers';
import {ClientEventBookingsFilters} from '../components/ClientEventBookingsFilters';
import ClientEventBookingGeoRestrictionsEditModel
    from "@modules/client/forms/ClientEventBooking/ClientEventBookingGeoRestrictionsEditModel";
import EventEventBookingGeoRestrictionsEditForm from "@modules/eventBooking/forms/EventEventBookingGeoRestrictionEditForm";
import ClientEventBookingClipsEditModel
    from "@modules/client/forms/ClientEventBooking/ClientEventBookingClipsEditModel";

const clientEventBookingsIndexRoute = 'clients.eventBookings.index';

class ClientEventBookings extends Component {
    static propTypes = {
        DataClientEventBookings: PropTypes.shape({
            clientEventBookings: PropTypes.arrayOf(PropTypes.object),
            loading: PropTypes.bool.isRequired,
        }),
        Modal: PropTypes.shape({
            setModal: PropTypes.func.isRequired,
        }),
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
        DataDropDown: PropTypes.object.isRequired,
        filters: PropTypes.shape({
            from: PropTypes.string,
            to: PropTypes.string,
            propertyLicences: PropTypes.arrayOf(PropTypes.number),
            eventTypes: PropTypes.number,
            clientPackages: PropTypes.arrayOf(PropTypes.number),
            sports: PropTypes.arrayOf(PropTypes.number),
            tournamentCategories: PropTypes.arrayOf(PropTypes.number),
            tournaments: PropTypes.arrayOf(PropTypes.number),
            contentCategoryLevel1: PropTypes.arrayOf(PropTypes.number),
            contentCategoryLevel2: PropTypes.arrayOf(PropTypes.number),
            contentCategoryLevel3: PropTypes.arrayOf(PropTypes.number),
            contentTiers: PropTypes.arrayOf(PropTypes.number),
            products: PropTypes.arrayOf(PropTypes.number),
            eventContentTypes: PropTypes.arrayOf(PropTypes.number),
            matchEventTypes: PropTypes.arrayOf(PropTypes.number),
            deviceCategories: PropTypes.arrayOf(PropTypes.number),
            countries: PropTypes.arrayOf(PropTypes.string),
            bookingTypes: PropTypes.arrayOf(PropTypes.number),
        }),
        match: PropTypes.object.isRequired,
        loadingRefetch: PropTypes.bool.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
    };

    static defaultProps = {
        DataClientEventBookings: {
            clientEventBookings: [],
        },
        filters: {
            from: getTodayDate(),
            to: getTomorrowDate(),
            propertyLicences: [],
            eventTypes: null,
            clientPackages: [],
            sports: [],
            tournamentCategories: [],
            tournaments: [],
            contentCategories: [],
            contentCategoryLevel1: [],
            contentCategoryLevel2: [],
            contentCategoryLevel3: [],
            contentTiers: [],
            products: [],
            eventContentTypes: [],
            matchEventTypes: [],
            deviceCategories: [],
            countries: [],
            bookingTypes: [],
        },
    };

    state = {
        clientEventBookings: [],
        clientEventBookingsOriginal: [],
        propertyLicences: [],
        eventTypes: null,
        clientPackages: [],
        contentTiers: [],
        products: [],
        eventContentTypes: [],
        event_booking_event_content_types: [],
        matchEventTypes: [],
        deviceCategories: [],
        countries: [],
        bookingTypes: [],
    };

    componentDidMount() {
        if (skipSearch(this.props)) {
            showConstraintsModal(this.props.filters);
        }


        this.loadModal(this.props.match, this.props.match.params);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            DataClientEventBookings: {clientEventBookings},
            DataDropDown: {
                propertyLicences,
                eventTypes,
                clientPackages,
                contentTiers,
                products,
                eventContentTypes,
                matchEventTypes,
                deviceCategories,
                countries,
                bookingTypes,
            },
        } = nextProps;

        const newState = {};

        if (propertyLicences && propertyLicences !== prevState.propertyLicences) {
            newState.propertyLicences = mapPropertyLicencesForDropdown(propertyLicences);
        }

        if (eventTypes && eventTypes !== prevState.eventTypes) {
            newState.eventTypes = dropdownParser(eventTypes).sort((a, b) => a.text.localeCompare(b.text));
        }

        if (clientPackages && clientPackages !== prevState.clientPackages) {
            newState.clientPackages = dropdownParser(clientPackages);
        }

        if (contentTiers && contentTiers !== prevState.contentTiers) {
            newState.contentTiers = dropdownParser(contentTiers);
        }

        if (products && products !== prevState.products) {
            newState.products = dropdownParser(products);
        }

        if (eventContentTypes && eventTypes !== prevState.eventContentTypes) {
            newState.eventContentTypes = dropdownParser(eventContentTypes).sort((a, b) => a.text.localeCompare(b.text));
        }

        if (matchEventTypes && matchEventTypes !== prevState.matchEventTypes) {
            newState.matchEventTypes = dropdownParser(matchEventTypes);
        }

        if (deviceCategories && prevState.deviceCategories !== []) {
            newState.deviceCategories = dropdownParser(deviceCategories);
        }

        if (countries && countries !== prevState.countries) {
            newState.countries = countries;
        }

        if (bookingTypes && prevState.bookingTypes !== []) {
            newState.bookingTypes = dropdownParser(bookingTypes);
        }

        if (clientEventBookings && prevState.clientEventBookingsOriginal !== clientEventBookings) {
            const resultBookings = [];

            _forEach(clientEventBookings, (booking) => {
                if (1 === booking.event_booking_geo_restrictions.length
                    && booking.event_booking_geo_restrictions[0].country_iso_alpha2_codes
                ) {
                    resultBookings.push(ClientEventBookings.createEventBookingForTable(
                        booking,
                        [booking.event_booking_geo_restrictions[0]]
                    ));
                } else if (!booking.event_booking_geo_restrictions.length
                    || null === booking.event_booking_geo_restrictions[0].country_iso_alpha2_codes
                ) {
                    resultBookings.push(ClientEventBookings.createEventBookingForTable(booking));
                } else {
                    const allCountries = booking.event_booking_geo_restrictions
                            .map(el => el.country_iso_alpha2_codes)
                            .reduce((prev, curr) => (prev.concat(curr))).sort(),
                        allBlackoutZones = booking.event_booking_geo_restrictions
                            .flatMap(el => el.blackout_zones)
                            .map(bz => bz.id),
                        uniqueCountries = _uniq(allCountries),
                        uniqueBlackoutZones = _uniq(allBlackoutZones),
                        deviceCategoriesCount = booking.event_booking_geo_restrictions.length;

                    if (
                        uniqueCountries.length === (allCountries.length / deviceCategoriesCount)
                        && uniqueBlackoutZones.length === (allBlackoutZones.length / deviceCategoriesCount)
                    ) {
                        const deviceCategoryName = booking.event_booking_geo_restrictions.map(
                            (gRestriction) => _get(gRestriction, 'device_category.name')
                        ).join(', ');

                        const geoRestriction = Object.assign(
                            {}, booking.event_booking_geo_restrictions[0], {device_category: {name: deviceCategoryName}}
                        );

                        if (null !== booking.event_booking_geo_restrictions[0].country_iso_alpha2_codes) {
                            resultBookings.push(ClientEventBookings.createEventBookingForTable(
                                booking,
                                [geoRestriction]
                            ));
                        } else {
                            resultBookings.push(ClientEventBookings.createEventBookingForTable(booking));
                        }
                    } else {
                        const geoRestrictions = [];

                        _forEach(booking.event_booking_geo_restrictions, (geoRestriction) => {
                            geoRestrictions.push(geoRestriction);
                        });

                        resultBookings.push(ClientEventBookings.createEventBookingForTable(
                            booking,
                            geoRestrictions,
                            true
                        ));
                    }
                }
            });

            newState.clientEventBookingsOriginal = clientEventBookings;
            newState.clientEventBookings = resultBookings;
        }

        return newState;
    }

    componentDidUpdate(prevProps) {
        const urlChanged = this.props.match.path !== prevProps.match.path,
            urlIsNotIndex = this.props.match.path !== routes.clients.eventBookings.index.path;

        if (urlChanged && urlIsNotIndex) {
            this.loadModal(this.props.match, this.props.match.params);
        }
    }

    loadModal(match, params = {}) {
        const eventBookingsPath = routes.clients.eventBookings,
            path = match.path;

        if (path === eventBookingsPath.edit.path
            || path === eventBookingsPath.clips.path
            || path === eventBookingsPath.editGeoRestrictions.path
            || path === eventBookingsPath.blackoutZones.path
        ) {
            this.showEventBookingEdit(params);
        }
    }

    showEventBookingEdit = (params) => {
        const [formParamsObject, linkParams] = getEventBookingFormParams(
            this.props.match.params.id,
            this.props.match.params.eventBookingId,
            params
        );
        let formParams = formParamsObject;

        return this.loadEditModalForm(
            {
                tabs: [
                    {
                        key: 'step1',
                        menuItem: {content: 'General information', disabled: false},
                        pane: {
                            content: createForm(
                                ClientEventBookingGeneralInformationEditModel,
                                EventBookingGeneralInformationEditForm,
                                formParams
                            ),
                        },
                        url: getLink('clients.eventBookings.edit', linkParams),
                    },
                    {
                        key: 'step2',
                        menuItem: {content: 'Clips', disabled: false},
                        pane: {
                            content: createForm(
                                ClientEventBookingClipsEditModel,
                                EventBookingClipsEditForm,
                                formParams
                            ),
                        },
                        url: getLink('clients.eventBookings.clips', linkParams),
                    },
                    {
                        key: 'step3',
                        menuItem: {content: 'Geo restrictions', disabled: false},
                        pane: {
                            content: createForm(
                                ClientEventBookingGeoRestrictionsEditModel,
                                EventEventBookingGeoRestrictionsEditForm,
                                Object.assign({}, {
                                    id: this.props.match.params.eventBookingId,
                                    optionsVariables: {
                                        id: this.props.match.params.eventBookingId,
                                        client: this.props.match.params.id
                                    },
                                })
                            ),
                        },
                        url: getLink('clients.eventBookings.editGeoRestrictions', linkParams),
                    },
                    {
                        key: 'step4',
                        menuItem: {content: 'Blackout Zones', disabled: false},
                        pane: {
                            content: createForm(
                                ClientEventBookingBlackoutZonesModel,
                                EventBookingBlackoutZonesEditForm,
                                formParams
                            ),
                        },
                        url: getLink('clients.eventBookings.blackoutZones', linkParams),
                    },
                ],
            },
            formParams
        );
    };

    loadEditModalForm = (Forms) => {
        this.props.Modal.setModal({
            content: <StepsContent
                title={'Edit event booking'}
                activeStep={Forms.active}
                tabs={Forms.tabs}
            />,
            isVisible: true,
            size: 'fullscreen',
        });
    };

    static createEventBookingForTable = (booking, geoRestrictions = [], hasMultipleGeoRestrictions = false) => ({
        id: booking.event_booking_id,
        event_booking_id: booking.event_booking_id,
        event_id: booking.event_id,
        match_id: booking.match_id,
        start_datetime: booking.start_datetime,
        end_datetime: booking.end_datetime,
        match_sport_name: booking.match_sport_name,
        match_tournament_category_name: booking.match_tournament_category_name,
        match_tournament_name: booking.match_tournament_name,
        sr_event_sport_name: booking.sr_event_sport_name,
        sr_event_tournament_category_name: booking.sr_event_tournament_category_name,
        sr_event_tournament_name: booking.sr_event_tournament_name,
        content_category_level_1_name: booking.content_category_level_1_name,
        content_category_level_2_name: booking.content_category_level_2_name,
        content_category_level_3_name: booking.content_category_level_3_name,
        event_type: booking.event_type,
        content_tier_name: getContentTierName(booking),
        competitors: getCompetitors(booking),
        product_short_name: booking.product_short_name,
        event_content_type_name: booking.event_content_type_name,
        match_event_type_name: booking.match_event_type_name,
        clips: this.getClips(booking),
        client_package_id: booking.client_package_id,
        client_package_name: booking.client_package_name,
        booking_type_name: booking.booking_type_name,
        event_booking_creation_datetime: booking.event_booking_creation_datetime,
        is_cancelled: booking.is_cancelled,
        booked_by: booking.booked_by,
        cancelled_by: booking.cancelled_by,
        event_booking_geo_restrictions: geoRestrictions,
        has_multiple_geo_restrictions: hasMultipleGeoRestrictions,
        cancellation_user_name: booking.cancellation_user_name,
    });

    static getClips = (booking) => {
        const eventContentTypes = booking.event_booking_event_content_types;

        if (!eventContentTypes) {
            return null;
        }

        const clips = eventContentTypes.map(contentType => {
            if (contentType.match_event_types && 0 < contentType.match_event_types.length) {
                const matchEventTypeNames = contentType.match_event_types.map(matchEventType => matchEventType.name);

                return `${contentType.name} (${matchEventTypeNames.join(', ')})`;
            } else {
                return contentType.name;
            }
        });

        return clips.join(', ');
    };

    render() {
        const clientId = convertToInt(this.props.match.params.id),
            loading = (_get(this.props, 'DataClientEventBookings.loading') || this.props.loadingRefetch),
            filtersProps = {
                additionalFilterId: this.props.match.params.id,
                data: {
                    propertyLicences: this.state.propertyLicences,
                    eventTypes: this.state.eventTypes,
                    clientPackages: this.state.clientPackages,
                    contentTiers: this.state.contentTiers,
                    products: this.state.products,
                    eventContentTypes: this.state.eventContentTypes,
                    matchEventTypes: this.state.matchEventTypes,
                    deviceCategories: this.state.deviceCategories,
                    countries: this.state.countries,
                    bookingTypes: this.state.bookingTypes,
                },
                loading: this.props.DataDropDown.loading,
            };

        return (
            <div>
                <HeaderRenderer
                    exportExcelParams={clientEventBookingsExportExcel(this.state.clientEventBookings, clientId)}
                    buttonsProps={{clientID: clientId}}
                    filters={ClientEventBookingsFilters}
                    filtersProps={filtersProps}
                    loading={loading}
                />
                <Segment basic className='--table'>
                    <ClientEventBookingsTable
                        clientEventBookings={this.state.clientEventBookings}
                        clientId={clientId}
                        loading={loading}
                    />
                </Segment>
            </div>
        );
    }
}

const ClientEventBookingsWithQuery = compose(
    graphql(GetClientEventBookingsData, {
        skip: (props) => {
            return skipSearch(props);
        },
        options: (props) => {
            const defaultFilters = ClientEventBookings.defaultProps.filters;
            const {
                from = defaultFilters.from,
                to = defaultFilters.to,
                propertyLicences = defaultFilters.propertyLicences,
                eventTypes = defaultFilters.eventTypes,
                sports = defaultFilters.sports,
                tournamentCategories = defaultFilters.tournamentCategories,
                tournaments = defaultFilters.tournaments,
                contentCategoryLevel1 = defaultFilters.contentCategoryLevel1,
                contentCategoryLevel2 = defaultFilters.contentCategoryLevel2,
                contentCategoryLevel3 = defaultFilters.contentCategoryLevel3,
                contentTiers = defaultFilters.contentTiers,
                products = defaultFilters.products,
                eventContentTypes = defaultFilters.eventContentTypes,
                matchEventTypes = defaultFilters.matchEventTypes,
                deviceCategories = defaultFilters.deviceCategories,
                countries = defaultFilters.countries,
                clientPackages = defaultFilters.clientPackages,
                bookingTypes = defaultFilters.bookingTypes,
                isCancelled = defaultFilters.isCancelled,
            } = props.filters;

            const variables = {
                client: convertToInt(props.match.params.id, 10),
                from: `${from}:00`,
                to: to ? `${to}:00` : defaultFilters.to,
                propertyLicences,
                eventTypes,
                sports,
                tournamentCategories,
                tournaments,
                contentCategoryLevel1,
                contentCategoryLevel2,
                contentCategoryLevel3,
                contentTiers,
                products,
                eventContentTypes,
                matchEventTypes,
                deviceCategories,
                countries,
                clientPackages,
                bookingTypes,
                isCancelled: convertBooleanValueForFilters(isCancelled, null),
            };

            return {
                fetchPolicy: 'cache-and-network',
                notifyOnNetworkStatusChange: true,
                variables,
                name: 'DataClientEventBookings',
            };
        },
        name: 'DataClientEventBookings',
    }),
    graphql(GetDropdownData, {
        options: (props) => {
            return {
                fetchPolicy: 'cache-and-network',
                notifyOnNetworkStatusChange: true,
                variables: {
                    client: [convertToInt(props.match.params.id)],
                },
            };
        },
        name: 'DataDropDown',
    })
)(FilterQueryWrapper(ClientEventBookings, {
    queryForRefresh: 'DataClientEventBookings',
    filterUrls: [clientEventBookingsIndexRoute],
}));

const mapStateToProps = (state, ownProps) => ({
    filters: getSearchFilters(state, `ClientEventBookings${ownProps.match.params.id}`, ClientEventBookings.defaultProps.filters),
});

const mapDispatchToProps = mapModulesToProps(['Modal']);

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(ClientEventBookingsWithQuery, ClientEventBookings.defaultProps.filters, clientEventBookingsIndexRoute)
)));
