import React from 'react';
import PropTypes from 'prop-types';
import {
    isEmpty as _isEmpty,
    isNumber as _isNumber,
} from 'lodash';
import {Link as ReactLink} from 'react-router-dom';
import {compile as compileUrl} from 'path-to-regexp';

import {routes} from '@constants/routes';

export const MESSAGE_ROUTE_IS_NULL = 'route name cannot be null';
export const MESSAGE_ROUTE_NOT_FOUND = (name) => `route ${name} was not found`;

/**
 * @ignore
 */
const getLink = (name, params, query, hash) => {
    if (null === name) return null;

    let routeObject = name.split('.').reduce((o, i) => (o === undefined ? o : o[i]), routes),
        url = false;

    if (routeObject !== undefined && routeObject.path !== undefined) {
        url = routeObject.path;

        if (params !== undefined && 0 < Object.keys(params).length) {
            if (Object.values(params).some(value => (_isEmpty(value) && !_isNumber(value)))) {
                return url;
            }

            let toPath = compileUrl(url);

            url = toPath(params);
        }

        if (query !== undefined) {
            let queryString = Object.keys(query).map((queryKey) => (
                queryKey + '=' + query[queryKey]
            )).join('&');

            url = url + '?' + queryString;
        }

        if (hash) {
            url += hash;
        }
    }

    return url;
};

/**
 * @ignore
 */
const getQueryParams = (queryString) => {
    if (!queryString) {
        return {};
    }

    return JSON.parse('{"' + decodeURI(queryString.substring(1))
        .replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

};

export {getLink, getQueryParams};

/**
 * @ignore
 */
export class Link extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string,
        ]),
        hash: PropTypes.string,
        newWindow: PropTypes.bool,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        name: PropTypes.string,
        params: PropTypes.object,
        query: PropTypes.object,
        options: PropTypes.object,
        target: PropTypes.oneOf([
            null,
            '_blank',
        ]),
    };

    static defaultProps = {
        hash: null,
        target: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            url: getLink(props.name, props.params, props.query, props.hash),
        };

        this.onClick = this.onClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.name !== prevProps.name || this.props.params !== prevProps.params) {
            this.setState(function () {
                return {
                    url: getLink(this.props.name, this.props.params, this.props.query, this.props.hash),
                };
            });
        }
    }

    onClick(event) {
        if (this.props.newWindow) {
            return false;
        }

        if (this.props.options && this.props.options.stopPropagation) {
            event.stopPropagation();
        }
    }

    render() {
        if (null === this.state.url) {
            return (
                <div>{MESSAGE_ROUTE_IS_NULL}</div>
            );
        }

        if (false === this.state.url) {
            return (
                <div>{`route ${this.props.name} was not found`}</div>
            );
        }

        let newWindowObject = {};

        if (this.props.newWindow) {
            newWindowObject = {
                target: '_blank',
            };
        }

        return (
            <ReactLink {...newWindowObject}
                onClick={this.onClick}
                to={this.state.url}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
            >
                {this.props.children}
            </ReactLink>
        );
    }
}

export default Link;
