/**
 * Action types
 */
export const AUTHENTICATION_CHECK = "AUTHENTICATION_CHECK";
export const AUTHENTICATION_FAILURE = "AUTHENTICATION_FAILURE";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const SAVE_USER = "SAVE_USER";

export const MENU_STORE = 'MENU_STORE';
export const MENU_SAVE = 'MENU_SAVE';
export const MENU_TOGGLE = 'MENU_TOGGLE';

export const CLEAR_MESSAGE_BOX = "CLEAR_MESSAGE_BOX";

export const FETCH_AUTHENTICATION_FAILURE = "FETCH_AUTHENTICATION_FAILURE";

export const PAGINATOR_PAGINATE = "PAGINATOR_PAGINATE";
export const PAGINATOR_CHANGE_PAGE = "PAGINATOR_CHANGE_PAGE";
export const PAGINATOR_SORT = "PAGINATOR_SORT";

export const SET_MODAL = "SET_MODAL";
export const SET_MODAL_CONFIRMATION = "SET_MODAL_CONFIRMATION";
export const SET_MODAL_ERROR = "SET_MODAL_ERROR";

export const SET_REDIRECT_LOADER_VISIBILITY = 'SET_REDIRECT_LOADER_VISIBILITY';

export const SET_FILTER = "SET_FILTER";
export const SET_INITIAL_FILTER = "SET_INITIAL_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const FILTERS_VISIBILITY = "FILTERS_VISIBILITY";

export const REDIRECT_TO_AUTH_LOGIN = "REDIRECT_TO_AUTH_LOGIN";

export const SAVE_MESSAGE_BOX = "SAVE_MESSAGE_BOX";
export const SUBMIT_FORM = "SUBMIT_FORM";

export const ENTITY_STORE = "ENTITY_STORE";
export const ENTITY_REMOVE = 'ENTITY_REMOVE';

export const SESSION_END = 'SESSION_END';

export const SET_TIMEZONE = 'SET_TIMEZONE';

export const SET_CLOSED_TIMESTAMP = 'SET_CLOSED_TIMESTAMP';

export const SET_SCROLL = 'SET_SCROLL';

export const SET_TABLE_PARAMS = 'SET_TABLE_PARAMS';

export const TOGGLE_STREAM_PLAYBACK_DEBUG_MODE = 'TOGGLE_STREAM_PLAYBACK_DEBUG_MODE';
