import React from 'react';
import PropTypes from 'prop-types';
import {Header} from 'semantic-ui-react';

import {renderEmailPreviewButtons} from '@modules/eventContentAlert/utils/helpers';
import EmailPreviewAlertTable from '@modules/eventContentAlert/components/EmailPreviewAlertTable';

const EmailAlertPreviewModal = ({
    dataForPreview,
    submittedData,
    sanitizedContent,
    handleBackButtonClick,
    sending,
    sendEmail,
}) => {
    return (
        <div className='AlertPreviewModal'>
            <Header content={'Send alert to clients - Email preview'}/>
            <div className='AlertPreviewContent'>
                <Header content={'Title'}/>
                <div className={'--title'}>{dataForPreview.subject}</div>
                <div className='emailPreviewBody'>
                    <Header content={'Body'}/>
                    {/*eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{__html: sanitizedContent}}/>
                </div>
                <div className='emailPreviewRecipients'>
                    <Header content={'Recipients'}/>
                    <EmailPreviewAlertTable data={dataForPreview.recipients}/>
                    {0 < submittedData.additional_recipients?.length && (
                        <div className='additionalRecipients'>
                            <strong>Additional recipients: </strong>
                            {submittedData.additional_recipients.join(', ')}
                        </div>
                    )}
                </div>
            </div>
            <div className='AlertPreviewFooter'>
                {renderEmailPreviewButtons({handleBackButtonClick, sending, sendEmail})}
            </div>
        </div>
    );
};

EmailAlertPreviewModal.propTypes = {
    dataForPreview: PropTypes.object,
    submittedData: PropTypes.object,
    sanitizedContent: PropTypes.string,
    handleBackButtonClick: PropTypes.func,
    sending: PropTypes.bool,
    sendEmail: PropTypes.func,
};

export default EmailAlertPreviewModal;
