import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, Icon} from 'semantic-ui-react';

import ExcelExportButton from '@appComponents/ExcelExportButton';
import {Authorization, AuthorizationAny} from '@appComponents/Authorization';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {routes} from '@constants/routes';

const eventAuthorizationObject = {
    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_CREATE,
};

const eventGroupNotificationAuthorizationObject = {
    [RESOURCES.EVENT_GROUP_NOTIFICATION]: CONST.SECURITY_PRIVILEGES_CREATE,
};

const applyPresetUpdateObject = {
    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_UPDATE,
};

const setCoverageUpdateObject = {
    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_UPDATE,
};

const reportMultipleAsWorkingAndInvoiceableAuthorizationObject ={
    [RESOURCES.EVENT_REPORT]: [CONST.SECURITY_PRIVILEGES_CREATE, CONST.SECURITY_PRIVILEGES_UPDATE],
    [RESOURCES.EVENT_INVOICE_STATUS]: [CONST.SECURITY_PRIVILEGES_CREATE, CONST.SECURITY_PRIVILEGES_UPDATE],
};

const renderDropdownItem = (type, key, action) => {
    return (
        <Dropdown.Item
            text={routes.events[type][key].label}
            key={routes.events[type][key].path}
            data-key={routes.events[type][key].path}
            data-idkey={key}
            onClick={action}
        />
    );
};

const renderDropdownButton = (text) => (
    <span><Icon name='plus circle'/> {text}</span>
);

renderDropdownButton.propTypes = {
    labelButton: PropTypes.string,
};

const EventsIndexButtons = (props) => (
    <div className='content__buttons'>
        <Authorization authorization={eventAuthorizationObject}>
            <Dropdown button trigger={renderDropdownButton('Create event')}>
                <Dropdown.Menu>
                    {renderDropdownItem('add', 'sportradarNonExisting', props.addEventHandler)}
                    {renderDropdownItem('add', 'nonSportradar', props.addEventHandler)}
                </Dropdown.Menu>
            </Dropdown>
        </Authorization>
        <ExcelExportButton exportExcelParams={props.excelExport} loading={props.loading}/>
        <AuthorizationAny authorizationObjects={[
            eventAuthorizationObject,
            eventGroupNotificationAuthorizationObject,
            applyPresetUpdateObject,
            reportMultipleAsWorkingAndInvoiceableAuthorizationObject,
            setCoverageUpdateObject,
        ]}>
            <Dropdown button text='Selection' direction='left'>
                <Dropdown.Menu>
                    <Authorization authorization={eventAuthorizationObject}>
                        <Dropdown.Item
                            text={'Create Sportradar events'}
                            data-idkey={'createSportradarEvents'}
                            onClick={props.addEventHandler}
                        />
                    </Authorization>
                    <Authorization authorization={applyPresetUpdateObject}>
                        <Dropdown.Item
                            text={'Apply preset'}
                            data-idkey={'applyPresetToEvents'}
                            onClick={props.addEventHandler}
                        />
                    </Authorization>
                    <Authorization authorization={setCoverageUpdateObject}>
                        <Dropdown.Item
                            text={'Set coverage'}
                            data-idkey={'setCoverageToEvents'}
                            onClick={props.addEventHandler}
                        />
                    </Authorization>
                    <Authorization authorization={eventGroupNotificationAuthorizationObject}>
                        {renderDropdownItem('selection', 'eventGroupNotification', props.addEventHandler)}
                    </Authorization>
                    <Authorization authorization={reportMultipleAsWorkingAndInvoiceableAuthorizationObject}>
                        <Dropdown.Item
                            text={'Report as working and invoiceable'}
                            data-idkey={'reportMultipleAsWorkingAndInvoiceable'}
                            onClick={props.addEventHandler}
                        />
                    </Authorization>
                </Dropdown.Menu>
            </Dropdown>
        </AuthorizationAny>
    </div>
);

EventsIndexButtons.propTypes = {
    addEventHandler: PropTypes.func.isRequired,
    excelExport: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default EventsIndexButtons;
