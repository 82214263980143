import {get as _get, uniq as _uniq} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import {convertToInt} from '@utils/helpers';
import {getCompetitorsNameFromEvent} from '@utils/competitorName';
import {addHasMatchIdToStructure} from '@utils/eventObject';
import {PROPERTY_LICENCE_TYPE_OTHER, PROPERTY_LICENCE_TYPE_SPORTRADAR} from '@constants/variables';

export const exportExcel = (dataExport, propertyLicenceId, propertyLicenceTypeId) => {
    const fieldIndex = {
        DATE_START: 1,
        COMPETITORS: 6,
        DESCRIPTION: 7,
    };
    const getProducts = (contents) => (
        _uniq(contents.flatMap((content) => (
            content.products.slice().sort((a, b) => (convertToInt(a.product_id) > convertToInt(b.product_id) ? 1 : -1)).map(product => product.short_name)
        ))).join(', ')
    );

    const getData = (event) => {
        event = addHasMatchIdToStructure(event);

        let data = [
            event.id,
            `${moment(event.start_datetime).format('YYYY-MM-DD')}`,
            `${moment(event.start_datetime).format('HH:mm')}`,
            `${event.end_datetime ? moment(event.end_datetime).format('YYYY-MM-DD') : ''}`,
            `${event.end_datetime ? moment(event.end_datetime).format('HH:mm') : ''}`,
            getCompetitorsNameFromEvent(event),
            _get(event, 'description', ''),
            _get(event, 'event_status.name', ''),
            event.event_contents ? getProducts(event.event_contents) : '',
            event.bookings,
            _get(event, 'origin_stream_status.name', ''),
            event.event_issue_types?.map(type => type.name).sort().join(', '),
            _get(event, 'comment', ''),
        ];

        if (PROPERTY_LICENCE_TYPE_SPORTRADAR === propertyLicenceTypeId) {
            data.splice(fieldIndex.DATE_START, 0, _get(event, 'sr_event.match.id', ''));
            data.splice(
                fieldIndex.DESCRIPTION,
                0,
                _get(
                    event,
                    'sr_event.match.tournament.tournament_category.name',
                    _get(event, 'sr_event.tournament.tournament_category.name', '')
                ),
                _get(
                    event,
                    'sr_event.match.tournament.name',
                    _get(event, 'sr_event.tournament.name', '')
                )
            );
        } else if (PROPERTY_LICENCE_TYPE_OTHER === propertyLicenceTypeId) {
            data.splice(
                fieldIndex.COMPETITORS,
                0,
                _get(event, 'av_event.content_category_level2.name', ''),
                _get(event, 'av_event.content_category_level3.name', '')
            );
        }

        event.products_invoice_status.forEach((productInvoiceStatus) => {
            if (productInvoiceStatus.has_usage) {
                data.push(_get(productInvoiceStatus, 'invoice_status.name', ''));
            }
        });

        return data;
    };

    const getColumns = () => {
        let columns = [
            'ID',
            'Date start',
            'Time start',
            'Date end',
            'Time end',
            'Competitors',
            'Description',
            'Status',
            'Products',
            'Bookings',
            'Origin stream status',
            'Issue types',
            'Comment',
        ];

        if (PROPERTY_LICENCE_TYPE_SPORTRADAR === propertyLicenceTypeId) {
            columns.splice(fieldIndex.DATE_START, 0, 'Match ID');
            columns.splice(fieldIndex.DESCRIPTION, 0, 'Category', 'Tournament');
        } else if (PROPERTY_LICENCE_TYPE_OTHER === propertyLicenceTypeId) {
            columns.splice(fieldIndex.COMPETITORS, 0, '2nd level category', '3rd level category');
        }

        if (0 < dataExport.length) {
            dataExport[0].products_invoice_status.forEach((productInvoiceStatus) => {
                if (productInvoiceStatus.has_usage) {
                    columns.push(`Invoice status ${productInvoiceStatus.short_name}`);
                }
            });
        }

        return columns;
    };

    return {
        dataExport: dataExport,
        filename: `property_licence_${propertyLicenceId}_events`,
        parseData: event => getData(event),
        path: 'propertyLicences.events.index.path',
        titles: getColumns(),
    };
};

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
