import { isEmpty as _isEmpty, trim as _trim } from 'lodash';

import { convertToInt } from '@utils/helpers';

export const prepareDataForSubmit = (data, ModelDefinition) => {
    const dataToSave = {...ModelDefinition.dataMap[ModelDefinition.entityDataMapKey], ...data};

    if (!_isEmpty(data)) {
        dataToSave.id = convertToInt(data?.id) || null;
        dataToSave.name = _trim(data?.name) || null;
        dataToSave.stream_name = _trim(data?.stream_name) || null;
    }

    return dataToSave;
};
