import React from 'react';
import {useParams, withRouter} from 'react-router';

import {createForm, renderModalError} from '@utils/forms';
import WnsStreamModel from '@modules/wnsStream/forms/WnsStreamModel';
import WnsStreamForm from '@modules/wnsStream/forms/WnsStreamForm';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {ButtonBackToList} from '@appComponents/ButtonCollection';
import {GetWorldNumberServiceForTitle} from '@graphql/wnsStream/query';
import {TitleFromQuery} from '@appComponents/HeaderWithQuery';
import {getLink} from '@appComponents/Link';
import {routes} from '@constants/routes';
import {showModal} from '@utils/modal';
import {convertToInt, isUrlParamValid} from '@utils/helpers';

const WnsStreamsEdit = () => {
    const {id} = useParams(),
        WnsEditForm = React.useMemo(
            () => (createForm(WnsStreamModel, WnsStreamForm, {id: convertToInt(id)})),
            []
        ),
        WnsStreamEditTitleButtons = () => (
            <ButtonBackToList name={'wnsStreams.index'}/>
        );

    if (!isUrlParamValid(id)) {
        showModal({
            isVisible: true,
            content: renderModalError('World Number Service Stream', routes.wnsStreams.index.path),
        });
    }

    return (
        <div>
            <HeaderRenderer
                buttons={WnsStreamEditTitleButtons}
                pageTitle={
                    <TitleFromQuery
                        query={GetWorldNumberServiceForTitle}
                        variables={{id}}
                        errorBackLink={getLink('wnsStreams.index')}
                        path={'worldNumberService.name'}
                        entity={'World Number Service Stream'}
                    />
                }
            />
            <WnsEditForm/>
        </div>
    );
};

export default withRouter(WnsStreamsEdit);
