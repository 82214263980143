import PropTypes from 'prop-types';

import {getProperty} from '../common';

/**
 * Display value for Sport
 */
export const sport = (event) => {
    const eventData = {
        data: event,
        srWithMatchID: ['match', 'tournament', 'tournament_category', 'sport'],
        srWithoutMatchID: ['tournament', 'tournament_category', 'sport'],
    };

    return getProperty(eventData, 'name');
};

sport.propTypes = {
    event: PropTypes.object.isRequired,
};
