import React from 'react';
import PropTypes from 'prop-types';

import wnsStreamTableColumns from '@modules/wnsStream/components/WnsStreamTable/wnsStreamTableColumns';
import wnsStreamTableRenderer from '@modules/wnsStream/components/WnsStreamTable/wnsStreamTableRenderer';
import Table from '@modules/app/components/Table';

const WnsStreamTable = ({data, loading}) => {
    return (
        <Table
            name='wnsStreamsTable'
            data={data}
            loading={loading}
            defaultSort={'name'}
            noDataText='No World Number Service streams found'
            columns={wnsStreamTableColumns}
            rowRenderer={wnsStreamTableRenderer}
            pagination={false}
        />
    );
};

WnsStreamTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
};

export default WnsStreamTable;
