import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import {has as _has} from 'lodash';

import {EVENT} from '@constants/resources';
import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';

import {isSportradarEvent} from '../../../../utils/eventType';

const authorizationEventUpdateObject = {
    [EVENT]: SECURITY_PRIVILEGES_UPDATE,
};

/**
 * Edit linked match id on event
 */
const ButtonEditLinkMatch = ({event, handleOpenLinkToMatchIDModal}) => {
    /**
     * We can edit linked match only with Sportradar Event
     * We can edit only if event is linked with match
     */
    if (!isSportradarEvent(event) || !_has(event, 'sr_event.match.id')) {
        return null;
    }

    return (
        <Authorization authorization={authorizationEventUpdateObject}>
            <Dropdown.Item
                icon='linkify'
                text='Link to another Sportradar match'
                key={'editSportradarMatch'}
                onClick={handleOpenLinkToMatchIDModal}
            />
        </Authorization>
    );
};

ButtonEditLinkMatch.propTypes = {
    event: PropTypes.object.isRequired,
    handleOpenLinkToMatchIDModal: PropTypes.func.isRequired,
};

export default ButtonEditLinkMatch;
