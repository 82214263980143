import React from 'react';
import {withRouter} from 'react-router';
import {get as _get} from 'lodash';
import {Button, Header} from 'semantic-ui-react';

import DefaultForm from '@appComponents/DefaultForm';
import {getTodayDate} from '@utils/date';
import {getLink} from '@appComponents/Link';
import {hideModal, showModalConfirmation} from '@utils/modal';
import {DeleteClientVplLicenceKey} from '@graphql/clientVplLicenceKey/mutation';
import {showMessageBox} from '@utils/messageBox';

export class ClientVplLicenceKeyForm extends DefaultForm {
    componentDidMount() {
        const todayDate = getTodayDate();
        const formParams = this.props.formParams;
        const formValues = this.props.formValues;

        this.setCreateSuccessCallback(() => {
            this.redirectToPage('clients.clientVideoPlayer.index');
        });

        this.setUpdateSuccessCallback(() => {
            this.redirectToPage('clients.clientVideoPlayer.index');
        });

        if (!formParams.id) {
            this.setField('licence_key', {
                defaultValue: 'The key will be generated automatically.',
            });

            this.setField('expiry_date', {
                defaultValue: formValues.expiry_date || todayDate,
                configuration: {
                    minDate: todayDate,
                },
            });
        }

        if (formParams.id && formParams.formData) {
            this.setField('licence_key', {
                defaultValue: formParams.formData.licence_key,
            });
            this.setField('expiry_date', {
                defaultValue: formValues.expiry_date ? formValues.expiry_date : formParams.formData.expiry_date,
            });
        }
    }

    redirectToPage = (link) => {
        const clientId = this.props.formParams.optionsVariables.client;

        this.props.history.push(getLink(link, {id: clientId}));
    };

    prepareDataForSubmit() {
        const domains = _get(this.props, 'formParams.domains', []);

        return {
            id: this.props.formParams.id || null,
            clientId: this.props.formParams.optionsVariables.client,
            expiryDate: this.props.formValues.expiry_date,
            clientVplDomains: domains.map(({domain, is_disabled}) => ({domain, is_disabled})),
        };
    }

    deleteLicenceKey = () => {
        this.runApolloRequest('mutate', {
            mutation: DeleteClientVplLicenceKey,
            variables: {
                id: this.props.formParams.id,
            },
        }).then(() => {
            showMessageBox(
                'clientMessage',
                'The licence key has been deleted successfully.',
                null,
                'success'
            );

            this.redirectToPage('clients.clientVideoPlayer.index');
            hideModal();
        }).catch((error) => {
            showMessageBox(
                'clientMessage',
                'There was an error while deleting the licence key.',
                `${error}`,
                'error'
            );
            hideModal();
        });
    };

    deleteButtonClick = (e) => {
        e.preventDefault();

        showModalConfirmation({
            header: <Header icon='trash' content='Delete licence key'/>,
            onYes: this.deleteLicenceKey,
            text: 'Are you sure you want to delete the licence key?',
        });
    };

    renderDeleteButton = () => {
        let deleteButton = null;

        if (this.props.formParams.id) {
            deleteButton = (
                <Button onClick={this.deleteButtonClick}
                    color='red'
                    loading={this.props.submitting}
                    disabled={this.props.submitting}
                    icon='trash'
                    content='Delete'
                />
            );
        }

        return deleteButton;
    };

    renderSaveButton = () => {
        if (!this.props.formParams.id) {
            return super.renderSaveButton();
        } else {
            return super.renderSaveButton({content: 'Save'});
        }
    };

    renderCancelButton = () => null;

    renderErrors(errorData) {
        super.renderErrors(errorData, 'Client video player', getLink('clientVideoPlayer.index'));
    }
}

export default withRouter(ClientVplLicenceKeyForm);
