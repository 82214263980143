import {has as _has, isUndefined as _isUndefined} from 'lodash';

import {convertToInt} from '@utils/helpers';

export const filteredIngestMethodsToDropdownOptions = (ingestMethods, selectedIngestMethod) => (
    ingestMethods.filter((notDisabledIngestMethodOrSelected) => (
        !notDisabledIngestMethodOrSelected.is_disabled || notDisabledIngestMethodOrSelected.value === selectedIngestMethod
    ))
);

export const compareAndSetFields = (
    prevProps,
    currentProps,
    handleCdnChanged,
    handleHasExternalStorageChanged,
    dataLoaded,
    setState,
    setField = () => {}
) => {
    const hasValidId = currentProps.formParams && null !== currentProps.formParams.id;
    const hasEntityData = currentProps.GraphQLEntityData && !currentProps.GraphQLEntityData.loading;
    const hasOptionsData = !currentProps.GraphQLOptionsData.loading;
    const isDataNotLoaded = !dataLoaded;
    const hasClientCdnConfig = currentProps.GraphQLEntityData?.clientCdnConfiguration;

    if (currentProps.formValues.client_product
        && convertToInt(currentProps.formValues.client_product) !== convertToInt(prevProps.formValues.client_product)
    ) {
        handleFieldChange(
            prevProps.formValues.client_product,
            currentProps.formValues.client_product,
            setField,
            'client_product',
            () => {
                setField('live_encoding_job_profile', {defaultValue: null});
                setField('recording_encoding_job_profile', {defaultValue: null});
            }
        );
    }

    handleFieldChange(
        prevProps.formValues.live_encoding_job_profile,
        currentProps.formValues.live_encoding_job_profile,
        setField,
        'live_encoding_job_profile',
        (currentValue) => {
            setField('live_encoding_job_profile', {defaultValue: currentValue});
        }
    );

    handleFieldChange(
        prevProps.formValues.recording_encoding_job_profile,
        currentProps.formValues.recording_encoding_job_profile,
        setField,
        'recording_encoding_job_profile',
        (currentValue) => {
            setField('recording_encoding_job_profile', {defaultValue: currentValue});
        }
    );

    handleFieldChange(
        prevProps.GraphQLOptionsData.cdns,
        currentProps.GraphQLOptionsData.cdns,
        setField,
        'cdn',
        (currentValue) => {
            setField('cdn', {options: currentValue, allowClear: false});
        }
    );

    handleFieldChange(
        prevProps.GraphQLOptionsData.clientProducts,
        currentProps.GraphQLOptionsData.clientProducts,
        setField,
        'client_product',
        (currentValue) => {
            setField('client_product', {
                options: currentValue.map(clientProduct => ({
                    key: clientProduct.id,
                    text: clientProduct.product.name,
                    value: convertToInt(clientProduct.id),
                })),
                allowClear: false,
            });
        }
    );

    handleFieldChange(
        prevProps.GraphQLOptionsData.akamaiCdnIngestMethods,
        currentProps.GraphQLOptionsData.akamaiCdnIngestMethods,
        setField,
        'akamai_cdn_ingest_method',
        (currentValue) => {
            setField('akamai_cdn_ingest_method', {options: currentValue, allowClear: false});
        }
    );

    if (hasValidId && hasEntityData && hasOptionsData && isDataNotLoaded && hasClientCdnConfig) {
        const clientCdnConfiguration = currentProps.GraphQLEntityData.clientCdnConfiguration;

        setField('client_product', {
            defaultValue: convertToInt(clientCdnConfiguration.client_product.id),
        });

        setField('cdn', {
            defaultValue: clientCdnConfiguration.cdn.id,
        });

        handleCdnChanged(convertToInt(clientCdnConfiguration.cdn.id));

        handleHasExternalStorageChanged(clientCdnConfiguration.has_external_storage);

        if (_has(clientCdnConfiguration.live_encoding_job_profile, 'id')) {
            setField('live_encoding_job_profile', {
                defaultValue: clientCdnConfiguration.live_encoding_job_profile.id,
            });
        }

        if (_has(clientCdnConfiguration.recording_encoding_job_profile, 'id')) {
            setField('recording_encoding_job_profile', {
                defaultValue: clientCdnConfiguration.recording_encoding_job_profile.id,
            });
        }

        setField('encoding_datacenters', {
            defaultValue: clientCdnConfiguration.encoding_datacenters.map((encodingDatacenter) => (
                encodingDatacenter.id
            )),
        });

        setState(() => ({dataLoaded: true}));
    }
};

export const handleFieldChange = (prevValue, currentValue, setField, fieldName, action) => {
    if (prevValue !== currentValue && !_isUndefined(currentValue)) {
        action(currentValue, setField);
    }
};
