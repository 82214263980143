import React from 'react';

import {CLIENT_VPL_LICENCE_KEY} from '@constants/resources';
import {CreateClientVplLicenceKey, UpdateClientVplLicenceKey} from '@graphql/clientVplLicenceKey/mutation';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import validate from '@modules/client/forms/validatorClientVplDomains';
import ClientVplDomainsTable from '@modules/client/forms/ClientVplDomainsTable';

const displayDomainsTable = (data, domains, setDomains) => {
    return (
        <ClientVplDomainsTable data={data} domains={domains} setDomains={setDomains}/>
    );
};

const ClientVplLicenceKeyModel = (domains, setDomains) => {
    return {
        deleteId: 'clientVplLicenceKey.id',
        formName: 'Client VPL licence key',
        entityLabel: 'licence key',
        entityName: 'licence key',
        entityDataMapKey: 'clientVplLicenceKey',
        resources: CLIENT_VPL_LICENCE_KEY,
        showChangeLog: true,
        dataFromFormParams: true,
        title: null,
        validate: validate,
        dataMap: {
            clientVplLicenceKey: {
                id: '',
                licence_key: '',
                expiry_date: '',
                client_vpl_domains: [],
            },
        },
        messages: {
            boxName: {
                delete: 'clientMessage',
                error: 'formInnerErrorMessage',
                success: 'clientMessage',
            },
            text: {
                ...MESSAGES,
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'clientVplLicenceKey.id',
                props: {
                    label: '',
                    name: 'id',
                    hidden: true,
                },
            },
            licence_key: {
                dataMapKey: 'clientVplLicenceKey.licence_key',
                props: {
                    className: '--align-self-baseline',
                    component: Form.TextPlaceholder,
                    label: 'Licence key',
                    name: 'licence_key',
                },
            },
            expiry_date: {
                dataMapKey: 'clientVplLicenceKey.expiry_date',
                props: {
                    className: '--align-self-baseline --width-60-percent',
                    component: Form.SemanticInput,
                    label: 'Expiry date',
                    name: 'expiry_date',
                    type: 'date',
                    required: true,
                },
            },
            client_vpl_domains: {
                component: (props) => displayDomainsTable(props.data, domains, setDomains),
                props: {
                    name: 'client_vpl_domains',
                },
            },
        },
        mutation: {
            createEntity: CreateClientVplLicenceKey,
            updateEntity: UpdateClientVplLicenceKey,
        },
        query: {},
    };
};

export default ClientVplLicenceKeyModel;
