import PropTypes from 'prop-types';
import moment from 'moment';
import {filter as _filter, flatten as _flatten, uniq as _uniq} from 'lodash';

import {convertToInt} from './helpers';

/**
 * Get available products based on event and right scopes *
 * @param {Object} event
 * @param {Object[]} rightsScopes
 * @returns {string[]}
 */
export const getProductsFromEventAndRightScopes = (event, rightsScopes) => {
    return _uniq(_flatten(
        avaliableProductsBasedOfRightScopes(event, rightsScopes).map((rightsScope) => (
            rightsScope.usages.map((rightsScopeUsage) => (
                rightsScopeUsage.product.id
            ))
        )))
    );
};

getProductsFromEventAndRightScopes.propTypes = {
    event: PropTypes.object.isRequired,
    rightsScopes: PropTypes.object.isRequired,
};

/**
 * @param {Object} event
 * @param {Object[]} rightsScopes
 * @returns {string[]}
 */
export const avaliableProductsBasedOfRightScopes = (event, rightsScopes) => {
    let filtered = [];

    if (event.sr_event) {
        filtered = _filter(rightsScopes, ((rightsScope) => (
            rightsScope.includes_all_content
                ? rightsScope
                : 0 < _filter(rightsScope.contents, ((content) => (
                    event.sr_event.match && event.sr_event.match.tournament
                        ? convertToInt(content.tournament.id) === convertToInt(event.sr_event.match.tournament.id)
                        : convertToInt(content.tournament.id) === convertToInt(event.sr_event.tournament.id)
                ))).length
        )));
    } else if (event.av_event) {
        filtered = _filter(rightsScopes, ((rightsScope) => (
            rightsScope.includes_all_content
                ? rightsScope
                : 0 < _filter(rightsScope.contents, ((content) => (
                    convertToInt(content.content_category.id) === convertToInt(event.av_event.content_category.id)
                ))).length
        )));
    }

    const formatedEventStartDatetime = moment(event.start_datetime).format('YYYY-MM-DD');

    return _filter(filtered, (item) => (
        moment(formatedEventStartDatetime).isBetween(item.start_date, item.end_date, null, '[]')
    ));
};

avaliableProductsBasedOfRightScopes.propTypes = {
    event: PropTypes.object.isRequired,
    rightsScopes: PropTypes.object.isRequired,
};
