import React from 'react';
import PropTypes from 'prop-types';
import {map as _map} from 'lodash';

import Table from '@appComponents/Table';
import {getLink} from '@appComponents/Link';
import {IconCheckmark, IconEdit} from '@appComponents/IconCollection';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {CLIENT_PACKAGE} from '@constants/resources';
import {SECURITY_PRIVILEGES_UPDATE} from '@constants/variables';
import Authorization from '@appComponents/Authorization';

const columns = {
    'id': {
        label: 'ID',
        sorting: row => parseInt(row.id, 10),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'client_product': {
        label: 'Product',
        sorting: (row) => row.client_product.product.short_name,
    },
    'name': {
        label: 'Name',
        sorting: (row) => row.name.toLowerCase(),
    },
    'type': {
        label: 'Type',
        sorting: (row) => row.client_package_type.name,
    },
    'booking_type': {
        label: 'Booking',
        sorting: (row) => row.booking_type.name,
    },
    'content_package_selection_type': {
        label: 'Content selection',
        sorting: (row) => row.content_package_selection_type.name,
    },
    'event_content_variants': {
        label: 'Content variants',
        sorting: (row) => _map(
            row['event_content_variants'], (eventContentVariant) => eventContentVariant.name
        ).join(', '),
    },
    'start_date': {
        label: 'Start date',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
        sorting: (row) => row.start_date,
    },
    'end_date': {
        label: 'End date',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
        sorting: (row) => row.end_date,
    },
    'client_package_geo_restrictions': {
        label: 'Geo restrictions',
        sorting: (row) => row.client_package_geo_restrictions.length > 0,
    },
    'is_hq': {
        label: 'HQ',
        sorting: (row) => (row.is_hq),
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'is_test': {
        label: 'Test',
        sorting: (row) => (row.is_test),
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

const PackagesTable = (props) => {
    const {packages, loading, clientId} = props;

    const renderCheckmark = (column, row) => (row[column] && <IconCheckmark/>);

    const renderEventContentVariants = (column, row) => {
        return _map(row[column], (eventContentVariant) => eventContentVariant.name).join(', ');
    };

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id':
            case 'name':
                return (
                    <LinkWithAuthorization
                        resources={CLIENT_PACKAGE}
                        privileges={SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: 'clients.packages.edit',
                            params: {
                                id: clientId,
                                packagesId: row.id,
                            },
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                );
            case 'client_product': {
                return row.client_product.product.short_name;
            }
            case 'type': {
                return row.client_package_type.name;
            }
            case 'booking_type': {
                return row.booking_type.name;
            }
            case 'content_package_selection_type': {
                return row.content_package_selection_type.name;
            }
            case 'event_content_variants': {
                return renderEventContentVariants(column, row);
            }
            case 'client_package_geo_restrictions': {
                let geoRestrictions = 'Same as product settings';

                if (row.client_package_geo_restrictions.length > 0) {
                    geoRestrictions = 'Specific';
                }

                return geoRestrictions;
            }
            case 'is_hq':
            case 'is_test':
                return renderCheckmark(column, row);
            case 'actions': {
                return (
                    <Authorization
                        resources={CLIENT_PACKAGE}
                        privileges={SECURITY_PRIVILEGES_UPDATE}
                        link={{
                            name: 'clients.edit',
                            params: {id: row.id},
                        }}
                    >
                        <div className='actions-list-icon'>
                            <IconEdit size='large' link={
                                getLink('clients.packages.edit', {
                                    id: clientId,
                                    packagesId: row.id,
                                })
                            }/>
                        </div>
                    </Authorization>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className='packages__table'>
            <Table
                columns={columns}
                data={packages}
                loading={loading}
                name='packagesList'
                noDataText='No packages found'
                pagination={false}
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

PackagesTable.defaultProps = {
    packages: [],
};

PackagesTable.propTypes = {
    clientId: PropTypes.number,
    editClick: PropTypes.func,
    loading: PropTypes.bool,
    packages: PropTypes.array.isRequired,
};

export default PackagesTable;
