import {withRouter} from 'react-router';

import DefaultForm from '@appComponents/DefaultForm';

import { handleErrorCallback, setLinkToIndexPage } from '../utils/WnsStreamForm/formUtils';
import { prepareDataForSubmit } from '../utils/WnsStreamForm/prepareDataForSubmit';

export class WnsStreamForm extends DefaultForm {
    componentDidMount = () => {
        this.setCreateSuccessCallback(() => {
            setLinkToIndexPage(this);
        });
        this.setDeleteSuccessCallback(() => {
            setLinkToIndexPage(this);
        });
        this.setUpdateSuccessCallback(() => {
            setLinkToIndexPage(this);
        });

        this.setCreateErrorCallback((error) => {
            handleErrorCallback(error, this.props.Model.entityLabel);
        });

        this.setUpdateErrorCallback((error) => {
            handleErrorCallback(error, this.props.Model.entityLabel);
        });

        this.setDeleteErrorCallback((error) => {
            handleErrorCallback(error, this.props.Model.entityLabel);
        });
    };

    prepareDataForSubmit = (data) => (prepareDataForSubmit(data, this.props.Model));

    renderCancelButton = () => {
        return null;
    };

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );
}

export default withRouter(WnsStreamForm);
