import React from 'react';
import {Provider} from 'react-redux';

import {AvcmpRouterWithRedux as AvcmpRouter} from './modules/app/components/AvcmpRouter';
import configuredStore from './store';

export const App = () => (
    <div className='avcmp'>
        <Provider store={configuredStore}>
            <AvcmpRouter/>
        </Provider>
    </div>
);

export default App;
