import {Button, Header} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {get as _get} from 'lodash';
import React from 'react';

import * as MESSAGES from '@constants/messages';
import {hideModal} from '@utils/modal';

const getIsArchivedValue = (props) => (
    _get(props, `GraphQLEntityData.${props.Model.entityDataMapKey}.is_archived`, false)
);

getIsArchivedValue.propTypes = {
    Model: PropTypes.shape({
        entityDataMapKey: PropTypes.string.isRequired,
    }),
};

const toggleArchive = (props, mutation, mutationVariables) => {
    const isArchived = getIsArchivedValue(props),
        messageField = `${props.Model.label} "${mutationVariables.name}"`,
        IN_PROGRESS = isArchived ? MESSAGES.UNARCHIVE_PROGRESS(messageField) : MESSAGES.ARCHIVE_PROGRESS(messageField),
        SUCCESS = isArchived ? MESSAGES.UNARCHIVE_SUCCESS(messageField) : MESSAGES.ARCHIVE_SUCCESS(messageField),
        FAILURE = isArchived ? MESSAGES.UNARCHIVE_FAILED(messageField) : MESSAGES.ARCHIVE_FAILED(messageField);

    props.Modal.setModalConfirmation({
        text: IN_PROGRESS,
    });

    props.client.mutate({
        mutation: mutation,
        variables: mutationVariables,
    }).then(() => {
        hideModal();
        props.MessageBox.addMessage(props.messageBoxName, SUCCESS, null, 'success');

        if (props.callback) {
            props.callback();
        } else if (props.callbackLink) {
            props.history.push(props.callbackLink);
        }
    }).catch((error) => {
        if (401 !== error?.networkError?.statusCode) {
            hideModal();
            props.MessageBox.addMessage(props.messageBoxName, FAILURE, `${error}`, 'error');
        }
    });
};

toggleArchive.propTypes = {
    callbackLink: PropTypes.string.isRequired,
    client: PropTypes.shape({
        mutate: PropTypes.func.isRequired,
    }),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    MessageBox: PropTypes.shape({
        addMessage: PropTypes.func.isRequired,
    }),
    messageBoxName: PropTypes.string.isRequired,
    Model: PropTypes.shape({
        label: PropTypes.string.isRequired,
    }),
    Modal: PropTypes.shape({
        setModalConfirmation: PropTypes.func.isRequired,
        setModal: PropTypes.func.isRequired,
    }),
};

const toggleArchiveButtonClick = (event, data) => {
    event.preventDefault();

    const isArchived = getIsArchivedValue(data.props),
        messageField = data.props.Model.label,
        icon = isArchived ? 'folder open' : 'archive',
        header = isArchived
            ? MESSAGES.UNARCHIVE_HEADER(messageField)
            : MESSAGES.ARCHIVE_HEADER(messageField),
        text = isArchived
            ? MESSAGES.UNARCHIVE_CONFIRMATION(`${messageField} "${data.variables.name}"`)
            : MESSAGES.ARCHIVE_CONFIRMATION(`${messageField} "${data.variables.name}"`);

    data.props.Modal.setModalConfirmation({
        header: <Header content={header} icon={icon} />,
        onYes: () => (toggleArchive(
            data.props,
            data.mutation,
            {
                ...data.variables,
                is_archived: !isArchived,
            }
        )),
        text,
    });
};

export const renderArchivingButton = (props, mutation, mutationVariables) => {
    if (
        !_get(props, 'match.params.id', null)
        && !_get(props, 'formParams.id', null)
    ) {
        return null;
    }

    const isArchived = getIsArchivedValue(props),
        icon = isArchived ? 'folder open' : 'archive',
        content = isArchived ? 'Unarchive' : 'Archive';

    return (
        <Button
            color='orange'
            content={content}
            disabled={props.submitting}
            icon={icon}
            onClick={toggleArchiveButtonClick}
            props={props}
            mutation={mutation}
            variables={mutationVariables}
        />
    );
};

renderArchivingButton.propTypes = {
    submitting: PropTypes.bool.isRequired,
};
