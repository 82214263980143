import {get as _get, isEmpty as _isEmpty} from 'lodash';
import {withRouter} from 'react-router';

import {
    getInitialSavedEventContentVariants,
    getInitialSelectedEventContentVariants,
} from '@utils/eventContentVariantsTreeSelectedValues';
import DefaultForm from '@appComponents/DefaultForm';
import {getLink} from '@appComponents/Link';
import {
    EXCLUSIVITY_SELECTION_OTHERS,
    SUB_LICENSING_SELECTION_OTHERS,
    TERRITORY_SELECTION_TYPE_WHITELIST,
} from '@constants/variables';
import {convertToInt} from '@utils/helpers';
import {getInitialSavedUsageTypes, getInitialSelectedUsageTypes} from '@utils/usageTypesTreeSelectedValues';
import {generateUsageTypesTree, generateContentVariantsTree} from '@utils/usageTypesTreeStructureGenerator';

import {
    createUsageTreeOptions,
    prepareContentsOptions,
    selectionHasEventBookingSupport,
} from '../utils/formHelpers';
import {formatToSaveUsages} from '../utils/rightScopeForm/save/productUsagesSave';
import {formatToSaveRightScopeOptions} from '../utils/rightScopeForm/save/formatToSaveRightScopeOptions';
import {getCurrentSelectedUsageTypes} from '../utils/rightScopeForm/tree/selectedValues/usageTypesTree';
import {getCurrentSelectedUsages, getInitialSelectedUsages} from '../utils/rightScopeForm/tree/selectedValues/usagesTree';

export class RightsScopeForm extends DefaultForm {
    componentDidMount() {
        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink('propertyLicences.rightsScopes.editGeoRestrictions',
                {
                    id: this.props.match.params.id,
                    rightsScopeId: response.data.createRightsScope.id,
                }
            ));
        });
        this.setUpdateSuccessCallback((response) => {
            this.setDefaultDataForSubmit(_get(response, 'data.updateRightsScope.includes_all_content', true));
        });
        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink('propertyLicences.rightsScopes.index', {
                id: this.props.match.params.id,
            }));
        });

        this.setOnChangeCallback({
            includes_all_content: (data) => {
                const hideContents = !data.value;

                this.setField('contents', {
                    hidden: hideContents,
                    required: !hideContents,
                    defaultValue: this.state.selectedContents || [],
                });
            },
            contents: (event) => {
                this.setState(() => ({selectedContents: event.data}));
            },
            clip_provider: (data) => {
                this.setState(() => ({selectedClipProvider: data.value}));
            },
            usages: (event) => {
                let selectedClipProvider = this.state.selectedClipProvider || null;

                if (!this.state.prevSelectionHasEventBookingSupport) {
                    this.setState(() => (
                        {
                            prevSelectionHasEventBookingSupport: selectionHasEventBookingSupport(
                                _get(this.props, 'GraphQLOptionsData.products', {}),
                                getCurrentSelectedUsages(event.data)
                            ),
                        }
                    )
                    );
                }

                if (!this.state.usagesTree.length) {
                    return;
                }

                if (
                    this.state.prevSelectionHasEventBookingSupport &&
                    !selectionHasEventBookingSupport(
                        _get(this.props, 'GraphQLOptionsData.products', {}),
                        getCurrentSelectedUsages(event.data)
                    )
                ) {
                    selectedClipProvider = null;
                }

                if (0 === this.props.formValues.usages.length) {
                    selectedClipProvider = null;
                }

                this.setField('clip_provider', {
                    hidden: !selectionHasEventBookingSupport(
                        _get(this.props, 'GraphQLOptionsData.products', {}),
                        getCurrentSelectedUsages(event.data)
                    ),
                    defaultValue: selectedClipProvider,
                });

                if (null !== this.state.treeSelectedUsageTypes) {
                    const usageTypesTree = generateUsageTypesTree(
                        this.props.GraphQLOptionsData.products,
                        getCurrentSelectedUsages(event.data),
                        this.state.treeSelectedUsageTypes,
                        this.state.treeSavedRightsScopeUsageTypes
                    );

                    this.setField('usage_types', {options: usageTypesTree, hidden: _isEmpty(usageTypesTree)});
                    this.setState(() => ({usageTypesTree}));
                }

                if (null !== this.state.treeSelectedContentVariants) {
                    const contentVariantsTree = generateContentVariantsTree(
                        this.props.GraphQLOptionsData.products,
                        getCurrentSelectedUsages(event.data),
                        this.state.treeSelectedContentVariants,
                        this.state.treeSavedRightsScopeContentVariants
                    );

                    this.setField('content_variants', {options: contentVariantsTree, hidden: _isEmpty(contentVariantsTree)});
                    this.setState(() => ({contentVariantsTree}));
                }
            },
            usage_types: (event) => {
                if (!this.state.usageTypesTree.length) {
                    return;
                }

                if (null !== this.state.treeSelectedUsageTypes) {
                    this.setState(() => ({treeSelectedUsageTypes: getCurrentSelectedUsageTypes(event.data)}));
                }
            },
            content_variants: (event) => {
                if (!this.state.contentVariantsTree.length) {
                    return;
                }

                if (null !== this.state.treeSelectedContentVariants) {
                    this.setState(() => ({treeSelectedContentVariants: getCurrentSelectedUsageTypes(event.data)}));
                }
            },
            rights_scope_exclusivity: (data) => {
                this.setField('rights_scope_exclusivity_notes', {
                    hidden: convertToInt(data.value) !== EXCLUSIVITY_SELECTION_OTHERS,
                });
            },
            rights_scope_sub_licensing: (data) => {
                this.setField('rights_scope_sub_licensing_notes', {
                    hidden: convertToInt(data.value) !== SUB_LICENSING_SELECTION_OTHERS,
                });
            },
        });
    }

    prepareExistingRightsScope = (rightsScope) => {
        const contents = _get(rightsScope, 'contents', []);

        if (!this.state.contentsGenerated && !_isEmpty(contents)) {
            const selectedContents = contents.map((content) => convertToInt(content.id));

            this.setField('includes_all_content', {defaultValue: false});
            this.setField('contents', {hidden: false, defaultValue: selectedContents});
            this.setState(() => ({contentsGenerated: true, selectedContents}));
        }

        if (!this.state.exclusivityNotesGenerated && (EXCLUSIVITY_SELECTION_OTHERS === convertToInt(
            _get(rightsScope, 'rights_scope_exclusivity.id', null)))
        ) {
            this.setField('rights_scope_exclusivity_notes', {hidden: false});
            this.setState(() => ({exclusivityNotesGenerated: true}));
        }

        if (!this.state.subLicensingNotesGenerated && (SUB_LICENSING_SELECTION_OTHERS === convertToInt(
            _get(rightsScope, 'rights_scope_sub_licensing.id', null)))
        ) {
            this.setField('rights_scope_sub_licensing_notes', {hidden: false});
            this.setState(() => ({subLicensingNotesGenerated: true}));
        }

        if (!this.state.selectedClipProvider && !_isEmpty(rightsScope.clip_provider)) {
            this.setState(() => ({selectedClipProvider: rightsScope.clip_provider.id}));
        }
    };

    setDefaultDataForSubmit = (includesAllContent) => {
        if (includesAllContent) {
            this.setField('contents', {defaultValue: []});
            this.resetFieldToDefault('contents');
            this.setState(() => ({
                selectedContents: [],
                selectedClipProvider: null,
            }));
        }

        this.setState(() => ({
            treeSelectedUsageTypes: null,
            treeSavedRightsScopeUsageTypes: null,
            treeSelectedContentVariants: null,
            treeSavedRightsScopeContentVariants: null,
            usagesGenerated: false,
        }));
    };

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const {GraphQLOptionsData: {propertyLicenceContents, products}} = nextProps,
            rightsScope = _get(nextProps, 'GraphQLEntityData.rightsScope', {}),
            usages = _get(rightsScope, 'usages', []),
            usageTypes = _get(rightsScope, 'usage_types', []),
            contentVariants = _get(rightsScope, 'rights_scope_event_content_variants', []);

        if (!_isEmpty(rightsScope)) {
            this.prepareExistingRightsScope(rightsScope);
        }

        if (!this.state.contentsOptionsGenerated && !_isEmpty(propertyLicenceContents)) {
            this.setField('contents', {options: prepareContentsOptions(propertyLicenceContents)});
            this.setState(() => ({contentsOptionsGenerated: true}));
        }

        const initialSelectedUsages = getInitialSelectedUsages(usages);

        if (!this.state.usagesGenerated
            && (null === nextProps.formParams.id || (0 < nextProps.formParams.id && !_isEmpty(rightsScope)))
            && !_isEmpty(products)) {

            const usagesTree = createUsageTreeOptions(products, usages);
            this.setField('usages', {options: usagesTree});

            this.setState(() => ({
                usagesTree,
                usagesGenerated: true,
            }));

            if (_isEmpty(this.state.treeSelectedUsageTypes) && _isEmpty(this.state.treeSavedRightsScopeUsageTypes)) {
                const treeSelectedUsageTypes = getInitialSelectedUsageTypes(usageTypes),
                    treeSavedRightsScopeUsageTypes = getInitialSavedUsageTypes(usageTypes),
                    usageTypesTree = generateUsageTypesTree(
                        products,
                        initialSelectedUsages,
                        treeSelectedUsageTypes,
                        treeSavedRightsScopeUsageTypes
                    );

                this.setField('clip_provider', {
                    hidden: !selectionHasEventBookingSupport(
                        products,
                        initialSelectedUsages
                    ),
                });


                this.setField('usage_types', {options: usageTypesTree, hidden: _isEmpty(usageTypesTree)});
                this.setState(() => ({
                    usageTypesTree,
                    treeSelectedUsageTypes,
                    treeSavedRightsScopeUsageTypes,
                    usagesGenerated: true,
                }));
            }

            if (_isEmpty(this.state.treeSelectedContentVariants) && _isEmpty(this.state.treeSavedRightsScopeContentVariants)) {

                const treeSelectedContentVariants = getInitialSelectedEventContentVariants(contentVariants),
                    treeSavedRightsScopeContentVariants = getInitialSavedEventContentVariants(contentVariants),
                    contentVariantsTree = generateContentVariantsTree(
                        products,
                        initialSelectedUsages,
                        treeSelectedContentVariants,
                        treeSavedRightsScopeContentVariants
                    );

                this.setField('content_variants', {
                    options: contentVariantsTree,
                    hidden: _isEmpty(contentVariantsTree),
                });
                this.setState(() => ({
                    contentVariantsTree,
                    treeSelectedContentVariants,
                    treeSavedRightsScopeContentVariants,
                    usagesGenerated: true,
                }));
            }
        }
    }

    prepareDataForSubmit = (data) => {
        const result = Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data,
            data ? {
                property_licence: convertToInt(this.props.match.params.id),
                licensee: convertToInt(data.licensee),
                contents: data.includes_all_content ? [] : data.contents,
                clip_provider: data.clip_provider ? convertToInt(data.clip_provider) : null,
                usages: formatToSaveUsages(data.usages),
                usage_types: formatToSaveRightScopeOptions(data.usage_types),
                rights_scope_event_content_variants: formatToSaveRightScopeOptions(data.content_variants, false),
                rights_scope_exclusivity: parseInt(data.rights_scope_exclusivity),
                rights_scope_exclusivity_notes:
                    convertToInt(data.rights_scope_exclusivity) !== EXCLUSIVITY_SELECTION_OTHERS
                        ? ''
                        : data.rights_scope_exclusivity_notes || '',
                rights_scope_sub_licensing: parseInt(data.rights_scope_sub_licensing),
                rights_scope_sub_licensing_notes:
                    convertToInt(data.rights_scope_sub_licensing) !== SUB_LICENSING_SELECTION_OTHERS
                        ? ''
                        : data.rights_scope_sub_licensing_notes || '',
            } : null
        );

        if (data && !data.id) {
            result.territory_selection_type = TERRITORY_SELECTION_TYPE_WHITELIST;
        }

        return result;
    };

    renderErrors = (errorData) => {
        super.renderErrors(
            errorData,
            'Rights scope',
            getLink('propertyLicences.rightsScope.index', {
                id: this.props.match.params.id,
            }),
            {size: 'tiny'}
        );
    };

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );

    renderCancelButton = () => null;
}

export default withRouter(RightsScopeForm);
