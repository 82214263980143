import React from 'react';

import Authorization from '@appComponents/Authorization';
import { ButtonAdd } from '@appComponents/ButtonCollection';
import Link from '@appComponents/Link';
import { WNS_STREAM } from '@constants/resources';
import { SECURITY_PRIVILEGES_CREATE } from '@constants/variables';

const WnsStreamIndexButtons = () => (
    <div className='content__buttons'>
        <Authorization authorization={{[WNS_STREAM]: SECURITY_PRIVILEGES_CREATE}}>
            <Link name={'wnsStreams.add'}>
                <ButtonAdd>Add new World Number Service stream</ButtonAdd>
            </Link>
        </Authorization>
    </div>
);

export default WnsStreamIndexButtons;
