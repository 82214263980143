import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {reduxForm} from 'redux-form';
/* eslint import/no-unresolved: 0 */
import {connect} from 'react-redux';
import {compose} from 'redux';
import {
    get as _get,
    has as _has,
    includes as _includes,
    isEmpty as _isEmpty,
    isFunction as _isFunction,
} from 'lodash';

import {gql, graphql, withApollo} from 'react-apollo';
import {removeEntity, saveMessage, clearMessage, setModal, setModalConfirmation, storeEntity} from '@actions';
import {FORM_ERROR, WIZARD_STEP_VALIDATION_TEXT} from '@constants/messages';
import responseStatus from '@constants/responseStatuses';
import {hideModal, showModal, showModalConfirmation} from '@utils/modal';

/**
 * Helper for loading form in modal window.
 */
export const loadModalForm = ({url, form, setModal, onClose, size}) => {
    window.history.pushState({}, '', url);
    setModal({
        content: form,
        isVisible: true,
        onClose,
        size,
    });
};

/**
 * Helper for getting form GET parameters from URL.
 */
export const getUrlParam = (name, url) => {
    name = name.replace(/[\[]/,'\\\[').replace(/[\]]/,'\\\]');   // eslint-disable-line no-useless-escape
    const regexS = '[\\?&]'+name+'=([^&#]*)';
    const regex = new RegExp( regexS );
    const results = regex.exec( url );

    return null == results ? null : results[1];
};

/**
 * Helpers for setting active tab.
 */
export const isPathActive = (path, routeNode) => _includes(Object.values(routeNode).map(route => route.path), path);

export const setActivePath = (path, routeNodes) => {
    for (let i = 0; i < routeNodes.length; i++) {
        if (isPathActive(path, routeNodes[i])) {
            return routeNodes[i].index.path;
        }
    }

    return path;
};

/**
 * Helper for render "Not found" error modal content.
 */
export const renderModalError = (name, backLink, content = null, buttonText = null) => {
    const error = content ? 'Error' : `${name} not found`;

    return (
        <div>
            <Header icon='warning' color='red' content={error} />
            {content ? <Modal.Content>{content}</Modal.Content> : null}
            <Modal.Actions>
                <Link to={backLink}>
                    <Button className='button-back'>
                        <Icon className='arrow circle left'/> {buttonText ? buttonText : 'Back to list'}
                    </Button>
                </Link>
            </Modal.Actions>
        </div>
    );
};

/**
 * Helper for submitting the form.

 formUtils.onSubmit({
      dataToSave: object (required),
      actions: {
           create: function (required),
           update: function (required)
      },
      message: {
           box: object (required) - MessageBox reference,
           boxName: {
                success: string (required),
                error: string (required)
           },
           text: object (required) - with SAVED and NOT_SAVED constants,
           description: {
                success: string (optional),
                error: string (optional)
           },
           entityName: string (optional),
           entityLabel: string (required)
      },
      callback: {
           created: function (optional),
           updated: function (optional),
           notUpdated: function (optional),
           notCreated: function (optional)
      }
 );
 */
export const onSubmit = ({ dataToSave, actions, message, callback, apolloClient, props }) => {
    const addMode = !dataToSave.id,
        actionOptions = addMode ? _get(actions, 'create.options', {}) : _get(actions, 'update.options', {});
    let successText = message.text.SAVED(message.entityLabel, message.entityName);
    let errorText = message.text.NOT_SAVED(message.entityLabel, message.entityName);
    let action = addMode ? _get(actions, 'create.mutation', null) : _get(actions, 'update.mutation', null);

    if (!action) {
        action = addMode ? _get(actions, 'create', null) : _get(actions, 'update', null);
    }

    let messageGroup = addMode ? 'create' : 'update';

    if (_has(props, `Model.messages.${messageGroup}.error`, false)) {
        errorText = getText(messageGroup, 'error', props);
    }

    return (_isFunction(action) ? action({
        variables: { ...dataToSave },
        ...actionOptions,
    }) : apolloClient.mutate({
        mutation: ('string' === typeof action) ? gql`${action}` : action,
        variables: { ...dataToSave },
        ...actionOptions,
    })).then(( data ) => {
        let status = 'success',
            header = successText,
            description = _get(message, `description.${status}`);

        const getMessageStatus = _get(props, `Model.messages.${messageGroup}.getStatus`),
            getMessageHeader = _get(props, `Model.messages.${messageGroup}.getHeader`),
            getMessageDescription = _get(props, `Model.messages.${messageGroup}.getDescription`);

        if ('function' === typeof getMessageStatus) {
            status = getMessageStatus(data);
        }

        if (_has(props, `Model.messages.${messageGroup}.${status}`)) {
            header = getText(messageGroup, status, props, data);
        }

        if ('function' === typeof getMessageHeader) {
            header = getMessageHeader(data);
        }

        if ('function' === typeof getMessageDescription) {
            description = getMessageDescription(data);
        }

        message.box.addMessage(message.boxName.success, header, description, status, 'success' !== status);

        if (message.modal) {
            message.modal.setModal({
                isVisible: false,
            });
        }

        if (addMode && callback && callback.created) {
            callback.created(data);

            return;
        }

        if (!addMode && callback && callback.updated) {
            callback.updated(data);
        }
    }).catch((error) => {
        const description = message.description && message.description.error
            ? message.description.error
            : error.message;

        message.box.addMessage( message.boxName.error, errorText, description, 'error', true);

        if (addMode && callback && callback.notCreated) {
            callback.notCreated(error);
        }

        if (!addMode && callback && callback.notUpdated) {
            callback.notUpdated(error);
        }
    });
};

const getText = (group, key, props = {}, data = {}) => {
    let TextObject = _get(props.Model, `messages.${group}`, {});

    return 'function' === typeof(TextObject[key])
        ? TextObject[key](props, data)
        : TextObject[key];
};

/**
 * Helper for deleting the entity.

 formUtils.onSubmit({
      id: integer (required),
      action: function (required),
      modal: object (required) - Modal reference,
      message: {
           box: object (required) - MessageBox reference,
           boxName: {
                success: string (required),
                error: string (required)
           },
           text: object (required) - with DELETED, NOT_DELETED and DELETE_IN_PROGRESS constants,
           description: {
                success: string (optional),
                error: string (optional)
           },
           entityName: string (optional),
           entityLabel: string (required)
      },
      callback: {
           deleted: function (optional),
           notDeleted: function (optional)
      }
 );
 */
export const onDelete = ({ action, id, message, callback, props }) => {
    let successText = message.text.DELETED(message.entityLabel, message.entityName),
        errorText = message.text.NOT_DELETED(message.entityLabel, message.entityName),
        inProgressText = message.text.DELETE_IN_PROGRESS(message.entityLabel, message.entityName),
        actionOptions = _get(action, 'delete.options', {});

    if (_has(props, 'Model.messages.delete.success', false)) {
        successText = getText('delete', 'success', props);
    }

    if (_has(props, 'Model.messages.delete.error', false)) {
        errorText = getText('delete', 'error', props);
    }

    if (_has(props, 'Model.messages.delete.progress', false)) {
        inProgressText = getText('delete', 'progress', props);
    }
    showModalConfirmation({
        text: inProgressText,
        inProgress: true,
        loading: true,
    });

    let messageBox = message.boxName.success;

    if (message.boxName.delete) {
        messageBox = message.boxName.delete;
    }


    let deleteAction = _get(action, 'delete.mutation', action);

    return deleteAction({
        variables: { id },
        refetchQueries: _get(actionOptions, 'refetchQueries', []),
    }).then(( data ) => {
        const description = message.description && message.description.success
            ? message.description.success
            : null;

        message.box.addMessage( messageBox, successText, description, 'success' );
        hideModal();

        if (callback && callback.deleted) {
            callback.deleted(data);
        }
    }).catch((error) => {
        if (error?.networkError?.statusCode === responseStatus.HTTP_UNAUTHORIZED) {
            showModal({
                isVisible: true, hideButtons: false,
            });

            return;
        }
        const description = message.description && message.description.error
            ? message.description.error
            : error.toString();

        message.box.addMessage(  message.boxName.error, errorText, description, 'error');
        hideModal();

        if (callback && callback.notDeleted) {
            callback.notDeleted(error);
        }
    });
};

/** *
 * Helper for creating the form component.
 *
 * Usage:
 * create(Model, Form);
 * create(Model, Form, {id: props.match.params.id, dataRequest: false);
 *
 * By default
 * id: null
 * dataRequest: true
 * */
export const createForm = (Model, Form, params = {}) => {
    Model = Model(params);

    let optionsFetchPolicy = _get(params, 'optionsFetchPolicy', 'network-only'),
        entityFetchPolicy = _get(params, 'entityFetchPolicy', 'network-only');

    const localParams = Object.assign(
        {
            id: null,
            dataRequest: true,
            optionsVariables: {},
        },
        params
    );

    const setupMutation = (action) => {
        return (Model.mutation[action])
            ? ('string' === typeof Model.mutation[action])
                ? gql`${Model.mutation[action]}`
                : Model.mutation[action]
            : null;
    };

    const Mutations = {
        UpdateEntity: Model.mutation.updateEntity ?
            graphql(setupMutation('updateEntity') , { name: 'UpdateEntity' }) : (data) => (data),
        CreateEntity: Model.mutation.createEntity && !_isFunction(Model.mutation.createEntity) ?
            graphql(setupMutation('createEntity'), { name: 'CreateEntity' }) : (data) => (data),
        DeleteEntity: Model.mutation.deleteEntity ?
            graphql(setupMutation('deleteEntity'), { name: 'DeleteEntity' }) : (data) => (data),
    };
    const grahpQlMutations = compose(
            Mutations.UpdateEntity,
            Mutations.CreateEntity,
            Mutations.DeleteEntity
        ),
        WrappedForm = withApollo(reduxForm({
            form: Model.formName,
            validate: Model.validate,
        })(Form)),
        mapDispatchToProps = (dispatch) => {
            return {
                MessageBox: {
                    addMessage: (name, header, text, type, stayVisible = false, addClosedTimestamp = false) => {
                        dispatch(saveMessage({name, header, text, type, stayVisible, addClosedTimestamp}));
                    },
                    removeMessage: (name) => {
                        dispatch(clearMessage({name: name }));
                    },
                },
                Entity: {
                    setEntity: (entity) => {
                        dispatch(storeEntity(entity));
                    },
                    removeEntity: (name) => {
                        dispatch(removeEntity(name));
                    },
                },
                Modal: {
                    setModal: (params) => {
                        dispatch(setModal(params));
                    },
                    setModalConfirmation: (params) => {
                        dispatch(setModalConfirmation(params));
                    },
                },
                Model,
            };
        },
        mapStateToProps = (state) => {
            let formValues = {};

            if (state.form[Model.formName] && state.form[Model.formName].values) {
                formValues = {...state.form[Model.formName].values};
            }

            return {
                formValues: formValues,
                formParams: localParams,
                user: state.app.security.user,
            };
        };

    let EntityQuery = (data) => (data);

    if (!localParams.id) {
        if (Model.query.getOptions) {
            const optionsQueryOptions = {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: optionsFetchPolicy,
                variables: localParams.optionsVariables,
            };
            const GetOptionsQuery = ('string' === typeof Model.query.getOptions)
                ? gql`${Model.query.getOptions}`
                : Model.query.getOptions;

            return compose(
                graphql(GetOptionsQuery, { options: () => (optionsQueryOptions), name: 'GraphQLOptionsData' }),
                grahpQlMutations
            )(connect(mapStateToProps, mapDispatchToProps)(WrappedForm));
        }

        return grahpQlMutations(connect(mapStateToProps, mapDispatchToProps)(WrappedForm));

    } else {
        if (localParams.dataRequest) {
            const entityQueryOptions = {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: entityFetchPolicy,
                variables: {id: localParams.id},
            };
            const GetEntityQuery = ('string' === typeof Model.query.getEntity)
                ? gql`${Model.query.getEntity}`
                : Model.query.getEntity;

            EntityQuery = compose(
                graphql(GetEntityQuery, { options: () => (entityQueryOptions), name: 'GraphQLEntityData' })
            );
        }

        if (Model.query.getOptions) {
            const optionsQueryOptions = {
                    notifyOnNetworkStatusChange: true,
                    fetchPolicy: optionsFetchPolicy,
                    variables: localParams.optionsVariables,
                },
                GetOptionsQuery = ('string' === typeof Model.query.getOptions)
                    ? gql`${Model.query.getOptions}`
                    : Model.query.getOptions;

            return compose(
                graphql(GetOptionsQuery, { options: () => (optionsQueryOptions), name: 'GraphQLOptionsData' }),
                EntityQuery,
                grahpQlMutations
            )(connect(mapStateToProps, mapDispatchToProps)(WrappedForm));
        }

        return compose(
            EntityQuery,
            grahpQlMutations
        )(connect(mapStateToProps, mapDispatchToProps)(WrappedForm));
    }
};
createForm.propTypes = {
    Model: PropTypes.func.isRequired,
};

export const closeModal = ({history, modal, url}) => {
    modal.setModal({
        content: null,
        isVisible : false,
    });

    if (url && history) {
        history.push(url);
    }
};

export const getFormMessageForWizard = (message, model) => {
    if (_isEmpty(model)) {
        return null;
    }

    let messageText = WIZARD_STEP_VALIDATION_TEXT;

    if (true === model.editForm) {
        messageText = FORM_ERROR(model.entityLabel);

        if ('tabs' === message.substr(message.length - 5, 4)) {
            messageText = message;
        }
    }

    return messageText;
};

