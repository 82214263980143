import moment from 'moment';
import {isEmpty as _isEmpty} from 'lodash';

import validators from '../../../utils/validators';

const validateCoverageAndClipProvider = (values, errors) => {
    if (!_isEmpty(values.coverage)) {
        Object.assign(errors, validators.required(values.clip_provider, 'clip_provider'));
    }
};

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.property_licence, 'property_licence'));
    Object.assign(errors, validators.required(values.content_category, 'content_category'));

    Object.assign(errors, validators.dateTime(values.start_datetime, 'start_datetime'));

    if (values.end_datetime && moment(values.start_datetime).isAfter(values.end_datetime)) {
        Object.assign(errors, {end_datetime: "End date must be later than the Start date"});
    }

    Object.assign(errors, validators.required(values.event_status, 'event_status'));

    Object.assign(errors, validators.maxLength({value: values.description, maxLength: 200}, 'description'));
    Object.assign(errors, validators.maxLength({value: values.notes, maxLength: 200}, 'notes'));

    if (values.preset_save) {
        Object.assign(errors, validators.required(values.preset_name, 'preset_name'));
    }

    validateCoverageAndClipProvider(values, errors);

    return errors;
};

export const validateForEventMultiple = (values) => {
    const errors ={};

    Object.assign(errors, validators.required(values.property_licence, 'property_licence'));
    Object.assign(errors, validators.required(values.event_status, 'event_status'));

    return errors;
};

export const validateForEventMultiplePresetApply = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.event_preset, 'event_preset'));

    if (0 < values.coverage?.length) {
        Object.assign(errors, validators.required(values.clip_provider, 'clip_provider'));
    }

    return errors;
};

export const validateForExistingEvent = (values) => {
    const errors = validate(values);

    delete errors.content_category;

    validateCoverageAndClipProvider(values, errors);

    return errors;
};

export const validateForNonExistingEvent = (values) => {
    const errors = validateForExistingEvent(values);

    Object.assign(errors, validators.required(values.tournament_id, 'tournament_id'));

    validateCoverageAndClipProvider(values, errors);

    return errors;
};

export default validate;
