import React from 'react';
import PropTypes from 'prop-types';
import {includes as _includes} from 'lodash';
import {withRouter} from 'react-router';

import {routes} from '@constants/routes';
import {convertToInt} from '@utils/helpers';
import ClientVideoPlayerIndex from '@modules/client/views/ClientVideoPlayerIndex';
import ClientVplLicenceKeyLayout from '@modules/client/layouts/ClientVplLicenceKeyLayout';

const ClientVideoPlayerTab = ({match}) => {
    const clientId = convertToInt(match.params.id);

    const getTabRendererState = () => {
        const {add, edit} = routes.clients.clientVideoPlayer;
        const renderClientVideoPlayerLayout = _includes([add.path, edit.path], match.path);

        return (renderClientVideoPlayerLayout)
            ? <ClientVplLicenceKeyLayout
            />
            : <ClientVideoPlayerIndex
                clientId={clientId}
            />;
    };

    return getTabRendererState();
};

ClientVideoPlayerTab.propTypes = {
    match: PropTypes.object.isRequired,
    clientVplLicenceKeys: PropTypes.array,
};
export default withRouter(ClientVideoPlayerTab);
