import {has as _has} from 'lodash';
import React from 'react';
import {Header} from 'semantic-ui-react';

import {IconBan} from '@appComponents/IconCollection';
import {DisableBlacklistedIp} from '@graphql/blacklistedIp/mutation';
import {DisableClientWhitelistedIp} from '@graphql/clientWhitelistedIp/mutation';
import responseStatus from '@constants/responseStatuses';

import apolloClient from '../apolloClient';
import {convertToInt} from './helpers';
import {showMessageBox} from './messageBox';
import {hideModal, showModal, showModalConfirmation} from './modal';

export const loadModalForm = (Form, data, setModalVisibility) => {
    showModal({
        content: <Form formData={data} />,
        header: null,
        isVisible: true,
        size: 'small',
    });

    setModalVisibility(true);
};

export const openDisableModal = (cidrData, indexUrl, history, setMutationSuccessCallback) => {
    const settings = {
        messageBoxName: 'whitelistedIpMessage',
        mutationQuery: DisableClientWhitelistedIp,
        type: 'whitelist',
    };

    if (!_has(cidrData, 'products')) {
        settings.messageBoxName = 'ipBlacklistingIndex';
        settings.mutationQuery = DisableBlacklistedIp;
        settings.type = 'blacklist';
    }

    showModalConfirmation({
        header: (
            <Header>
                <IconBan flipped='vertically'/>
                <Header.Content>Disable CIDR</Header.Content>
            </Header>
        ),
        onYes: () => (disableCidr(
            cidrData,
            indexUrl,
            settings.messageBoxName,
            settings.mutationQuery,
            history,
            setMutationSuccessCallback
        )),
        text: (
            <>
                Are you sure you want to disable this CIDR?<br/>
                This cannot be reverted.<br/>
                {`If you want to ${settings.type} this CIDR again, it will have to be added as a new entry.`}
            </>
        ),
    });
};

const disableCidr = (cidrData, indexUrl, messageBoxName, mutationQuery, history, setMutationSuccessCallback) => {
    const message = `CIDR "${cidrData.cidr}"`;

    showModalConfirmation({text: `Disabling the ${message}...`});

    return apolloClient.mutate({
        mutation: mutationQuery,
        variables: {
            id: convertToInt(cidrData.id),
            is_disabled: true,
        },
    }).then(() => {
        hideModal();
        showMessageBox(
            messageBoxName,
            `The ${message} has been disabled successfully.`,
            null,
            'success'
        );
        setMutationSuccessCallback(Date.now());
        history.push(indexUrl);
    }).catch((error) => {
        if (error?.networkError?.statusCode !== responseStatus.HTTP_UNAUTHORIZED) {
            hideModal();
            showMessageBox(
                'formInnerErrorMessage',
                `The ${message} could not be disabled.`,
                `${error}`,
                'error'
            );
        }
    });
};
