import React from 'react';

import { Icon, IconEdit } from '@appComponents/IconCollection';
import { getLink } from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import { WNS_STREAM } from '@constants/resources';
import { convertToInt } from '@utils/helpers';

import { authorizationObject } from './authorizationObject';

const wnsStreamTableRenderer = (column, row) => {
    const numId = convertToInt(row?.id);

    if (!numId) {
        throw new TypeError('ID is required and must be a number greater than zero');
    }

    switch (column) {
        case 'id': {
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{name: 'wnsStreams.edit', params: {id: numId}}}
                >{numId}
                </LinkWithAuthorization>
            );
        }
        case 'name': {
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{name: 'wnsStreams.edit', params: {id: numId}}}
                >{row.name}
                </LinkWithAuthorization>
            );
        }
        case 'stream_name': {
            return row.stream_name;
        }
        case 'actions': {
            return (
                <div className='actions-list-icon'>
                    <IconEdit size='large'
                        resource={WNS_STREAM}
                        link={getLink('wnsStreams.edit', {id: numId})}
                    />
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{name: 'wnsStreams.play', params: {id: numId}}}>
                        <Icon className={'large play circle outline'}/>
                    </LinkWithAuthorization>
                </div>
            );
        }
    }
};

export default wnsStreamTableRenderer;
