import {merge as _merge, get as _get, isEmpty as _isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Button, Checkbox, Header, Modal, Segment} from 'semantic-ui-react';

import {refetchQueryByName} from '@utils/apollo';
import {compose, graphql} from 'react-apollo';
import {toggleStreamPlaybackDebugMode} from '@actions';
import {convertToInt} from '@utils/helpers';
import {
    DISTRIBUTION_TYPE_RECORDING,
    PRODUCT_SPOTT,
} from '@constants/variables';
import {GetEventContentDetailsForStreamsOutput} from '@graphql/eventContent/query';
import {EventContentStreamsPlaybackQuery} from '@graphql/streams/query';

import StreamsPlaybackTable from './StreamsPlaybackTable';
import {HeaderEventContent} from '../views/StreamsPlayback/HeaderEventContent';
import {OriginStream} from '../views/StreamsPlayback/OriginStream';

export class StreamsPlayback extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func,
        DataStreams: PropTypes.object,
        eventId: PropTypes.number.isRequired,
        eventContentId: PropTypes.number.isRequired,
        EventContentDetails: PropTypes.shape({
            eventContentDetailsForStreamsOutput: PropTypes.object,
        }),
        modal: PropTypes.object.isRequired,
        newWindow: PropTypes.bool,
        streamPlaybackDebugMode: PropTypes.bool.isRequired,
        toggleStreamPlaybackDebugMode: PropTypes.func.isRequired,
    };

    static defaultProps = {
        EventContentDetails: {
            eventContentDetailsForStreamsOutput: {},
        },
        newWindow: true,
    };

    state = {
        streams: [],
    };

    componentDidMount() {
        refetchQueryByName('GetEventContentDetailsForStreamsOutput');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.DataStreams.loading !== this.props.DataStreams.loading) {
            this.props.modal.setModal({
                loading: this.props.DataStreams.loading,
            });
        }

        if ((this.props.DataStreams.streams && this.props.DataStreams.streams !== prevProps.DataStreams.streams)
            || (!_isEmpty(this.props.DataStreams.streams) && _isEmpty(this.state.streams)
                && this.props.DataStreams.streams === prevProps.DataStreams.streams)
        ) {
            this.setState(() => ({streams: this.groupStreamsByBitrate(this.props.DataStreams.streams)}));
        }
    }

    getEncodingTargetData = (encodingTarget, format, protocol, distrRegion, bitrate, cdnClients, ejpClients) => {
        const distrRegionId = distrRegion ? distrRegion.id : 0;
        const distrRegionName = distrRegion ? distrRegion.name : '-';

        return {
            id: encodingTarget.id,
            isDefault: encodingTarget.is_default,
            isComputerVision: encodingTarget.is_computer_vision,
            cdn: {
                name: encodingTarget.cdn ? encodingTarget.cdn.name : '-',
                clients: cdnClients,
            },
            akamai_cdn_ingest_method: {
                id: encodingTarget.akamai_cdn_ingest_method && encodingTarget.akamai_cdn_ingest_method.id,
                name: encodingTarget.akamai_cdn_ingest_method && encodingTarget.akamai_cdn_ingest_method.name,
            },
            ingest_method: {
                id: encodingTarget.ingest_method && encodingTarget.ingest_method.id,
                name: encodingTarget.ingest_method && encodingTarget.ingest_method.name,
            },
            encoder_type: {
                name: encodingTarget.encoder_type && encodingTarget.encoder_type.name,
            },
            encoding_target_sr: {
                name: encodingTarget.encoding_target_sr
                    ? encodingTarget.encoding_target_sr.encoding_job_profile.name
                    : '-',
            },
            job_profile: {
                clients: ejpClients,
            },
            formats: {
                [format.id]: {
                    format: format.name,
                    protocols: {
                        [protocol.id]: {
                            protocol: protocol.name,
                            distr_regions: {
                                [distrRegionId]: {
                                    name: distrRegionName,
                                    manifest: {
                                        encodingTargetId: encodingTarget.id,
                                        streamProtocolId: protocol.id,
                                        akamaiRtmpDistrRegionId: distrRegionId,
                                    },
                                    'bitrates': [
                                        bitrate,
                                    ],
                                },
                            },
                        },
                    },
                },
            },
        };
    }

    groupStreamsByBitrate = (streams) => {
        const groupedStreams = {};

        streams = streams.filter(stream => {
            const productId = convertToInt(stream.target.distribution.product.id),
                distributionTypeId = convertToInt(stream.target.distribution.distribution_type.id);

            return !(PRODUCT_SPOTT === productId && DISTRIBUTION_TYPE_RECORDING === distributionTypeId );
        });

        streams.forEach(stream => {
            const product = stream.target.distribution.product,
                distributionType = stream.target.distribution.distribution_type,
                streamDelayType = stream.target.stream_delay_type,
                distrRegion = stream.akamai_rtmp_distr_region,
                format = stream.format,
                protocol = stream.protocol,
                encodingTarget = stream.target,
                clientCdnConfs = _get(encodingTarget, 'client_cdn_configurations', []),
                cdnClients = clientCdnConfs && clientCdnConfs.map((conf) => (conf.client_product.client)),
                ejpClients = _get(encodingTarget, 'encoding_target_sr.encoding_job_profile.clients', []);
            const streamDelayTypeId = streamDelayType ? streamDelayType.id : 0;
            const streamDelayTypeName = streamDelayType ? streamDelayType.name : '-';
            const distrRegionId = distrRegion ? distrRegion.id : 0;
            const rate = (stream.bitrate_audio + stream.bitrate_video) / 1000;
            const bitrate = {streamId: stream.id, rate: `${rate} kbps`};

            const currentProductStream = _get(
                groupedStreams,
                `${product.id}` +
                '.distribution_types' +
                `.${distributionType.id}` +
                `.stream_delay_types.${streamDelayTypeId}` +
                `.encoding_targets.${encodingTarget.id}` +
                `.formats.${format.id}` +
                `.protocols.${protocol.id}` +
                `.distr_regions.${distrRegionId}`,
                false
            );

            const currentEncodingTarget = _get(
                groupedStreams,
                `${product.id}` +
                '.distribution_types' +
                `.${distributionType.id}` +
                '.stream_delay_types' +
                `.${streamDelayTypeId}` +
                `.encoding_targets${encodingTarget.id}`,
                false
            );

            if (currentProductStream) {
                currentProductStream.bitrates.push(bitrate);
            } else if (currentEncodingTarget === encodingTarget.id) {
                currentEncodingTarget.push(this.getEncodingTargetData(
                    encodingTarget,
                    format,
                    protocol,
                    distrRegion,
                    bitrate,
                    cdnClients,
                    ejpClients
                ));
            } else {
                const newProductStream = {
                    [product.id]: {
                        id: product.id,
                        key: product.id,
                        product: product.short_name,
                        distribution_types: {
                            [distributionType.id]: {
                                distribution_type: distributionType.name,
                                stream_delay_types: {
                                    [streamDelayTypeId]: {
                                        stream_delay_type: streamDelayTypeName,
                                        encoding_targets: {
                                            [encodingTarget.id]: this.getEncodingTargetData(
                                                encodingTarget,
                                                format,
                                                protocol,
                                                distrRegion,
                                                bitrate,
                                                cdnClients,
                                                ejpClients
                                            ),
                                        },
                                    },
                                },
                            },
                        },
                    },
                };

                _merge(groupedStreams, newProductStream);
            }
        });

        return groupedStreams ? Object.keys(groupedStreams).map((k) => groupedStreams[k]) : [];
    };

    toggleDebugMode = () => {
        this.props.toggleStreamPlaybackDebugMode();
    };

    render() {
        const eventContentDetails = (this.props.EventContentDetails.eventContentDetailsForStreamsOutput) ?
            this.props.EventContentDetails.eventContentDetailsForStreamsOutput : {};

        return (
            <Segment basic className='--noPadding'>
                <Header content='Stream playback' />
                <HeaderEventContent eventContentDetails={eventContentDetails} />
                <div className='--padding-2rem'>
                    <div className='originStreamAndDebugModeWrapper'>
                        <OriginStream
                            debugMode={this.props.streamPlaybackDebugMode}
                            eventContentDetails={eventContentDetails}
                            newWindow={this.props.newWindow}
                        />
                        <div className='originStreamAndDebugModeWrapper__div--right-aligned-toggle'>
                            <label id='debugSwitchLabel'>Debug mode</label>
                            <Checkbox
                                defaultChecked={this.props.streamPlaybackDebugMode}
                                onChange={this.toggleDebugMode}
                                toggle
                            />
                        </div>
                    </div>
                    <StreamsPlaybackTable
                        eventId={this.props.eventId}
                        eventContentId={this.props.eventContentId}
                        debugMode={this.props.streamPlaybackDebugMode}
                        loading={this.props.DataStreams.loading}
                        newWindow={this.props.newWindow}
                        streams={this.state.streams}
                    />
                </div>
                <Modal.Actions
                    className='streamPlaybackActionsSection'
                >
                    <Button
                        content='Close'
                        onClick={this.props.closeModal}
                    />
                </Modal.Actions>
            </Segment>
        );
    }
}

const mapStateToProps = (state) => ({
    streamPlaybackDebugMode: state.app.streamPlayback.debugMode,
});

const mapDispatchToProps = (dispatch) => ({
    toggleStreamPlaybackDebugMode: () => {dispatch(toggleStreamPlaybackDebugMode());},
});

const StreamsPlaybackWithGraphQL = compose(
    graphql(GetEventContentDetailsForStreamsOutput, {
        options: (props) => {
            return {
                variables: {
                    eventContentId: props.eventContentId,
                },
            };
        },
        name: 'EventContentDetails',
    }),
    graphql(EventContentStreamsPlaybackQuery, {
        options: (props) => {
            return {
                fetchPolicy: 'cache-and-network',
                variables: {
                    eventContents: [props.eventContentId],
                },
            };
        },
        name: 'DataStreams',
    })
)(connect(mapStateToProps, mapDispatchToProps)(StreamsPlayback));

export default StreamsPlaybackWithGraphQL;
