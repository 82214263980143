import React from 'react';
import {Header, Modal as SemanticModal} from 'semantic-ui-react';

import * as actions from '@actions/index';

import configuredStore from '../../../store';

const SessionEnd = () => {
    const logInAgain = () => {
        configuredStore.dispatch(actions.authenticationFailure());
    };

    return (
        <div>
            <Header icon='warning' color='red' content={'Session ended'}/>
            <SemanticModal.Content>
                <p>Your session has ended. Please click <a style={{cursor: 'pointer'}} onClick={logInAgain}>here</a> to login again.</p>
            </SemanticModal.Content>
        </div>
    );
};

export default SessionEnd;
