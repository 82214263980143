import {withApollo} from 'react-apollo';
import {
    getCommonProductsWithBookingSupport,
    getRightScopesWithMediaRightsForMatches,
} from '@modules/events/views/common/EventsMultipleActions';
import {prefillCoverage, transformProductsForCoverageDropdown} from '@modules/events/utils/formHelper';
import {refetchQueryByName} from '@utils/apollo';

import EventForm from './EventForm';

export class EventsMultipleCreationForm extends EventForm {
    componentDidMount() {
        this.setCreateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false});
        });

        this.setCreateSuccessCallback(() => {
            refetchQueryByName(this.props.formParams.queryForRefetch);
        });

        this.setOnChangeCallback({
            property_licence: (data) => {
                this.setField('coverage', {
                    defaultValue: [],
                    timestamp: Date.now(),
                });

                this.getPresetsForPropertyLicence(data.value);
                this.prepareCoverageField(this.props.formParams.matches, data.value);
            },
        });

        this.setField('property_licence', {
            options: this.props.formParams.propertyLicencesOptions,
        });

        this.setField('event_status', {
            options: this.props.formParams.eventStatuses,
        });
    }

    componentDidUpdate() {
        prefillCoverage(
            this.props.Model.fields.coverage.props.hidden,
            this.state.presetProducts,
            this.props.Model,
            this.setField,
            this.state,
            (newState) => this.setState(newState)
        );
    }

    prepareDataForSubmit(data) {
        let dataForSubmit = super.prepareDataForSubmit(data).EventMultipleCreation;

        dataForSubmit = Object.assign(dataForSubmit, {
            color: data.color.substring(1),
            matches: this.props.formParams.matches,
            products: data.coverage,
        });

        return dataForSubmit;
    }

    prepareCoverageField(matchIds, propertyLicenceId) {
        getRightScopesWithMediaRightsForMatches(matchIds, propertyLicenceId)
            .then(data => {
                const coverageProducts = getCommonProductsWithBookingSupport(data.rightsScopesForMatches);

                this.setField('coverage', {
                    options: transformProductsForCoverageDropdown(coverageProducts),
                    hidden: !coverageProducts.length,
                });
            });
    }
}

export default withApollo(EventsMultipleCreationForm);
