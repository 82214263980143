import React from 'react';
import PropTypes from 'prop-types';
/* eslint import/no-unresolved: 0 */
import {compose} from 'redux';
import {withRouter} from 'react-router';
import {Header, Button} from 'semantic-ui-react';
import {Field, reduxForm} from 'redux-form';
import {get as _get} from 'lodash';

import {ButtonCancel} from '@appComponents/ButtonCollection';
import {FormWrapper} from '@appComponents/HOCFormWrapper';
import Form from '@appComponents/ReduxFormControls';
import {hasPrivileges} from '@appComponents/Authorization';
import {graphql} from 'react-apollo';
import {getLink} from '@appComponents/Link';
import * as formUtils from '@utils/forms';
import {convertToInt} from '@utils/helpers';
import * as MESSAGES from '@constants/messages';
import {CONTACT_PERSON} from '@constants/resources';
import {
    SECURITY_PRIVILEGES_CREATE,
    SECURITY_PRIVILEGES_DELETE,
    SECURITY_PRIVILEGES_UPDATE,
} from '@constants/variables';
import {CreateContactPerson, DeleteContactPerson, UpdateContactPerson} from '@graphql/contactPerson/mutation';

import validate from './validatorContactPerson';

class ContactPersonForm extends React.Component {
    static propTypes = {
        CreateContactPerson: PropTypes.func,
        dataForForm: PropTypes.object,
        dataMap: PropTypes.object,
        DeleteContactPerson: PropTypes.func,
        formData: PropTypes.object,
        handleSubmit: PropTypes.func,
        history: PropTypes.object,
        MessageBox: PropTypes.object,
        Modal: PropTypes.object,
        parent: PropTypes.object,
        personType: PropTypes.number,
        submitting: PropTypes.bool,
        UpdateContactPerson: PropTypes.func,
    };

    authorized = false;
    authorizedObject = {
        'createContactPerson': {
            [CONTACT_PERSON]: SECURITY_PRIVILEGES_CREATE,
        },
        'deleteContactPerson': {
            [CONTACT_PERSON]: SECURITY_PRIVILEGES_DELETE,
        },
        'updateContactPerson': {
            [CONTACT_PERSON]: SECURITY_PRIVILEGES_UPDATE,
        },
    };

    AuthorizedField = (props) => (
        <Field {...props} />
    );

    constructor(props) {
        super(props);

        const contactPersonId = _get(props, 'formData.variables.id');

        if(
            (hasPrivileges(this.authorizedObject.createContactPerson)
            && !contactPersonId
            )
            || (hasPrivileges(this.authorizedObject.updateContactPerson)
                && contactPersonId
            )
        )
        {
            this.authorized = true;
        }

        if (!this.authorized) {
            // eslint-disable-next-line react/display-name
            this.AuthorizedField = (props) => (
                <Field {...props} disabled={true} readonly={true}/>
            );
        }

        this.deleteButtonClick = this.deleteButtonClick.bind(this);
        this.deleteContactPerson = this.deleteContactPerson.bind(this);
    }

    getQueryNameForRefetch = () => {
        switch (this.props.parent.name) {
            case 'licensors':
                return ['GetLicensorContactPersonsForList'];
            case 'licensees':
                return ['GetLicenseeContactPersonsForList'];
            default:
                return [];
        }
    };

    onFormSubmit(data) {
        const dataToSave = Object.assign(
            {
                contact_person_type_id: convertToInt(this.props.personType),
                licensor_id: null,
                licensee_id: null,
                client_id: null,
                production_company_id: null,
            },
            this.props.dataMap.contactPerson,
            data
        );

        switch (this.props.parent.name) {
            case 'licensors':
                dataToSave.licensor_id = convertToInt(this.props.parent.id);
                break;
            case 'licensees':
                dataToSave.licensee_id = convertToInt(this.props.parent.id);
                break;
        }

        const redirect = () => {
            this.props.history.push(getLink(`${this.props.parent.name}.contactPerson.index`, {
                id: this.props.parent.id,
            }));
        };

        return formUtils.onSubmit({
            dataToSave,
            actions: {
                create: {
                    mutation: this.props.CreateContactPerson,
                    options: {
                        refetchQueries: this.getQueryNameForRefetch(),
                    },
                },
                update: {
                    mutation: this.props.UpdateContactPerson,
                    options: {
                        refetchQueries: this.getQueryNameForRefetch(),
                    },
                },
            },
            message: {
                box: this.props.MessageBox,
                boxName: { success: 'contactPerson', error: 'contactPerson' },
                text: MESSAGES,
                entityLabel: 'contact person',
                entityName: dataToSave.name,
            },
            callback: {
                created: redirect,
                updated: redirect,
                notCreated: redirect,
                notUpdated: redirect,
            },
        });
    }

    deleteContactPerson() {
        const redirect = () => {
            this.props.history.push(getLink(`/${this.props.parent.name}/${this.props.parent.id}/contact-persons`));
        };

        return formUtils.onDelete({
            id: parseInt(this.props.formData.contactPerson.id, 10),
            action: {
                delete: {
                    options: {
                        refetchQueries: this.getQueryNameForRefetch(),
                    },
                    mutation: this.props.DeleteContactPerson,
                },
            },
            message: {
                box: this.props.MessageBox,
                boxName: { success: 'contactPerson', error: 'contactPerson' },
                text: MESSAGES,
                entityLabel: 'contact person',
                entityName: this.props.dataForForm.contactPerson.name,
            },
            callback: {
                deleted: redirect,
                notDeleted: redirect,
            },
        });
    }

    componentWillUnmount() {
        if ('licensors' === this.props.parent.name) {
            document.title = 'Licensor "' + this.props.parent.licencorsName + '" - Contact persons - AV-CMP';
        }
    }

    deleteButtonClick(e) {
        e.preventDefault();

        this.props.Modal.setModalConfirmation({
            header : <Header icon='trash' content='Delete contact person'/>,
            text : MESSAGES.DELETE_CONFIRMATION(this.props.dataForForm.contactPerson.name),
            onYes : this.deleteContactPerson,
        });
    }

    closeModal = (e) => {
        e.preventDefault();

        this.props.Modal.setModal({
            content: null,
            isVisible : false,
        });

        this.props.history.push(getLink(`${this.props.parent.name}.contactPerson.index`, {id: this.props.parent.id}));
    };

    renderDeleteButton() {
        let deleteButton = null;

        if (hasPrivileges(this.authorizedObject.deleteContactPerson)
            && this.props.dataForForm.contactPerson.id !== undefined
            && '' !== this.props.dataForForm.contactPerson.id)
        {
            deleteButton =
                <Button onClick={ this.deleteButtonClick }
                    color='red'
                    disabled={ this.props.submitting }
                    icon='trash'
                    content='Delete'/>;
        }

        return deleteButton;
    }

    render() {
        const AuthorizedField = this.AuthorizedField;

        return (
            <div>
                <Form.Create
                    onSubmit={ this.props.handleSubmit((values) => { return this.onFormSubmit(values, this.props); }) }
                    loading={ (this.props.formData !== undefined) ? this.props.formData.loading : false }
                >
                    <AuthorizedField
                        name='id'
                        label='ID'
                        defaultValue={ this.props.dataForForm.contactPerson.id }
                        component={ Form.SemanticInput }
                        type='number'/>
                    <div className='formRow'>
                        <AuthorizedField
                            name='name'
                            required
                            label='Name'
                            defaultValue={ this.props.dataForForm.contactPerson.name }
                            component={ Form.SemanticInput }
                            type='text'
                            autoFocus/>
                    </div>
                    <div className='formRow'>
                        <AuthorizedField
                            name='position'
                            label='Position'
                            defaultValue={ this.props.dataForForm.contactPerson.position }
                            component={ Form.SemanticInput }
                            type='text'/>
                    </div>
                    <div className='formRow'>
                        <AuthorizedField
                            name='address'
                            label='Address'
                            rows={ 2}
                            defaultValue={ this.props.dataForForm.contactPerson.address }
                            component={ Form.SemanticTextArea }
                            type='number'/>
                    </div>
                    <div className='formRow'>
                        <AuthorizedField
                            name='email_address'
                            label='Email address'
                            defaultValue={ this.props.dataForForm.contactPerson.email_address }
                            component={ Form.SemanticInput }
                            type='text'/>
                    </div>
                    <div className='formRow'>
                        <AuthorizedField
                            name='phone_number_office'
                            label='Phone number (office)'
                            defaultValue={ this.props.dataForForm.contactPerson.phone_number_office }
                            component={ Form.SemanticInput }
                            type='text'/>
                    </div>
                    <div className='formRow'>
                        <AuthorizedField
                            name='phone_number_mobile'
                            label='Phone number (mobile)'
                            defaultValue={ this.props.dataForForm.contactPerson.phone_number_mobile }
                            component={ Form.SemanticInput }
                            type='text'/>
                    </div>
                    <div className='formRow'>
                        <AuthorizedField
                            name='skype_name'
                            label='Skype name'
                            defaultValue={ this.props.dataForForm.contactPerson.skype_name }
                            component={ Form.SemanticInput }
                            type='text'/>
                    </div>
                    <div className='formRow'>
                        <AuthorizedField
                            name='notes'
                            label='Notes'
                            defaultValue={ this.props.dataForForm.contactPerson.notes }
                            component={ Form.SemanticTextArea }
                            type='number'/>
                    </div>
                    <Form.FormRowChangelog
                        resources={CONTACT_PERSON}
                        data={ (this.props.formData || {}).contactPerson }
                    />
                    <div className='formRow form__footer'>
                        {this.authorized && <Button
                            color='blue'
                            type='submit'
                            loading={ this.props.submitting }
                            disabled={ this.props.submitting }
                            icon='save'
                            content='Save'>
                        </Button>}
                        { this.renderDeleteButton() }
                        <ButtonCancel
                            disabled={this.props.submitting}
                            onClick={ this.closeModal }
                        >
                            {this.authorized ? 'Cancel' : 'Close'}
                        </ButtonCancel>
                    </div>
                </Form.Create>
            </div>
        );
    }
}

const ContactPersonFormWithRedux =
    reduxForm({ form: 'ContactPerson', validate})(ContactPersonForm);

const ContactPersonFormWithRouter = withRouter(FormWrapper({
    contactPerson: {
        id: '',
        name: '',
        position: '',
        address: '',
        email_address: '',
        phone_number_office: '',
        phone_number_mobile: '',
        skype_name: '',
        notes: '',
    },
}, ContactPersonFormWithRedux));

export default compose(
    graphql(CreateContactPerson, { name: 'CreateContactPerson' }),
    graphql(DeleteContactPerson, { name: 'DeleteContactPerson' }),
    graphql(UpdateContactPerson, { name: 'UpdateContactPerson' })
)(ContactPersonFormWithRouter);
