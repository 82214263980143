import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';

import {Authorization} from '@appComponents/Authorization';
import {convertToInt} from '@utils/helpers';
import {getLink} from '@appComponents/Link';
import {IconEdit} from '@appComponents/IconCollection';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import Table from '@appComponents/Table';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';

const authorizationObject = {
    [RESOURCES.AD_SPLICE_CONFIG]: CONST.SECURITY_PRIVILEGES_READ,
};

export const columns = {
    'id': {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {
        label: 'Name',
        sorting: (row) => row.name.toLowerCase(),
    },
    'ad_splice': {
        label: 'Ad splice',
        sorting: (row) => row.ad_splice.name,
    },
    'duration': {
        label: 'Duration',
        sorting: (row) => row.duration,
    },
    'ad_start_offset': {
        label: 'Ad start offset',
        sorting: (row) => row.ad_start_offset,
    },
    'ad_duration_offset': {
        label: 'Ad duration offset',
        sorting: (row) => row.ad_duration_offset,
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
};

export const renderAdSpliceNameColumn = (column, row) => {
    return (
        <div>
            <div>
                {(_isEmpty(row[column]))
                    ? ''
                    :
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: 'adSplice.edit',
                            params: {
                                id: row.id,
                            },
                        }}
                    >
                        {row?.name}
                    </LinkWithAuthorization>
                }
            </div>
        </div>
    );
};

const renderActionsColumn = (row) => {
    return (
        <Authorization authorization={authorizationObject}>
            <div className='actions-list-icon'>
                <IconEdit
                    size='large'
                    link={getLink('adSplice.edit', {
                        id: row.id,
                    })}
                    resource={RESOURCES.AD_SPLICE_CONFIG}
                />
            </div>
        </Authorization>
    );
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'id':
            return row[column];
        case 'name':
            return renderAdSpliceNameColumn(column, row);
        case 'ad_splice':
            return row.ad_splice.name;
        case 'duration':
            return row.duration ? row.duration + 's' : null;
        case 'ad_start_offset':
            return (row.ad_start_offset || 0 === row.ad_start_offset) ? row.ad_start_offset + 'ms' : null;
        case 'ad_duration_offset':
            return (row.ad_duration_offset || 0 === row.ad_duration_offset) ? row.ad_duration_offset + 'ms' : null;
        case 'actions':
            return renderActionsColumn(row);
        default:
            return null;
    }
};

const AdSpliceTable = ({adSplices, loading}) => {
    return (
        <Table
            name='adSpliceTable'
            columns={columns}
            data={adSplices}
            loading={loading}
            rowRenderer={rowRenderer}
            noDataText='No Ad splice configs found'
        />
    );
};

AdSpliceTable.propTypes = {
    adSplices: PropTypes.array,
    loading: PropTypes.bool,
};

export default AdSpliceTable;
