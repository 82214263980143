import validators from '@utils/validators';

const validate = (values) => {
    const errors ={};

    if (0 < values.coverage?.length) {
        Object.assign(errors, validators.required(values.clip_provider, 'clip_provider'));
    }

    return errors;
};

export default validate;
