import React, {useState} from 'react';
import {Checkbox, Divider, Grid, Header, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {hasPrivileges} from '@appComponents/Authorization';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';
import {hideModal, showModalConfirmation} from '@utils/modal';
import ClientVplDomainsForm from '@modules/client/forms/ClientVplDomainsForm';
import {convertToInt} from '@utils/helpers';
import {IconDelete} from '@appComponents/IconCollection';

const ClientVplDomainsTable = ({data, domains, setDomains}) => {
    const [activeEditableFields, setActiveEditableFields] = useState(null);
    const authorization = {
        update: hasPrivileges({[RESOURCES.CLIENT_VPL_LICENCE_KEY]: CONST.SECURITY_PRIVILEGES_UPDATE}),
    };

    let clientVplDomains = [
        ...domains,
        ...(data?.clientVplLicenceKey?.client_vpl_domains || []),
    ];

    const toggleIsDomainActive = (indexId) => {
        const updatedDomains = clientVplDomains.map((domain, index) => {
            if (index === convertToInt(indexId)) {
                return {
                    ...domain,
                    is_disabled: !domain.is_disabled,
                };
            }

            return domain;
        });

        setDomains(updatedDomains);
    };

    const deleteDomain = (indexId) => {
        const updatedDomains = clientVplDomains.filter((domain, index) => index !== convertToInt(indexId));

        setDomains(updatedDomains);
    };

    const removeDomain = (index) => {
        showModalConfirmation({
            header: <Header icon='trash' content={'Delete domain'}/>,
            text: 'Are you sure you want to remove this domain?',
            onYes: () => {
                deleteDomain(index);
                hideModal();
            },
        });
    };

    const renderButtons = (data, index) => {
        return (
            <div className='--flex actions-list-icon --custom-gap'>
                <Checkbox
                    toggle
                    checked={!data.is_disabled}
                    onChange={() => toggleIsDomainActive(index)}
                />
                <IconDelete
                    icon='trash'
                    size='large'
                    onClick={() => removeDomain(index)}
                />
            </div>
        );
    };

    const renderEditableGrids = () => {
        return clientVplDomains.map((data, index) => {
            return (
                [
                    <React.Fragment key={index}>
                        {(activeEditableFields === convertToInt(index))
                            ? renderEditForm(data, index, 'form_cvd_edit')
                            : renderEditableGrid(data, index)}
                    </React.Fragment>,
                ]
            );
        });
    };

    const renderEditableGrid = (data, index) => {
        const onClickCallback = authorization.update ? activateEditableCell : null;

        return (
            <div className='--max-width-600'>
                <Grid key={index} verticalAlign='top'>
                    <Grid.Row data-id={index} onClick={onClickCallback} className='editableTable__trigger'>
                        <Grid.Column width={13}>
                            {displayDomain(data.domain)}
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {renderButtons(data, index)}
                        </Grid.Column>
                    </Grid.Row>
                    <Divider className='--margin-sides-top-0'/>
                </Grid>
            </div>
        );
    };

    const displayDomain = (domain) => {
        if (50 < domain?.length) {
            const shortenedDomain = domain.slice(0, 50) + '...';

            return (
                <Popup
                    flowing
                    hoverable
                    position='top left'
                    size='small'
                    trigger={<span>{shortenedDomain}</span>}
                >
                    {domain}
                </Popup>
            );
        }

        return domain;
    };

    const activateEditableCell = (event) => {
        setActiveEditableFields(convertToInt(event.currentTarget.dataset.id));
    };

    const handleDeactivateEditableCell = () => {
        setActiveEditableFields(null);
    };

    const renderAddForm = () => (
        <ClientVplDomainsForm
            formType='form_cvd_add'
            domains={domains}
            setDomains={setDomains}
        />
    );

    const renderEditForm = (data, key, formType) => {
        return (
            <ClientVplDomainsForm
                formType={formType}
                formData={{
                    id: key,
                    domain: data.domain,
                    is_disabled: data.is_disabled,
                }}
                domains={domains}
                setDomains={setDomains}
                onDeactivateEditableCell={handleDeactivateEditableCell}
            />
        );
    };

    return (
        <div className='--form-container'>
            {renderAddForm()}
            {renderEditableGrids()}
        </div>
    );
};

ClientVplDomainsTable.displayName = 'ClientVplDomainsTable';

ClientVplDomainsTable.propTypes = {
    data: PropTypes.object,
    domains: PropTypes.array,
    setDomains: PropTypes.func,
};

export default ClientVplDomainsTable;
