import {convertToInt} from '@utils/helpers';
import {EVENT_TYPE_SPORTRADAR} from '@constants/variables';

/**
 * Check if event is
 * - Sportradar
 * @returns {boolean}
 */
export const isSportradarEvent = (event) => (
    EVENT_TYPE_SPORTRADAR === convertToInt(event.event_type.id)
);
