import {get as _get} from 'lodash';

import {refetchQueryByName} from '@utils/apollo';
import {convertToInt} from '@utils/helpers';
import {
    prefillClipProvider,
    transformProductsForCoverageDropdown,
} from '@modules/events/utils/formHelper';
import DefaultForm from '@appComponents/DefaultForm';

export class SetCoverageForm extends DefaultForm {

    prepareDataForSubmit(data) {
        return {
            id: 'UPDATE', //Dummy variable for update
            events: this.props.formParams.optionsVariables.eventsIds,
            products: data.coverage,
            clip_provider: data.clip_provider ? data.clip_provider : null,
        };
    }

    componentDidMount() {
        this.setUpdateSuccessCallback(() => {
            refetchQueryByName(this.props.formParams.queryForRefetch);
        });

        const products = _get(this.props, 'formParams.optionsVariables.products', []);

        this.setField('coverage', {
            options: transformProductsForCoverageDropdown(products),
            defaultValue: products.map(product => convertToInt(product.id)),
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.formValues.coverage !== prevProps.formValues.coverage) {
            const {rightScopes, products} = this.props.formParams.optionsVariables;
            this.setField('clip_provider', {
                hidden: !this.props.formValues.coverage.length,
                defaultValue: this.props.formValues.coverage.length ? prefillClipProvider(rightScopes, products) : null,
            });
        }
    }

    renderSaveButton(props = {}) {
        return super.renderSaveButton({...props, ...{content: 'Apply'}});
    }
}

export default SetCoverageForm;
