import validators from '@utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.expiry_date, 'expiry_date'));

    const validateDomain = (domain, fieldName) => {
        Object.assign(errors, validators.maxLength({ value: domain, maxLength: 100 }, fieldName));
        Object.assign(errors, validators.protocolValidator(domain, fieldName));
    };

    if (values.client_vpl_domains) {
        validateDomain(values.client_vpl_domains, 'client_vpl_domains');
    }

    if (values.client_vpl_domains_edit) {
        validateDomain(values.client_vpl_domains_edit, 'client_vpl_domains_edit');
    }

    return errors;
};

export default validate;
