import { isEmpty as _isEmpty, map as _map } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, useQuery } from '@apollo/client';
import { Field } from 'redux-form';

import { withApollo } from 'react-apollo';
import Form from '@appComponents/ReduxFormControls';
import { GetClipConfigInitialFilters } from '@graphql/clipConfiguration/query';
import { GetTournamentsForDropdownMultipleCategories } from '@graphql/tournament/query';
import { GetTournamentCategoryDropdownDataFromSports } from '@graphql/sport/query';
import { FiltersWrapper, getSelectedValues } from '@appComponents/HOCFiltersWrapper';
import { convertToInt } from '@utils/helpers';
import { sortDropdownOptionsAlphanumerically } from '@utils/sorters';
import { showErrorModal } from '@utils/modal';
import { getLink } from '@appComponents/Link';
import {DISTRIBUTION_TYPE_VOD, EVENT_SPORTRADAR_TYPE} from '@constants/variables';

export const DEFAULT_FILTER_VALUES = {
    search: null,
    products: null,
    sports: null,
    categories: null,
    tournaments: null,
    eventContentTypes: null,
    matchEventTypes: null,
};

export const FILTER_KEY = 'clipConfiguration';

const ClipConfigurationFilters = ({
    restoreFilterValues,
    form: {changeValue: setFormFieldValue },
    filters,
    loading: isClipDataLoading,
}) => {
    const apollo = useApolloClient(),
        [sportOptions, setSportOptions] = useState([]),
        [selectedSportOptions, setSelectedSportOptions] = useState(restoreFilterValues(filters[FILTER_KEY], 'sports', [])),
        [tournamentCategoryOptions, setTournamentCategoryOptions] = useState([]),
        [selectedTournamentCategoryOptions, setSelectedTournamentCategoryOptions]
            = useState(restoreFilterValues(filters[FILTER_KEY], 'categories', [])),
        [tournamentOptions, setTournamentOptions] = useState([]),
        [selectedTournamentOptions, setSelectedTournamentOptions]
            = useState(restoreFilterValues(filters[FILTER_KEY], 'tournaments', [])),
        [eventContentTypeOptions, setEventContentTypeOptions] = useState([]),
        [matchEventTypeOptions, setMatchEventTypeOptions] = useState([]);

    const {
        data: {
            productsDropdown,
        } = {},
        loading: isFilterDataLoading = true,
        error: errorFetchingFilterData,
    } = useQuery(GetClipConfigInitialFilters, {
        onCompleted: (data) => {
            setSportOptions(sortDropdownOptionsAlphanumerically(data.sports.mapDataForDropdownWithIntVal()));
            setEventContentTypeOptions(sortDropdownOptionsAlphanumerically(data.eventContentTypes));
            setMatchEventTypeOptions(sortDropdownOptionsAlphanumerically(data.matchEventTypes.mapDataForDropdownWithIntVal()));
        },
    });

    const isLoading = isClipDataLoading || isFilterDataLoading;

    const onSportChange = (event, value) => {
        if (value.length) {
            const sportIds = _map(value, (sport) => convertToInt(sport));
            setSelectedSportOptions(sportIds);
            setTournamentCategoryOptionsFromSelectedSports(sportIds);
        } else {
            setTournamentOptions([]);
            setSelectedTournamentOptions([]);
            setFormFieldValue('tournaments', []);
            setTournamentCategoryOptions([]);
            setSelectedTournamentCategoryOptions([]);
            setFormFieldValue('categories', []);
            setSelectedSportOptions([]);
        }
    };

    const setTournamentCategoryOptionsFromSelectedSports = (sportIds = []) => {
        apollo.query({
            query: GetTournamentCategoryDropdownDataFromSports,
            variables: {sportIds: sportIds},
        }).then((result) => {
            const tournamentCategories = sortDropdownOptionsAlphanumerically(
                    result.data.tournamentCategories.mapDataForDropdownWithIntVal()
                ),
                selectedCategories = getSelectedValues(selectedTournamentCategoryOptions, tournamentCategories);
            setTournamentCategoryOptions(tournamentCategories);
            setSelectedTournamentCategoryOptions(selectedCategories);
            setFormFieldValue('categories', selectedCategories);
        });
    };

    const onTournamentCategoryChange = (event, value) => {
        if (value.length) {
            const tournamentCategoryIds = _map(value, (tournamentCategoryId) => convertToInt(tournamentCategoryId));
            setSelectedTournamentCategoryOptions(tournamentCategoryIds);
            setTournamentOptionsFromSelectedTournamentCategories(tournamentCategoryIds);
        } else {
            setTournamentOptions([]);
            setFormFieldValue('tournaments', []);
            setSelectedTournamentCategoryOptions([]);
            setFormFieldValue('categories', []);
        }
    };

    const setTournamentOptionsFromSelectedTournamentCategories = (tournamentCategoryIds = []) => {
        apollo.query({
            query: GetTournamentsForDropdownMultipleCategories,
            variables: {categories: tournamentCategoryIds},
        }).then((result) => {
            const tournaments = sortDropdownOptionsAlphanumerically(result.data.tournaments.mapDataForDropdownWithIntVal()),
                selectedTournaments = getSelectedValues(selectedTournamentOptions, tournaments);
            setTournamentOptions(tournaments);
            setSelectedTournamentOptions(selectedTournaments);
            setFormFieldValue('tournaments', selectedTournaments);
        });
    };

    const onTournamentChange = (event, value) => {
        if (value.length) {
            const tournamentIds = _map(value, (tournamentId) => convertToInt(tournamentId));
            setSelectedTournamentOptions(tournamentIds);
        } else {
            setSelectedTournamentOptions([]);
            setFormFieldValue('tournaments', []);
        }
    };

    const filteredEventContentTypeOptions = eventContentTypeOptions.filter((eventContentTypeOption) => {
        return eventContentTypeOption.event_types.some(eventType => convertToInt(eventType.id) === EVENT_SPORTRADAR_TYPE)
            && eventContentTypeOption.distribution_types.some(distributionType => convertToInt(distributionType.id) === DISTRIBUTION_TYPE_VOD);
    });

    if (!_isEmpty(errorFetchingFilterData)) {
        showErrorModal({
            header: 'Clip configurations filter data could not be retrieved',
            text: errorFetchingFilterData.toString(),
            errorButtonText: 'Back to dashboard',
            hideButtons: false,
            redirectLink: getLink('dashboard'),
            errorButtonIcon: 'arrow circle left',
            size: 'large',
        });

        return null;
    }

    return (
        <div className='content__filters'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={isLoading}
                setValue={restoreFilterValues(filters[FILTER_KEY], 'search') || ''}
            />
            <Field
                component={Form.ReduxSelect}
                name='products'
                placeholder='Product'
                options={productsDropdown}
                disabled={isLoading}
                loading={isLoading}
                multiple={true}
                search
                setValue={restoreFilterValues(filters[FILTER_KEY], 'products')}
            />
            <Field
                component={Form.ReduxSelect}
                name='sports'
                placeholder='Sport'
                options={sportOptions}
                disabled={isLoading}
                loading={isLoading}
                multiple={true}
                onChangeValue={onSportChange}
                search
                setValue={restoreFilterValues(filters[FILTER_KEY], 'sports')}
            />
            <Field
                component={Form.ReduxSelect}
                name='categories'
                placeholder='Category'
                options={tournamentCategoryOptions}
                disabled={isLoading}
                loading={isLoading}
                multiple={true}
                hidden={0 === selectedSportOptions.length}
                onChangeValue={onTournamentCategoryChange}
                search
                setValue={restoreFilterValues(filters[FILTER_KEY], 'categories')}
            />
            <Field
                component={Form.ReduxSelect}
                name='tournaments'
                placeholder='Tournament'
                options={tournamentOptions}
                disabled={isLoading}
                loading={isLoading}
                multiple={true}
                hidden={0 === selectedTournamentCategoryOptions.length}
                onChangeValue={onTournamentChange}
                search
                setValue={restoreFilterValues(filters[FILTER_KEY], 'tournaments')}
            />
            <Field
                component={Form.ReduxSelect}
                name='eventContentTypes'
                placeholder='Event content type'
                options={filteredEventContentTypeOptions}
                disabled={isLoading}
                loading={isLoading}
                multiple={true}
                search
                setValue={restoreFilterValues(filters[FILTER_KEY], 'eventContentTypes')}
            />
            <Field
                component={Form.ReduxSelect}
                name='matchEventTypes'
                placeholder='Match event type'
                options={matchEventTypeOptions}
                disabled={isLoading}
                loading={isLoading}
                multiple={true}
                search
                setValue={restoreFilterValues(filters[FILTER_KEY], 'matchEventTypes')}
            />
        </div>
    );
};

ClipConfigurationFilters.propTypes = {
    restoreFilterValues: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default FiltersWrapper(withApollo(ClipConfigurationFilters), {
    name: FILTER_KEY,
    initialValues: DEFAULT_FILTER_VALUES,
});
