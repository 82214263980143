import React from 'react';
import {Button, Icon} from 'semantic-ui-react';
import {gql} from '@apollo/client';

import {hideModal} from '@utils/modal';
import {sendAlert} from '@graphql/liveControl/alerts';
import {showMessageBox} from '@utils/messageBox';

import apolloClient from '../../../apolloClient';

export const isAtLeastOneRecipientDefined = (clients) => {
    if(clients.length) {
        return clients.find(client => client.notification_email_recipients !== null)
    }

    return false;
};

export const renderEmailPreviewButtons = ({handleBackButtonClick, sending, sendEmail}) => {
    return (
        <Button.Group>
            <Button
                color='grey'
                content='Back'
                icon='angle double left'
                labelPosition='left'
                onClick={handleBackButtonClick}
            />
            <Button
                content='Send'
                type='submit'
                icon={<Icon className='outline' name='mail'/>}
                loading={sending}
                color='blue'
                onClick={sendEmail}
            />
            <Button
                content='Cancel'
                type='button'
                icon='cancel'
                onClick={hideModal}
            />
        </Button.Group>
    );
};

export const sendEmailAlertNotification = ({setSending, submittedData, isLiveControl}) => {
    setSending(true);

    return apolloClient.mutate({
        mutation: gql`${sendAlert}`,
        variables: submittedData,
        fetchPolicy: 'no-cache',
    }).then(() => {
        hideModal();
        showMessageBox(isLiveControl ? 'liveControl' : 'targetsInfo', 'The alert has been sent to the clients successfully.', null, 'success');
    }).catch((error) => {
        hideModal();
        showMessageBox(isLiveControl ? 'liveControl' : 'targetsInfo', 'The alert could not be sent', error.error, 'error');
    });
};
