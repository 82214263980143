import BlackoutZoneIndex from '@modules/blackoutZone/views/BlackoutZoneIndex';
import BlackoutZoneEdit from '@modules/blackoutZone/views/BlackoutZoneEdit';
import LcrChannelIndex from '@modules/lcr/views/LcrChannelIndex';
import LcrChannelEdit from '@modules/lcr/views/LcrChannelEdit';
import WnsStreamsIndex from '@modules/wnsStream/views/WnsStreamsIndex';
import WnsStreamsEdit from '@modules/wnsStream/views/WnsStreamsEdit';
import CountriesIndex from '@modules/country/views/CountriesIndex';
import CountryTabsLayout from '@modules/country/views/CountryTabsLayout';
import MediaConnectIndex from '@modules/mediaConnect/views/MediaConnectIndex';
import MediaConnectEdit from '@modules/mediaConnect/views/MediaConnectEdit';
import ClipConfigurationIndex from '@modules/clipConfiguration/views/ClipConfigurationIndex';
import ChangelogElement from '@modules/changelog/views/ChangeLogElement';

import * as CONST from "./variables";
import * as RESOURCES from "./resources";
import Dashboard from "../../dashboard/views/Dashboard";
import LicensorIndex from "../../licensor/views/LicensorIndex";
import NotFound from "../views/NotFound";
import LicenseeIndex from "../../licensee/views/LicenseeIndex";
import LicenseeTabsLayout from "../../licensee/layouts/LicenseeTabsLayout";
import LicensorTabsLayout from "../../licensor/layouts/LicensorTabsLayout";
import PropertyLicenceIndex from "../../propertyLicence/views/PropertyLicenceIndex";
import PropertyLicenceTabsLayout from "../../propertyLicence/layouts/PropertyLicenceTabsLayout";
import CompetitorIndex from "../../competitor/views/CompetitorsIndex";
import CompetitorEdit from "../../competitor/views/CompetitorEdit";
import ContentCategoriesIndex from "../../contentCategories/views/ContentCategoriesIndex"
import ClientIndex from "../../client/views/ClientIndex";
import ClientTabsLayout from "../../client/layouts/ClientTabsLayout";
import ChangeLogIndex from '../../changelog/views/ChangeLogIndex';
import SdiOriginEntryPointIndex from '../../sdiOriginEntryPoint/views/SdiOriginEntryPointIndex';
import SdiOriginEntryPointEdit from '../../sdiOriginEntryPoint/views/SdiOriginEntryPointEdit';
import ProductionCompanyIndex from "../../productionCompany/views/ProductionCompanyIndex";
import ProductionCompanyTabsLayout from "../../productionCompany/layouts/ProductionCompanyTabsLayout";
import RtmpOriginEntryPointIndex from "../../rtmpOriginEntryPoint/views/RtmpOriginEntryPointIndex";
import RtmpOriginEntryPointTabsLayout from "../../rtmpOriginEntryPoint/layouts/RtmpOriginEntryPointTabsLayout";
import UdpOriginEntryPointIndex from "../../udpOriginEntryPoint/views/UdpOriginEntryPointIndex";
import EncodingJobProfileIndex from "../../encodingJobProfile/views/EncodingJobProfileIndex";
import EncodingJobProfileEdit from "../../encodingJobProfile/views/EncodingJobProfileEdit";
import EventsIndex from "../../events/views/EventsIndex";
import EventsTabLayout from "../../events/layouts/EventsTabsLayout";
import UserIndex from "../../user/views/UserIndex";
import UserSettings from "../../user/views/UserSettings";
import EventContentTypesIndex from "../../eventContentTypes/views/EventContentTypesIndex";
import EventContentTypeEdit from "../../eventContentTypes/views/EventContentTypeEdit";
import VenuesIndex from "../../venue/views/VenuesIndex";
import VenueEdit from "../../venue/views/VenueEdit";
import ProductIndex from "../../product/views/ProductIndex";
import ProductLayout from "../../product/layouts/ProductLayout";
import LiveControlIndex from "../../liveControl/views/LiveControlIndex";
import StreamPlaybackIndex from "../../streamPlayback/views/StreamPlaybackIndex";
import IpBlacklistingIndex from "../../ipBlacklisting/views/IpBlacklistingIndex";
import RolesIndex from "../../role/views/RoleIndex";
import RoleTabsLayout from "../../role/layouts/RoleTabsLayout";
import {UdpOriginEntryPointEdit} from "../../udpOriginEntryPoint/views/UdpOriginEntryPointEdit";
import UserTabsLayout from "../../user/layouts/UserTabsLayout";
import UsersAddIndex from "../../user/components/UsersAddIndex";
import EventRedirectByDistribution from "../../events/views/EventRedirectByDistribution";
import EventRedirectByEventContent from "../../events/views/EventRedirectByEventContent";
import EventRedirectByMatch from "../../events/views/EventRedirectByMatch";
import AdSpliceIndex from "../../adSplice/views/AdSpliceIndex";
import AdSpliceEdit from "../../adSplice/views/AdSpliceEdit";
import {ClientRedirectByBookmaker} from '../../client/views/ClientRedirectByBookmaker';
import ClientPackagesIndex from '../../client/views/ClientPackagesIndex';

/**
 * Routes object
 *
 * For route authorization you can set keys:
 * <pre>
 *  resource - {Array<String> | String> Array of names or name of resource
 *  privileges - {Bitmask | Number} privileges for resource
 * </pre>
 *
 */
export const routes = {
    dashboard: {
        path: "/",
        class: "notLogged",
        component: Dashboard,
        icon: "fa fa-home",
        title: "Home :",
        public: true
    },
    liveControl: {
        path: "/live-control",
        class: "notLogged",
        component: LiveControlIndex,
        icon: "",
        title: "Live control :",
        resource: RESOURCES.LIVE_CONTROL,
        privileges: CONST.SECURITY_PRIVILEGES_READ,
    },
    lcrChannel: {
        index: {
            path: '/lcr/channels',
            class: 'lc-channels-index',
            component: LcrChannelIndex,
            excelExport: true,
            title: 'LCR Channels',
            authorization: {
                [RESOURCES.LCR_CHANNEL]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        add: {
            path: '/lcr/channels/add',
            component: LcrChannelEdit,
            title: 'LCR - Channels - Add new LCR channel',
            authorization: {
                [RESOURCES.LCR_CHANNEL]: CONST.SECURITY_PRIVILEGES_CREATE,
            },
        },
        edit: {
            generalInformation: {
                path: '/lcr/channels/:id',
                component: LcrChannelEdit,
                title: 'LCR - Channels',
                authorization: {
                    [RESOURCES.LCR_CHANNEL]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            geoRestrictions: {
                path: '/lcr/channels/:id/geo-restrictions',
                component: LcrChannelEdit,
                title: 'LCR - Channels - Geo restrictions',
                authorization: {
                    [RESOURCES.LCR_CHANNEL]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            contentRestrictions: {
                index: {
                    path: '/lcr/channels/:id/content-restrictions',
                    component: LcrChannelEdit,
                    title: 'LCR - Channels - Content restrictions',
                    authorization: {
                        [RESOURCES.LCR_CHANNEL]: CONST.SECURITY_PRIVILEGES_READ,
                    },
                },
                sports: {
                    add: {
                        path: '/lcr/channels/:id/content-restrictions/sports/add',
                        component: LcrChannelEdit,
                        title: 'LCR - Channels - Content restrictions',
                        authorization: {
                            [RESOURCES.LCR_CHANNEL]: CONST.SECURITY_PRIVILEGES_READ,
                        },
                    },
                    edit: {
                        path: '/lcr/channels/:id/content-restrictions/sports/:sportId',
                        component: LcrChannelEdit,
                        title: 'LCR - Channels - Content restrictions',
                        authorization: {
                            [RESOURCES.LCR_CHANNEL]: CONST.SECURITY_PRIVILEGES_READ,
                        },
                    },
                },
                categories: {
                    add: {
                        path: '/lcr/channels/:id/content-restrictions/categories/add',
                        component: LcrChannelEdit,
                        title: 'LCR - Channels - Content restrictions',
                        authorization: {
                            [RESOURCES.LCR_CHANNEL]: CONST.SECURITY_PRIVILEGES_UPDATE,
                        },
                    },
                    edit: {
                        path: '/lcr/channels/:id/content-restrictions/categories/:categoryId',
                        component: LcrChannelEdit,
                        title: 'LCR - Channels - Content restrictions',
                        authorization: {
                            [RESOURCES.LCR_CHANNEL]: CONST.SECURITY_PRIVILEGES_UPDATE,
                        },
                    },
                },
                tournaments: {
                    add: {
                        path: '/lcr/channels/:id/content-restrictions/tournaments/add',
                        component: LcrChannelEdit,
                        title: 'LCR - Channels - Content restrictions',
                        authorization: {
                            [RESOURCES.LCR_CHANNEL]: CONST.SECURITY_PRIVILEGES_UPDATE,
                        },
                    },
                },
            },
        },
    },
    licensors: {
        index: {
            path: "/licensors",
            class: "licensors-index",
            component: LicensorIndex,
            excelExport: true,
            icon: "fa fa-user",
            title: "Licensors",
            authorization: {
                [RESOURCES.LICENSOR]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        add: {
            path: "/licensors/add",
            class: "licensor-add",
            component: LicensorTabsLayout,
            icon: "fa fa-user",
            authorization: {
                [RESOURCES.LICENSOR]: CONST.SECURITY_PRIVILEGES_CREATE_READ
            }
        },
        edit: {
            path: "/licensors/:id",
            class: "licensor-edit",
            component: LicensorTabsLayout,
            authorization: {
                [RESOURCES.LICENSOR]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        contactPerson: {
            index: {
                path: "/licensors/:id/contact-persons",
                class: "contact-person-index",
                component: LicensorTabsLayout,
                icon: "fa fa-user",
                title: "Contact persons",
                authorization: {
                    [RESOURCES.LICENSOR]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.CONTACT_PERSON]: CONST.SECURITY_PRIVILEGES_READ
                }
            },
            add: {
                path: "/licensors/:id/contact-persons/add",
                class: "licensor-edit",
                component: LicensorTabsLayout,
                icon: "fa fa-user",
                authorization: {
                    [RESOURCES.LICENSOR]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.CONTACT_PERSON]: CONST.SECURITY_PRIVILEGES_CREATE_READ
                }
            },
            edit: {
                path: "/licensors/:id/contact-persons/:contactPersonId",
                class: "licensor-edit",
                component: LicensorTabsLayout,
                icon: "fa fa-user",
                authorization: {
                    [RESOURCES.LICENSOR]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.CONTACT_PERSON]: CONST.SECURITY_PRIVILEGES_READ
                }
            }
        },
    },
    licensees: {
        "index": {
            path: "/licensees",
            class: "licensees-index",
            component: LicenseeIndex,
            excelExport: true,
            title: "Licensee",
            authorization: {
                [RESOURCES.LICENSEE]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        "add": {
            path: "/licensees/add",
            component: LicenseeTabsLayout,
            authorization: {
                [RESOURCES.LICENSEE]: CONST.SECURITY_PRIVILEGES_CREATE_READ
            }
        },
        "edit": {
            path: "/licensees/:id",
            component: LicenseeTabsLayout,
            authorization: {
                [RESOURCES.LICENSEE]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        "contactPerson": {
            "index": {
                path: "/licensees/:id/contact-persons",
                class: "licensees-contact-person-index",
                component: LicenseeTabsLayout,
                authorization: {
                    [RESOURCES.LICENSEE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.CONTACT_PERSON]: CONST.SECURITY_PRIVILEGES_READ,
                }
            },
            "add": {
                path: "/licensees/:id/contact-persons/add",
                class: "licensor-edit",
                component: LicenseeTabsLayout,
                icon: "fa fa-user",
                authorization: {
                    [RESOURCES.LICENSEE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.CONTACT_PERSON]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                }
            },
            "edit": {
                path: "/licensees/:id/contact-persons/:contactPersonId",
                class: "licensor-edit",
                component: LicenseeTabsLayout,
                icon: "fa fa-user",
                authorization: {
                    [RESOURCES.LICENSEE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.CONTACT_PERSON]: CONST.SECURITY_PRIVILEGES_READ,
                }
            }
        }
    },
    propertyLicences: {
        "index": {
            path: "/property-licences",
            class: "propertyLicences-index",
            component: PropertyLicenceIndex,
            excelExport: true,
            icon: "fa fa-user",
            title: "Property Licences",
            authorization: {
                [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        "add": {
            path: "/property-licences/add",
            component: PropertyLicenceTabsLayout,
            authorization: {
                [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
            },
        },
        "edit": {
            path: "/property-licences/:id",
            component: PropertyLicenceTabsLayout,
            authorization: {
                [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        "content": {
            "index": {
                path: "/property-licences/:id/content",
                class: "propertyLicences-content-index",
                component: PropertyLicenceTabsLayout,
                excelExport: true,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.PROPERTY_LICENCE_CONTENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            }
        },
        "accreditation": {
            "index": {
                path: "/property-licences/:id/accreditation",
                class: "propertyLicences-accreditation-index",
                component: PropertyLicenceTabsLayout,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.CONTACT_PERSON]: CONST.SECURITY_PRIVILEGES_READ,
                },
            }
        },
        "linksAndAttachments": {
            "index": {
                path: "/property-licences/:id/links-and-attachments",
                class: "propertyLicences-linksAndAttachments-index",
                component: PropertyLicenceTabsLayout,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                },
            }
        },
        "matchSelectionPeriods": {
            "index": {
                path: "/property-licences/:id/match-selection-periods",
                class: "propertyLicences-match-selection-periods-index",
                component: PropertyLicenceTabsLayout,
                excelExport: true,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.MATCH_SELECTION_PERIOD]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            "add": {
                path: "/property-licences/:id/match-selection-periods/add",
                class: "propertyLicences-match-selection-periods-add",
                component: PropertyLicenceTabsLayout,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.MATCH_SELECTION_PERIOD]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                },
            },
            "edit": {
                path: "/property-licences/:id/match-selection-periods/:matchSelectionPeriodId",
                class: "propertyLicences-match-selection-periods-edit",
                component: PropertyLicenceTabsLayout,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.MATCH_SELECTION_PERIOD]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
        },
        "pricing": {
            "index": {
                path: "/property-licences/:id/pricing",
                class: "propertyLicences-pricing-index",
                component: PropertyLicenceTabsLayout,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
        },
        "technicalSetup": {
            "index" : {
                path: "/property-licences/:id/technical-setup",
                class: "propertyLicences-technicalSetup-index",
                component: PropertyLicenceTabsLayout,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.PROPERTY_LICENCE_TECH_SETUP]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            "products" : {
                path: "/property-licences/:id/technical-setup/products",
                class: "propertyLicences-technicalSetup-products",
                component: PropertyLicenceTabsLayout,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.PROPERTY_LICENCE_TECH_SETUP]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            "streamDelayTypes" : {
                path: "/property-licences/:id/technical-setup/stream-delay-types",
                class: "propertyLicences-technicalSetup-streamDelayTypes",
                component: PropertyLicenceTabsLayout,
                public: true
            },
            "slates": {
                "index": {
                    component: PropertyLicenceTabsLayout,
                    path: "/property-licences/:id/technical-setup/slates",
                    authorization: {
                        [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.PROPERTY_LICENCE_TECH_SETUP]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.SLATE]: CONST.SECURITY_PRIVILEGES_READ,
                    },
                },
                "add": {
                    component: PropertyLicenceTabsLayout,
                    path: "/property-licences/:id/technical-setup/slates/add",
                    authorization: {
                        [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.PROPERTY_LICENCE_TECH_SETUP]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.SLATE]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                    },
                },
                "edit": {
                    component: PropertyLicenceTabsLayout,
                    path: "/property-licences/:id/technical-setup/slates/:slateId",
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                    authorization: {
                        [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.PROPERTY_LICENCE_TECH_SETUP]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.SLATE]: CONST.SECURITY_PRIVILEGES_READ,
                    },
                }
            },
            'overlays': {
                'index': {
                    path: '/property-licences/:id/technical-setup/overlays',
                    component: PropertyLicenceTabsLayout,
                    authorization: {
                        [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.PROPERTY_LICENCE_TECH_SETUP]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.OVERLAY]: CONST.SECURITY_PRIVILEGES_READ,
                    },
                },
                'add': {
                    component: PropertyLicenceTabsLayout,
                    path: '/property-licences/:id/technical-setup/overlays/add',
                    authorization: {
                        [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.PROPERTY_LICENCE_TECH_SETUP]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.OVERLAY]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                    },
                },
                'edit': {
                    component: PropertyLicenceTabsLayout,
                    path: '/property-licences/:id/technical-setup/overlays/:overlayId',
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                    authorization: {
                        [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.PROPERTY_LICENCE_TECH_SETUP]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.OVERLAY]: CONST.SECURITY_PRIVILEGES_READ,
                    },
                },
            },
        },
        "rightsScopes": {
            "index" : {
                path: "/property-licences/:id/rights-scope",
                class: "propertyLicences-rights-scopes-index",
                component: PropertyLicenceTabsLayout,
                excelExport: true,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RIGHT_SCOPE]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            "add" : {
                path: "/property-licences/:id/rights-scope/add",
                class: "propertyLicences-rights-scopes-edit",
                component: PropertyLicenceTabsLayout,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RIGHT_SCOPE]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                },
            },
            "editGeneralInformation" : {
                path: "/property-licences/:id/rights-scope/:rightsScopeId",
                class: "propertyLicences-rights-scopes-edit",
                component: PropertyLicenceTabsLayout,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RIGHT_SCOPE]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            "editGeoRestrictions" : {
                path: "/property-licences/:id/rights-scope/:rightsScopeId/geo-restrictions",
                class: "propertyLicences-rights-scopes-editGeoRestrictions",
                component: PropertyLicenceTabsLayout,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RIGHT_SCOPE]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            "editStreamConstraints": {
                path: "/property-licences/:id/rights-scope/:rightsScopeId/stream-constraints",
                class: "propertyLicences-rights-scopes-editStreamConstraints",
                component: PropertyLicenceTabsLayout,
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RIGHT_SCOPE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.PROPERTY_LICENCE_STREAM_CONSTRAINT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            }
        },
        "events": {
            "index": {
                class: "propertyLicences-events-index",
                component: PropertyLicenceTabsLayout,
                excelExport: true,
                path: "/property-licences/:id/events",
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            }
        },
        "eventPresets": {
            "index": {
                component: PropertyLicenceTabsLayout,
                excelExport: true,
                path: "/property-licences/:id/event-presets",
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT_PRESET]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            "edit": {
                class: "propertyLicences-eventPresets-index",
                component: PropertyLicenceTabsLayout,
                path: "/property-licences/:id/event-presets/:eventPresetId",
                authorization: {
                    [RESOURCES.PROPERTY_LICENCE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT_PRESET]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
        },
    },
    clipConfiguration : {
        index : {
            path : '/clip-configuration',
            component : ClipConfigurationIndex,
            title: 'Content Categories',
            authorization: {
                [RESOURCES.CLIP_CONFIGURATION]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        add: {
            'addOnSportLevel': {
                path : '/clip-configuration/add-on-sport-level',
                component : ClipConfigurationIndex,
                title: 'Clip configuration - Add new configuration on sport level - AV-CMP',
                authorization: {
                    [RESOURCES.CLIP_CONFIGURATION]: CONST.SECURITY_PRIVILEGES_CREATE,
                },
            },
            'addOnTournamentLevel': {
                path : '/clip-configuration/add-on-tournament-level',
                component : ClipConfigurationIndex,
                title: 'Clip configuration - Add new configuration on tournament level - AV-CMP',
                authorization: {
                    [RESOURCES.CLIP_CONFIGURATION]: CONST.SECURITY_PRIVILEGES_CREATE,
                },
            },

        },
        edit: {
            path: '/clip-configuration/:id',
            component: ClipConfigurationIndex,
            title: 'Edit Clip Configuration',
            authorization: {
                [RESOURCES.CLIP_CONFIGURATION]: CONST.SECURITY_PRIVILEGES_CREATE_READ_UPDATE,
            },
        },
    },
    content_categories : {
        'index' : {
            path : '/content-categories',
            component : ContentCategoriesIndex,
            title: 'Content Categories',
            authorization: {
                [RESOURCES.CONTENT_CATEGORY]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        'add' : {
            path : '/content-categories/:levelId/add',
            component : ContentCategoriesIndex,
            title: 'Content Categories Add',
            authorization: {
                [RESOURCES.CONTENT_CATEGORY]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
            },
        },
        'edit' : {
            path : '/content-categories/:levelId/:id',
            component : ContentCategoriesIndex,
            title: 'Content Categories',
            authorization: {
                [RESOURCES.CONTENT_CATEGORY]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
    },
    blackoutZones: {
        index: {
            path : '/blackout-zones',
            component : BlackoutZoneIndex,
            title: 'Blackout Zone',
            authorization: {
                [RESOURCES.BLACKOUT_ZONE]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        add: {
            path : '/blackout-zones/add',
            component : BlackoutZoneEdit,
            title: 'Blackout Zones - Add new Blackout Zone',
            authorization: {
                [RESOURCES.BLACKOUT_ZONE]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
            },
        },
        edit: {
            generalInformation: {
                path: '/blackout-zones/:id',
                component: BlackoutZoneEdit,
                title: 'Blackout Zones - edit',
                authorization: {
                    [RESOURCES.BLACKOUT_ZONE]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            postalCodes: {
                index: {
                    path: '/blackout-zones/:id/postal-codes',
                    component: BlackoutZoneEdit,
                    title: 'Blackout Zones - postal codes',
                    authorization: {
                        [RESOURCES.BLACKOUT_ZONE]: CONST.SECURITY_PRIVILEGES_READ,
                    },
                },
                add: {
                    path: '/blackout-zones/:id/postal-codes/add',
                    component: BlackoutZoneEdit,
                    title: 'Blackout Zones - postal codes',
                    authorization: {
                        [RESOURCES.BLACKOUT_ZONE]: CONST.SECURITY_PRIVILEGES_UPDATE,
                    },
                },
            },
            countriesAndSubdivisions: {
                path: '/blackout-zones/:id/countries-and-subdivisions',
                component: BlackoutZoneEdit,
                title: 'Blackout Zones - countries and subdivisions',
                authorization: {
                    [RESOURCES.BLACKOUT_ZONE]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
        },
    },
    clientPackages: {
        path: '/clients/packages',
        component: ClientPackagesIndex,
        title: 'AV-CMP - Client packages',
        excelExport: true,
        authorization: {
            [RESOURCES.CLIENT_PACKAGE]: CONST.SECURITY_PRIVILEGES_READ,
        },
        edit : {
            path: '/clients/:id/packages/:packagesId',
            component: ClientTabsLayout,
            authorization: {
                [RESOURCES.CLIENT_PACKAGE] : CONST.SECURITY_PRIVILEGES_READ_UPDATE,
            },
        },
    },
    clients: {
        index : {
            path: '/clients',
            component: ClientIndex,
            excelExport: true,
            title: 'Clients',
            authorization: {
                [RESOURCES.CLIENT]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        add: {
            path: '/clients/add',
            component: ClientTabsLayout,
            authorization: {
                [RESOURCES.CLIENT]: CONST.SECURITY_PRIVILEGES_CREATE,
            },
        },
        edit: {
            path: '/clients/:id',
            component: ClientTabsLayout,
            authorization: {
                [RESOURCES.CLIENT]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        contactPersons: {
            index: {
                path: '/clients/:id/contact-persons',
                component: ClientTabsLayout,
                resource: [RESOURCES.CLIENT, RESOURCES.CONTACT_PERSON],
                privileges: CONST.SECURITY_PRIVILEGES_READ,
                combinedResources: true,
            },
            add : {
                path: '/clients/:id/contact-persons/add',
                component: ClientTabsLayout,
                resource: [RESOURCES.CLIENT, RESOURCES.CONTACT_PERSON],
                privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                combinedResources: true,
            },
            edit : {
                path: '/clients/:id/contact-persons/:contactPersonId',
                component: ClientTabsLayout,
                resource: [RESOURCES.CLIENT, RESOURCES.CONTACT_PERSON],
                privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                combinedResources: true,
            },
        },
        products:{
            lcr: {
                add: {
                    path: '/clients/:id/products/add/lcr',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                },
                edit: {
                    path: '/clients/:id/products/lcr',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                editGeoRestrictions: {
                    path: "/clients/:id/products/lcr/geo-restrictions",
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                geolocation: {
                    index: {
                        path: '/clients/:id/products/lcr/geolocation',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                        combinedResources: true
                    },
                    add : {
                        path: '/clients/:id/products/lcr/geolocation/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                        combinedResources: true
                    },
                    edit : {
                        path: '/clients/:id/products/lcr/geolocation/:geolocationId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                        combinedResources: true
                    }
                },
            },
            lco: {
                add: {
                    path: '/clients/:id/products/add/lco',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                },
                edit: {
                    path: '/clients/:id/products/lco',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                editGeoRestrictions: {
                    path: "/clients/:id/products/lco/geo-restrictions",
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                geolocation: {
                    index: {
                        path: '/clients/:id/products/lco/geolocation',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                        combinedResources: true
                    },
                    add : {
                        path: '/clients/:id/products/lco/geolocation/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                        combinedResources: true
                    },
                    edit : {
                        path: '/clients/:id/products/lco/geolocation/:geolocationId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                        combinedResources: true
                    }
                },
            },
            lcpp: {
                add: {
                    path: '/clients/:id/products/add/lcp-p',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                },
                edit: {
                    path: '/clients/:id/products/lcp-p',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                editGeoRestrictions: {
                    path: "/clients/:id/products/lcp-p/geo-restrictions",
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                notificationHistory: {
                    path: '/clients/:id/products/lcp-p/notification-history',
                    component: ClientTabsLayout,
                    authorization: {
                        [RESOURCES.CLIENT]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.CLIENT_PRODUCT]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.CLIENT_LCP_P_NOTIFICATION]: CONST.SECURITY_PRIVILEGES_READ,
                    },
                },
                geolocation: {
                    index: {
                        path: '/clients/:id/products/lcp-p/geolocation',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                        combinedResources: true
                    },
                    add : {
                        path: '/clients/:id/products/lcp-p/geolocation/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                        combinedResources: true
                    },
                    edit : {
                        path: '/clients/:id/products/lcp-p/geolocation/:geolocationId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                        combinedResources: true
                    }
                },
            },
            lct: {
                add: {
                    path: '/clients/:id/products/add/lct',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                },
                edit: {
                    path: '/clients/:id/products/lct',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                editGeoRestrictions: {
                    path: "/clients/:id/products/lct/geo-restrictions",
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                geolocation: {
                    index: {
                        path: '/clients/:id/products/lct/geolocation',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                        combinedResources: true
                    },
                    add : {
                        path: '/clients/:id/products/lct/geolocation/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                        combinedResources: true
                    },
                    edit : {
                        path: '/clients/:id/products/lct/geolocation/:geolocationId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                        combinedResources: true
                    }
                },
            },
            spott: {
                add: {
                    path: '/clients/:id/products/add/spott',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                },
                edit: {
                    path: '/clients/:id/products/spott',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                editGeoRestrictions: {
                    path: "/clients/:id/products/spott/geo-restrictions",
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                geolocation: {
                    index: {
                        path: '/clients/:id/products/spott/geolocation',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                        combinedResources: true
                    },
                    add : {
                        path: '/clients/:id/products/spott/geolocation/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                        combinedResources: true
                    },
                    edit : {
                        path: '/clients/:id/products/spott/geolocation/:geolocationId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                        combinedResources: true
                    }
                },
            },
            thirdParty: {
                add: {
                    path: '/clients/:id/products/add/third-party',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                },
                edit: {
                    path: '/clients/:id/products/third-party',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                editGeoRestrictions: {
                    path: "/clients/:id/products/third-party/geo-restrictions",
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                geolocation: {
                    index: {
                        path: '/clients/:id/products/third-party/geolocation',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                        combinedResources: true
                    },
                    add : {
                        path: '/clients/:id/products/third-party/geolocation/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                        combinedResources: true
                    },
                    edit : {
                        path: '/clients/:id/products/third-party/geolocation/:geolocationId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                        combinedResources: true
                    }
                },
                cdns: {
                    index: {
                        path: '/clients/:id/products/third-party/cdns',
                        component: ClientTabsLayout,
                        excelExport: true,
                        authorization: {
                            [RESOURCES.THIRD_PARTY_CLIENT_CDN]: CONST.SECURITY_PRIVILEGES_READ
                        },
                    },
                    add: {
                        path: '/clients/:id/products/third-party/cdns/add',
                        component: ClientTabsLayout,
                        authorization: {
                            [RESOURCES.THIRD_PARTY_CLIENT_CDN]: CONST.SECURITY_PRIVILEGES_CREATE_READ
                        },
                    },
                    edit: {
                        path: '/clients/:id/products/third-party/cdns/:thirdPartyClientCdnId',
                        component: ClientTabsLayout,
                        authorization: {
                            [RESOURCES.THIRD_PARTY_CLIENT_CDN]: CONST.SECURITY_PRIVILEGES_READ
                        },
                    }
                },
                pullOrigins: {
                    index: {
                        path: '/clients/:id/products/third-party/pull-origins',
                        component: ClientTabsLayout,
                        excelExport: true,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_PULL_ORIGIN,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                    },
                    add: {
                        path: '/clients/:id/products/third-party/pull-origins/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_PULL_ORIGIN,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                    },
                    edit: {
                        path: '/clients/:id/products/third-party/pull-origins/:thirdPartyClientPullOriginId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_PULL_ORIGIN,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                    }
                },
                streamNamesPush: {
                    index: {
                        path: '/clients/:id/products/third-party/push-stream-names',
                        component: ClientTabsLayout,
                        excelExport: true,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_PUSH_STREAM_NAME,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                    },
                    add: {
                        path: '/clients/:id/products/third-party/push-stream-names/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_PUSH_STREAM_NAME,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                    },
                    edit: {
                        path: '/clients/:id/products/third-party/push-stream-names/:thirdPartyClientPushStreamNameId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_PUSH_STREAM_NAME,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                    }
                },
                streamNamesPull: {
                    index: {
                        path: '/clients/:id/products/third-party/pull-stream-names',
                        component: ClientTabsLayout,
                        excelExport: true,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_PULL_STREAM_NAME,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                    },
                    add: {
                        path: '/clients/:id/products/third-party/pull-stream-names/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_PULL_STREAM_NAME,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                    },
                    edit: {
                        path: '/clients/:id/products/third-party/pull-stream-names/:thirdPartyClientPullStreamNameId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_PULL_STREAM_NAME,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                    }
                },
                vodStorages: {
                    index: {
                        path: '/clients/:id/products/third-party/vod-storages',
                        component: ClientTabsLayout,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_VOD_STORAGE,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                        excelExport: true,
                    },
                    add: {
                        path: '/clients/:id/products/third-party/vod-storages/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_VOD_STORAGE,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                    },
                    edit: {
                        path: '/clients/:id/products/third-party/vod-storages/:thirdPartyClientVodStorageId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_VOD_STORAGE,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                    },
                },
            },
            lci: {
                add: {
                    path: '/clients/:id/products/add/lci',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                },
                edit: {
                    path: '/clients/:id/products/lci',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                editGeoRestrictions: {
                    path: "/clients/:id/products/lci/geo-restrictions",
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                geolocation: {
                    index: {
                        path: '/clients/:id/products/lci/geolocation',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                        combinedResources: true
                    },
                    add : {
                        path: '/clients/:id/products/lci/geolocation/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                        combinedResources: true
                    },
                    edit : {
                        path: '/clients/:id/products/lci/geolocation/:geolocationId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                        combinedResources: true
                    }
                },
            },
            qb: {
                add: {
                    path: '/clients/:id/products/add/qb',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                },
                edit: {
                    path: '/clients/:id/products/qb',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                editGeoRestrictions: {
                    path: "/clients/:id/products/qb/geo-restrictions",
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PRODUCT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                geolocation: {
                    index: {
                        path: '/clients/:id/products/qb/geolocation',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_READ,
                        combinedResources: true
                    },
                    add : {
                        path: '/clients/:id/products/qb/geolocation/add',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                        combinedResources: true
                    },
                    edit : {
                        path: '/clients/:id/products/qb/geolocation/:geolocationId',
                        component: ClientTabsLayout,
                        resource: RESOURCES.CLIENT_PRODUCT,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                        combinedResources: true
                    }
                },
            },
        },
        whitelistedIps: {
            index: {
                path: '/clients/:id/ip-whitelisting/geo-restrictions',
                component: ClientTabsLayout,
                excelExport: true,
                authorization: {
                    [RESOURCES.CLIENT_WHITELISTED_IP]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            add: {
                path: '/clients/:id/ip-whitelisting/geo-restrictions/add',
                component: ClientTabsLayout,
                authorization: {
                    [RESOURCES.CLIENT_WHITELISTED_IP]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                },
            },
            edit: {
                path: '/clients/:id/ip-whitelisting/geo-restrictions/:whitelistedIpId',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_WHITELISTED_IP,
                privileges: CONST.SECURITY_PRIVILEGES_READ_UPDATE,
            },
            avApiAccess: {
                index: {
                    path: '/clients/:id/ip-whitelisting/av-api-access',
                    component: ClientTabsLayout,
                    excelExport: true,
                    authorization: {
                        [RESOURCES.CLIENT_WHITELISTED_IP_AV_API_ACCESS]: CONST.SECURITY_PRIVILEGES_READ,
                    },
                },
                add : {
                    path: '/clients/:id/ip-whitelisting/av-api-access/add',
                    component: ClientTabsLayout,
                    authorization: {
                        [RESOURCES.CLIENT_WHITELISTED_IP_AV_API_ACCESS]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                    },
                },
                edit : {
                    path: '/clients/:id/ip-whitelisting/av-api-access/:whitelistedIpId',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_WHITELISTED_IP_AV_API_ACCESS,
                    privileges: CONST.SECURITY_PRIVILEGES_READ_UPDATE,
                },
            },
        },
        packages: {
            index: {
                path: '/clients/:id/packages',
                component: ClientTabsLayout,
                excelExport: true,
                resource: RESOURCES.CLIENT_PACKAGE,
                privileges: CONST.SECURITY_PRIVILEGES_READ
            },
            add : {
                path: '/clients/:id/packages/add',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_PACKAGE,
                privileges: CONST.SECURITY_PRIVILEGES_CREATE
            },
            edit : {
                path: '/clients/:id/packages/:packagesId',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_PACKAGE,
                privileges: CONST.SECURITY_PRIVILEGES_UPDATE
            },
            duplicate : {
                path: '/clients/:id/packages/:packagesId/duplicate',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_PACKAGE,
                privileges: CONST.SECURITY_PRIVILEGES_READ
            },
            geoRestrictions: {
                index: {
                    path: '/clients/:id/packages/:packagesId/geo-restrictions',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PACKAGE_GEO_RESTRICTION,
                    privileges: CONST.SECURITY_PRIVILEGES_READ
                },
                add: {
                    path: '/clients/:id/packages/:packagesId/geo-restrictions/add',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PACKAGE_GEO_RESTRICTION,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE
                },
                edit: {
                    path: '/clients/:id/packages/:packagesId/geo-restrictions/:geoRestrictionId',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PACKAGE_GEO_RESTRICTION,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE
                },
                editGeoRestrictions: {
                    path: '/clients/:id/packages/:packagesId/geo-restrictions/:geoRestrictionId/geo-restrictions',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PACKAGE_GEO_RESTRICTION,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE
                }
            },
            content: {
                index: {
                    path: '/clients/:id/packages/:packagesId/content',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PACKAGE_CONTENT,
                    privileges: CONST.SECURITY_PRIVILEGES_READ,
                },
                geoRestrictions: {
                    path: '/clients/:id/packages/:packageId/content/geo-restrictions/',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_PACKAGE_CONTENT,
                    authorization: {
                        [RESOURCES.CLIENT]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.CLIENT_PACKAGE]: CONST.SECURITY_PRIVILEGES_READ,
                        [RESOURCES.CLIENT_PACKAGE_CONTENT]: CONST.SECURITY_PRIVILEGES_READ_UPDATE,
                    },
                },
            },
        },
        clientAnalyticsSetup : {
            index: {
                path: '/clients/:id/analytics-setup',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_ANALYTICS_SETUP,
                privileges: CONST.SECURITY_PRIVILEGES_READ,
            },
            labels: {
                index: {
                    path: '/clients/:id/analytics-setup/labels',
                    component: ClientTabsLayout,
                    excelExport: true,
                    resource: RESOURCES.CLIENT_LABEL,
                    privileges: CONST.SECURITY_PRIVILEGES_READ,
                },
                add: {
                    path: '/clients/:id/analytics-setup/labels/add',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_LABEL,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                },
                edit: {
                    path: '/clients/:id/analytics-setup/labels/:clientLabelId',
                    component: ClientTabsLayout,
                    resource: RESOURCES.CLIENT_LABEL,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
        },
        eventBookings: {
            index: {
                path: '/clients/:id/event-bookings',
                component:ClientTabsLayout,
                authorization: {
                    [RESOURCES.EVENT_BOOKING]: CONST.SECURITY_PRIVILEGES_READ,
                },
                excelExport: true,
                resource: RESOURCES.EVENT_BOOKING,
                privileges: CONST.SECURITY_PRIVILEGES_READ,
            },
            edit: {
                path: '/clients/:id/event-bookings/:eventBookingId',
                component: ClientTabsLayout,
                authorization: {
                    [RESOURCES.EVENT_BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                resource: RESOURCES.EVENT_BOOKING,
                privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
            clips: {
                path: '/clients/:id/event-bookings/:eventBookingId/clips',
                component: ClientTabsLayout,
                authorization: {
                    [RESOURCES.EVENT_BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                resource: RESOURCES.EVENT_BOOKING,
                privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
            editGeoRestrictions: {
                path: '/clients/:id/event-bookings/:eventBookingId/geo-restrictions',
                component: ClientTabsLayout,
                authorization: {
                    [RESOURCES.EVENT_BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
                resource: RESOURCES.EVENT_BOOKING,
                privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
            blackoutZones: {
                path: '/clients/:id/event-bookings/:eventBookingId/blackout-zones',
                component: ClientTabsLayout,
                authorization: {
                    [RESOURCES.EVENT_BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
        },
        bookings: {
            index: {
                path: '/clients/:id/bookings',
                component: ClientTabsLayout,
                excelExport: true,
                resource: RESOURCES.BOOKING,
                privileges: CONST.SECURITY_PRIVILEGES_READ
            },
            edit: {
                path: '/clients/:id/bookings/:bookingId',
                component: ClientTabsLayout,
                resource: RESOURCES.BOOKING,
                privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
            editGeoRestrictions: {
                path: '/clients/:id/bookings/:bookingId/geo-restrictions',
                component: ClientTabsLayout,
                resource: RESOURCES.BOOKING,
                privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
            blackoutZones: {
                path: '/clients/:id/bookings/:bookingId/blackout-zones',
                component: ClientTabsLayout,
                authorization: {
                    [RESOURCES.BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
            bulkChange: {
                path: "/clients/:id/bookings/bulk-change/:bookingsIds",
                component: ClientTabsLayout,
                label: "Modify bookings",
                authorization: {
                    [RESOURCES.CLIENT]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
            bulkCancel: {
                path: "/clients/:id/bookings/bulk-cancellation/:bookingsIds",
                component: ClientTabsLayout,
                label: "Cancel bookings",
                authorization: {
                    [RESOURCES.CLIENT]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
        },
        offerings: {
            index: {
                path: '/clients/:id/offerings',
                component: ClientTabsLayout,
                excelExport: true,
                resource: RESOURCES.OFFERING,
                privileges: CONST.SECURITY_PRIVILEGES_READ
            },
        },
        clientCdnConfiguration : {
            index: {
                path: '/clients/:id/cdn-configurations',
                component: ClientTabsLayout,
                excelExport: true,
                resource: RESOURCES.CLIENT_CDN_CONFIGURATION,
                privileges: CONST.SECURITY_PRIVILEGES_READ,
            },
            add: {
                path: '/clients/:id/cdn-configurations/add',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_CDN_CONFIGURATION,
                privileges: CONST.SECURITY_PRIVILEGES_CREATE,
            },
            edit: {
                path: '/clients/:id/cdn-configurations/:clientCdnConfigurationId',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_CDN_CONFIGURATION,
                privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
            akamaiLiveStreamName : {
                index: {
                    path: '/clients/:id/cdn-configurations/:clientCdnConfigurationId/akamai-live-stream-names',
                    component: ClientTabsLayout,
                    resource: RESOURCES.AKAMAI_LIVE_STREAM_NAME,
                    privileges: CONST.SECURITY_PRIVILEGES_READ,
                    excelExport: true,
                },
                add: {
                    path: '/clients/:id/cdn-configurations/:clientCdnConfigurationId/akamai-live-stream-names/add',
                    component: ClientTabsLayout,
                    resource: RESOURCES.AKAMAI_LIVE_STREAM_NAME,
                    privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                },
                edit: {
                    path: '/clients/:id/cdn-configurations/:clientCdnConfigurationId/akamai-live-stream-names' +
                        '/:akamaiLiveStreamNameId',
                    component: ClientTabsLayout,
                    resource: RESOURCES.AKAMAI_LIVE_STREAM_NAME,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
        },
        drmSetup: {
            index: {
                path: '/clients/:id/drm-setup',
                component: ClientTabsLayout,
                resource: RESOURCES.DRM_SETUP,
                privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
        },
        apiKey : {
            index: {
                path: '/clients/:id/api-keys',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_API_KEY,
                privileges: CONST.SECURITY_PRIVILEGES_READ,
            },
            add: {
                path: '/clients/:id/api-keys/add',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_API_KEY,
                privileges: CONST.SECURITY_PRIVILEGES_CREATE,
            },
            edit: {
                path: '/clients/:id/api-keys/:clientApiKeyId',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_API_KEY,
                privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
        },
        clientVideoPlayer: {
            index: {
                path: '/clients/:id/video-player',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_VPL_LICENCE_KEY,
                privileges: CONST.SECURITY_PRIVILEGES_READ,
            },
            add: {
                path: '/clients/:id/video-player/add',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_VPL_LICENCE_KEY,
                privileges: CONST.SECURITY_PRIVILEGES_CREATE,
            },
            edit: {
                path: '/clients/:id/video-player/:clientVplLicenceKeyId',
                component: ClientTabsLayout,
                resource: RESOURCES.CLIENT_VPL_LICENCE_KEY,
                privileges: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
        },
    },
    productionCompanies: {
        index: {
            path: '/production-companies',
            component: ProductionCompanyIndex,
            excelExport: true,
            title: 'Production Companies',
            authorization: {
                [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_READ,
            }
        },
        add: {
            path: '/production-companies/add',
            component: ProductionCompanyTabsLayout,
            authorization: {
                [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
            }
        },
        edit: {
            path: '/production-companies/:id',
            component: ProductionCompanyTabsLayout,
            authorization: {
                [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_READ,
            }
        },
        contactPersons: {
            index: {
                path: '/production-companies/:id/contact-persons',
                component: ProductionCompanyTabsLayout,
                authorization: {
                    [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.CONTACT_PERSON]: CONST.SECURITY_PRIVILEGES_READ,
                }
            },
            add: {
                path: '/production-companies/:id/contact-persons/add',
                component: ProductionCompanyTabsLayout,
                authorization: {
                    [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.CONTACT_PERSON]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                }
            },
            edit: {
                path: '/production-companies/:id/contact-persons/:contactPersonId',
                component: ProductionCompanyTabsLayout,
                authorization: {
                    [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.CONTACT_PERSON]: CONST.SECURITY_PRIVILEGES_READ,
                }
            }
        },
        rtmpOriginStreamNames: {
            index: {
                path: '/production-companies/:id/rtmp-origin-stream-names',
                component: ProductionCompanyTabsLayout,
                excelExport: true,
                authorization: {
                    [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RTMP_ORIGIN_STREAM_NAME]: CONST.SECURITY_PRIVILEGES_READ,
                }
            },
            add: {
                path: '/production-companies/:id/rtmp-origin-stream-names/add',
                component: ProductionCompanyTabsLayout,
                authorization: {
                    [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RTMP_ORIGIN_STREAM_NAME]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                },
            },
            addMultiple: {
                path: '/production-companies/:id/rtmp-origin-stream-names/add-multiple',
                component: ProductionCompanyTabsLayout,
                authorization: {
                    [RESOURCES.RTMP_ORIGIN_STREAM_NAME]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RTMP_ORIGIN_STREAM_NAME]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                },
            },
            edit: {
                path: '/production-companies/:id/rtmp-origin-stream-names/:rtmpOriginStreamNameId',
                component: ProductionCompanyTabsLayout,
                authorization: {
                    [RESOURCES.PRODUCTION_COMPANY]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RTMP_ORIGIN_STREAM_NAME]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
        },
    },
    competitors : {
        "index" : {
            path : "/competitors",
            component : CompetitorIndex,
            excelExport: true,
            authorization: {
                [RESOURCES.AV_COMPETITOR]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        "add" : {
            path : "/competitors/add",
            component : CompetitorEdit,
            authorization: {
                [RESOURCES.AV_COMPETITOR]: CONST.SECURITY_PRIVILEGES_CREATE_READ
            }
        },
        "edit" : {
            path : "/competitors/:id",
            component : CompetitorEdit,
            authorization: {
                [RESOURCES.AV_COMPETITOR]: CONST.SECURITY_PRIVILEGES_READ
            }
        }
    },
    venues: {
        index: {
            path: "/venues",
            component: VenuesIndex,
            excelExport: true,
            authorization: {
                [RESOURCES.VENUE]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        add: {
            path: "/venues/add",
            component: VenueEdit,
            authorization: {
                [RESOURCES.VENUE]: CONST.SECURITY_PRIVILEGES_CREATE_READ
            }
        },
        edit: {
            path: "/venues/:id",
            component: VenueEdit,
            authorization: {
                [RESOURCES.VENUE]: CONST.SECURITY_PRIVILEGES_READ
            }
        }
    },
    wnsStreams: {
        index: {
            path: '/wns-streams',
            component: WnsStreamsIndex,
            title: 'World Number Service streams',
            excelExport: true,
            authorization: {
                [RESOURCES.WNS_STREAM]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        add: {
            path: '/wns-streams/add',
            component: WnsStreamsEdit,
            title: 'add World Number Service streams',
            authorization: {
                [RESOURCES.WNS_STREAM]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
            },
        },
        edit: {
            path: '/wns-streams/:id',
            component: WnsStreamsEdit,
            title: 'edit World Number Service streams',
            authorization: {
                [RESOURCES.WNS_STREAM]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        play: {
            path: '/wns-streams/:id/play',
            component: StreamPlaybackIndex,
            title: 'play World Number Service streams',
            authorization: {
                [RESOURCES.WNS_STREAM]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        debug: {
            path: '/wns-streams/:id/play/debug',
            component: StreamPlaybackIndex,
            title: 'play World Number Service streams',
            authorization: {
                [RESOURCES.WNS_STREAM]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
    },
    eventContentTypes: {
        index: {
            path: "/event-content-types",
            component: EventContentTypesIndex,
            excelExport: true,
            authorization: {
                [RESOURCES.EVENT_CONTENT_TYPE]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        add: {
            path: "/event-content-types/add",
            component: EventContentTypeEdit,
            authorization: {
                [RESOURCES.EVENT_CONTENT_TYPE]: CONST.SECURITY_PRIVILEGES_CREATE_READ
            }
        },
        edit: {
            path: "/event-content-types/:id",
            component: EventContentTypeEdit,
            authorization: {
                [RESOURCES.EVENT_CONTENT_TYPE]: CONST.SECURITY_PRIVILEGES_READ
            }
        }
    },
    mediaConnect: {
        index: {
            path: '/mediaconnect-origin-entry-points',
            component: MediaConnectIndex,
            excelExport: true,
            authorization: {
                [RESOURCES.MEDIA_CONNECT_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        add: {
            path: '/mediaconnect-origin-entry-points/add',
            class: 'mediaConnect-add',
            component: MediaConnectEdit,
            authorization: {
                [RESOURCES.MEDIA_CONNECT_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
            },
        },
        edit: {
            path: '/mediaconnect-origin-entry-points/:id',
            class: 'mediaConnect-edit',
            component: MediaConnectEdit,
            authorization: {
                [RESOURCES.MEDIA_CONNECT_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
    },
    rtmpOriginEntryPoint : {
        index : {
            path: '/rtmp-origin-entry-points',
            component: RtmpOriginEntryPointIndex,
            excelExport: true,
            title: 'Production Companies',
            authorization: {
                [RESOURCES.RTMP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        add: {
            path: '/rtmp-origin-entry-points/add',
            component: RtmpOriginEntryPointTabsLayout,
            resource: RESOURCES.RTMP_ORIGIN_ENTRY_POINT,
            authorization: {
                [RESOURCES.RTMP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_CREATE_READ
            }
        },
        edit: {
            path: '/rtmp-origin-entry-points/:id',
            component: RtmpOriginEntryPointTabsLayout,
            resource: RESOURCES.RTMP_ORIGIN_ENTRY_POINT,
            authorization: {
                [RESOURCES.RTMP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        applications : {
            index: {
                path: '/rtmp-origin-entry-points/:id/applications',
                component: RtmpOriginEntryPointTabsLayout,
                excelExport: true,
                authorization: {
                    [RESOURCES.RTMP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RTMP_ORIGIN_ENTRY_POINT_APPLICATION]: CONST.SECURITY_PRIVILEGES_READ,
                }
            },
            add: {
                path: '/rtmp-origin-entry-points/:id/applications/add',
                component: RtmpOriginEntryPointTabsLayout,
                authorization: {
                    [RESOURCES.RTMP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RTMP_ORIGIN_ENTRY_POINT_APPLICATION]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                }
            },
            edit: {
                path: '/rtmp-origin-entry-points/:id/applications/:applicationId',
                component: RtmpOriginEntryPointTabsLayout,
                authorization: {
                    [RESOURCES.RTMP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.RTMP_ORIGIN_ENTRY_POINT_APPLICATION]: CONST.SECURITY_PRIVILEGES_READ,
                }
            }
        }
    },
    sdiOriginEntryPoints : {
        index : {
            path: '/sdi-origin-entry-points',
            component: SdiOriginEntryPointIndex,
            excelExport: true,
            title: 'SDI origin entry points',
            authorization: {
                [RESOURCES.SDI_ORIGIN_ENTRY_POINTS]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        add : {
            path: '/sdi-origin-entry-points/add',
            component: SdiOriginEntryPointEdit,
            authorization: {
                [RESOURCES.SDI_ORIGIN_ENTRY_POINTS]: CONST.SECURITY_PRIVILEGES_CREATE_READ
            }
        },
        edit : {
            path: '/sdi-origin-entry-points/:id',
            component: SdiOriginEntryPointEdit,
            authorization: {
                [RESOURCES.SDI_ORIGIN_ENTRY_POINTS]: CONST.SECURITY_PRIVILEGES_READ
            }
        }
    },
    udpOriginEntryPoint: {
        index: {
            path: "/udp-origin-entry-points",
            class: "udpOriginEntryPoint-index",
            component: UdpOriginEntryPointIndex,
            excelExport: true,
            title: "UDP origin entry points",
            authorization: {
                [RESOURCES.UDP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        add: {
            path: "/udp-origin-entry-points/add",
            class: "udpOriginEntryPoint-add",
            component: UdpOriginEntryPointEdit,
            authorization: {
                [RESOURCES.UDP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_CREATE_READ
            }
        },
        edit: {
            path: "/udp-origin-entry-points/:id",
            class: "udpOriginEntryPoint-edit",
            component: UdpOriginEntryPointEdit,
            authorization: {
                [RESOURCES.UDP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
    },
    adSplice: {
        index: {
            path: '/ad-splice-configs',
            component: AdSpliceIndex,
            excelExport: true,
            authorization: {
                [RESOURCES.AD_SPLICE_CONFIG]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        add: {
            path: '/ad-splice-configs/add',
            class: 'adSplice-add',
            component: AdSpliceEdit,
            authorization: {
                [RESOURCES.AD_SPLICE_CONFIG]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
            },
        },
        edit: {
            path: '/ad-splice-configs/:id',
            class: 'adSplice-edit',
            component: AdSpliceEdit,
            authorization: {
                [RESOURCES.AD_SPLICE_CONFIG]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
    },
    events: {
        "index": {
            path: "/events",
            component: EventsIndex,
            excelExport: true,
            authorization: {
                [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        "add": {
            "sportradarExisting": {
                path: "/events/add/sportradar/:matchId",
                component: EventsIndex,
                label: "Sportradar event",
                authorization: {
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                },
            },
            "sportradarNonExisting": {
                path: "/events/add/sportradar",
                component: EventsIndex,
                label: "Sportradar event",
                authorization: {
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                },
            },
            "nonSportradar": {
                path: "/events/add/non-sportradar",
                component: EventsIndex,
                label: "Non-Sportradar event",
                authorization: {
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                },
            },
            "multiple": {
                path: "/events/add/multi-sportradar/:matchIds",
                component: EventsIndex,
                label: "Create Sportradar events",
                authorization: {
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                },
            },
            "presetMultiple": {
                path: "/events/apply-preset/:eventsIds",
                component: EventsIndex,
                label: "Events - Apply preset",
                authorization: {
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ_UPDATE,
                },
            },
            "setCoverage": {
                path: "/events/set-coverage/:eventsIds",
                component: EventsIndex,
                label: "Events - Set coverage",
                authorization: {
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ_UPDATE,
                },
            },
        },
        'edit': {
            path: '/events/:id/edit',
            component: EventsTabLayout,
            authorization: {
                [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
        },
        "linkToSportradarMatch" : {
            path: "/events/:id/link-to-sportradar-match",
            component: EventsTabLayout,
            authorization: {
                [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
        },
        "selection": {
            "eventGroupNotification": {
                path: "/events/event-group-notifications",
                component: EventsIndex,
                label: "Event group notification",
                authorization: {
                    [RESOURCES.EVENT_GROUP_NOTIFICATION]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                },
            },
        },
        'configuration': {
            'index': {
                path: '/events/:id',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            'content': {
                'add': {
                    path: '/events/:id/contents/add',
                    component: EventsTabLayout,
                    authorization: {
                        [RESOURCES.EVENT_CONTENT]: CONST.SECURITY_PRIVILEGES_CREATE,
                    },
                },
                'edit': {
                    path: '/events/:id/contents/:contentId',
                    component: EventsTabLayout,
                    authorization: {
                        [RESOURCES.EVENT_CONTENT]: CONST.SECURITY_PRIVILEGES_READ,
                    },
                },
                'input': {
                    path: '/events/:id/contents/:contentId/input',
                    component: EventsTabLayout,
                    resource: RESOURCES.EVENT_CONTENT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE
                },
                'distribution': {
                    path: '/events/:id/contents/:contentId/distribution',
                    component: EventsTabLayout,
                    authorization: {
                        [RESOURCES.EVENT_CONTENT]: CONST.SECURITY_PRIVILEGES_UPDATE,
                    },
                },
                broadcastStatusChangelog: {
                    path: '/events/:id/contents/:contentId/distributions/:distributionId/broadcast-status-changelog',
                    component: EventsTabLayout,
                    authorization: {
                        [RESOURCES.EVENT_CONTENT]: CONST.SECURITY_PRIVILEGES_READ,
                    },
                },
                'target': {
                    'add': {
                        path: '/events/:id/contents/:contentId/targets/add',
                        component: EventsTabLayout,
                        authorization: {
                            [RESOURCES.ENCODING_TARGET]: CONST.SECURITY_PRIVILEGES_CREATE,
                        },
                    },
                    "edit": {
                        path: "/events/:id/contents/:contentId/targets/:targetId",
                        component: EventsTabLayout,
                        resource: RESOURCES.ENCODING_TARGET,
                        privileges: CONST.SECURITY_PRIVILEGES_UPDATE
                    },
                    "audio": {
                        path: "/events/:id/contents/:contentId/targets/:targetId/audio",
                        component: EventsTabLayout,
                        authorization: {
                            [RESOURCES.AUDIO]: CONST.SECURITY_PRIVILEGES_READ,
                        },
                    },
                    "subtitles": {
                        path: '/events/:id/contents/:contentId/targets/:targetId/subtitles',
                        component: EventsTabLayout,
                        authorization: {
                            [RESOURCES.SUBTITLE]: CONST.SECURITY_PRIVILEGES_READ,
                        },
                    }
                },
                "cdnConfiguration": {
                    "add": {
                        path: "/events/:id/contents/:contentId/add-client-cdn-configurations",
                        component: EventsTabLayout,
                        resource: RESOURCES.CLIENT_CDN_CONFIGURATION,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE
                    },
                },
                "thirdPartyClientVodStorage": {
                    "add": {
                        path: "/events/:id/contents/:contentId/add-third-party-client-vod-storages",
                        component: EventsTabLayout,
                        resource: RESOURCES.THIRD_PARTY_CLIENT_VOD_STORAGE,
                        privileges: CONST.SECURITY_PRIVILEGES_CREATE,
                    },
                },
                "thirdPartyClients": {
                    path: "/events/:id/contents/:contentId/third-party-clients",
                    component: EventsTabLayout,
                    resource: RESOURCES.ENCODING_TARGET,
                    privileges: CONST.SECURITY_PRIVILEGES_READ
                },
                "changeEncodingStatus": {
                    path: "/events/:id/contents/:contentId/encoding-status",
                    component: EventsTabLayout,
                    resource: RESOURCES.EVENT_CONTENT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE
                },
                "changeEncodingStartTime": {
                    path: "/events/:id/contents/:contentId/encoding-start-time",
                    component: EventsTabLayout,
                    resource: RESOURCES.EVENT_CONTENT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE
                },
                "changeEncodingEndTime": {
                    path: "/events/:id/contents/:contentId/encoding-end-time",
                    component: EventsTabLayout,
                    resource: RESOURCES.EVENT_CONTENT,
                    privileges: CONST.SECURITY_PRIVILEGES_UPDATE
                },
                'releaseAkamaiStreamId': {
                    path: '/events/:id/contents/:contentId/release-akamai-stream-id',
                    component: EventsTabLayout,
                    authorization: {
                        [RESOURCES.EVENT_CONTENT]: CONST.SECURITY_PRIVILEGES_UPDATE,
                    },
                },
            },
        },
        'geoRestrictions': {
            'index': {
                path: '/events/:id/geo-restrictions',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT_GEO_RESTRICTION]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            'add': {
                path: '/events/:id/geo-restrictions/add',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT_GEO_RESTRICTION]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            'editGeneralInformation': {
                path: '/events/:id/geo-restrictions/:restrictionId',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT_GEO_RESTRICTION]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            'editGeoRestrictions': {
                path: '/events/:id/geo-restrictions/:restrictionId/geo-restrictions',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT_GEO_RESTRICTION]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            'blackoutZones': {
                path: '/events/:id/geo-restrictions/:restrictionId/blackout-zones',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.BLACKOUT_ZONE]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
        },
        'streamConstraints': {
            path: '/events/:id/stream-constraints',
            component: EventsTabLayout,
            authorization: {
                [RESOURCES.EVENT_STREAM_CONSTRAINT]: CONST.SECURITY_PRIVILEGES_READ,
                [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        'eventBookings': {
            'index': {
                path: '/events/:id/event-bookings',
                component: EventsTabLayout,
                excelExport: true,
                authorization: {
                    [RESOURCES.EVENT_BOOKING]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            'add': {
                path: '/events/:id/event-bookings/add',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT_BOOKING]: CONST.SECURITY_PRIVILEGES_CREATE,
                },
            },
            'edit': {
                path: '/events/:id/event-bookings/:eventBookingId',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT_BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
            'clips': {
                path: '/events/:id/event-bookings/:eventBookingId/clips',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT_BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
            'editGeoRestrictions': {
                path: '/events/:id/event-bookings/:eventBookingId/geo-restrictions',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT_BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
            'blackoutZones': {
                path: '/events/:id/event-bookings/:eventBookingId/blackout-zones',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT_BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
        },
        'bookings': {
            'index': {
                path: '/events/:id/bookings',
                component: EventsTabLayout,
                excelExport: true,
                authorization: {
                    [RESOURCES.BOOKING]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            'add': {
                path: '/events/:id/bookings/add',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.BOOKING]: CONST.SECURITY_PRIVILEGES_CREATE,
                },
            },
            'edit': {
                path: '/events/:id/bookings/:bookingId',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
            'editGeoRestrictions': {
                path: '/events/:id/bookings/:bookingId/geo-restrictions',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
            'blackoutZones': {
                path: '/events/:id/bookings/:bookingId/blackout-zones',
                component: EventsTabLayout,
                resource: RESOURCES.BOOKING,
                authorization: {
                    [RESOURCES.BOOKING]: CONST.SECURITY_PRIVILEGES_UPDATE,
                },
            },
        },
        'offerings': {
            'index': {
                path: '/events/:id/offerings',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.OFFERING]: CONST.SECURITY_PRIVILEGES_READ,
                },
                excelExport: true,
            },
        },
        'streams': {
            'index': {
                path: '/events/:id/streams',
                component: EventsTabLayout,
                excelExport: true,
                authorization: {
                    [RESOURCES.STREAM]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            'add': {
                path: '/events/:id/streams/add',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.STREAM]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            'edit': {
                path: '/events/:id/streams/:streamId',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.STREAM]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            play: {
                debug: {
                    origin: {
                        path: "/events/:id/contents/:eventContentId/play/origin/debug",
                        component: StreamPlaybackIndex,
                        resource: RESOURCES.STREAM,
                        privileges: CONST.SECURITY_PRIVILEGES_READ
                    },
                    stream: {
                        path: "/streams/:streamId/play/debug",
                        component: StreamPlaybackIndex,
                        resource: RESOURCES.STREAM,
                        privileges: CONST.SECURITY_PRIVILEGES_READ
                    },
                    event_stream: {
                        path: "/events/:id/contents/:eventContentId/play/:streamId/debug",
                        component: StreamPlaybackIndex,
                        resource: RESOURCES.STREAM,
                        privileges: CONST.SECURITY_PRIVILEGES_READ
                    },
                    manifest: {
                        path: "/events/:id/contents/:eventContentId/play/manifest/:encodingTargetId" +
                            "/:streamProtocolId/debug",
                        component: StreamPlaybackIndex,
                        resource: RESOURCES.STREAM,
                        privileges: CONST.SECURITY_PRIVILEGES_READ
                    },
                    manifest_akamai: {
                        path: "/events/:id/contents/:eventContentId/play/manifest/:encodingTargetId" +
                            "/:streamProtocolId/:akamaiRtmpDistrRegionId/debug",
                        component: StreamPlaybackIndex,
                        resource: RESOURCES.STREAM,
                        privileges: CONST.SECURITY_PRIVILEGES_READ
                    }
                },
                m3u: {
                    path: "/events/:id/contents/:eventContentId/play/origin/m3u",
                    component: StreamPlaybackIndex,
                    resource: RESOURCES.STREAM,
                    privileges: CONST.SECURITY_PRIVILEGES_READ
                },
                origin: {
                    path: "/events/:id/contents/:eventContentId/play/origin",
                    component: StreamPlaybackIndex,
                    resource: RESOURCES.STREAM,
                    privileges: CONST.SECURITY_PRIVILEGES_READ
                },
                stream: {
                    path: "/streams/:streamId/play",
                    component: StreamPlaybackIndex,
                    resource: RESOURCES.STREAM,
                    privileges: CONST.SECURITY_PRIVILEGES_READ
                },
                event_stream: {
                    path: "/events/:id/contents/:eventContentId/play/:streamId",
                    component: StreamPlaybackIndex,
                    resource: RESOURCES.STREAM,
                    privileges: CONST.SECURITY_PRIVILEGES_READ
                },
                manifest: {
                    path: "/events/:id/contents/:eventContentId/play/manifest/:encodingTargetId" +
                        "/:streamProtocolId",
                    component: StreamPlaybackIndex,
                    resource: RESOURCES.STREAM,
                    privileges: CONST.SECURITY_PRIVILEGES_READ
                },
                manifest_akamai: {
                    path: "/events/:id/contents/:eventContentId/play/manifest/:encodingTargetId" +
                        "/:streamProtocolId/:akamaiRtmpDistrRegionId",
                    component: StreamPlaybackIndex,
                    resource: RESOURCES.STREAM,
                    privileges: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
        },
        'lcppClientNotifications': {
            path: '/events/:id/lcp-p-client-notifications',
            component: EventsTabLayout,
            excelExport: true,
            authorization: {
                [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                [RESOURCES.CLIENT_LCP_P_NOTIFICATION]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        'stats': {
            path: '/events/:id/stats',
            component: EventsTabLayout,
            public: true,
        },
        'incidents': {
            'add': {
                path: '/events/:id/report/incidents/add',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT_INCIDENT]: CONST.SECURITY_PRIVILEGES_CREATE,
                    [RESOURCES.EVENT_REPORT]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
            'edit': {
                path: '/events/:id/report/incidents/:incidentId',
                component: EventsTabLayout,
                authorization: {
                    [RESOURCES.EVENT_INCIDENT]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT_REPORT]: CONST.SECURITY_PRIVILEGES_READ,
                    [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
        },
        "report": {
            path: "/events/:id/report",
            component: EventsTabLayout,
            public: true
        },
        'usageTypes': {
            path: '/events/:id/usage-types',
            component: EventsTabLayout,
            authorization: {
                [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_UPDATE,
            },
        },
        'reportMultipleAsWorkingAndInvoiceable': {
            path: '/events/report-as-working-and-invoiceable/:eventIds',
            component: EventsIndex,
            label: 'Report as working and invoiceable',
            authorization: {
                [RESOURCES.EVENT_REPORT]: [CONST.SECURITY_PRIVILEGES_CREATE, CONST.SECURITY_PRIVILEGES_UPDATE],
                [RESOURCES.EVENT_INVOICE_STATUS]: [CONST.SECURITY_PRIVILEGES_CREATE, CONST.SECURITY_PRIVILEGES_UPDATE],
            },
        },
    },
    contents: {
        index: {
            path: '/contents/:id',
            component: EventRedirectByEventContent,
            authorization: {
                [RESOURCES.EVENT_CONTENT]: CONST.SECURITY_PRIVILEGES_READ_UPDATE,
            },
        },
    },
    matches: {
        index: {
            path: '/matches/:id',
            component: EventRedirectByMatch,
            authorization: {
                [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
    },
    distributions: {
        index: {
            path: '/distributions/:id',
            component: EventRedirectByDistribution,
            authorization: {
                [RESOURCES.EVENT]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
    },
    bookmakers: {
        index: {
            path: '/bookmakers/:id/',
            component: ClientRedirectByBookmaker,
            authorization: {
                [RESOURCES.CLIENT]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
    },
    encodingJobProfile: {
        index: {
            title: 'Encoding job profiles',
            path: '/encoding-job-profiles',
            component: EncodingJobProfileIndex,
            excelExport: true,
            authorization: {
                [RESOURCES.ENCODING_JOB_PROFILE]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        edit: {
            title: 'Edit Encoding Job Profile',
            path: '/encoding-job-profiles/:id',
            component: EncodingJobProfileEdit,
            authorization: {
                [RESOURCES.ENCODING_JOB_PROFILE]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
    },
    changelog : {
        index : {
            path: '/changelog',
            component: ChangeLogIndex,
            excelExport: true,
            authorization: {
                [RESOURCES.CHANGELOG]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        view: {
            path: '/changelog/:id',
            component: ChangeLogIndex,
            authorization: {
                [RESOURCES.CHANGELOG]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        changelogElement: {
            path: '/changelog/resources/:resourceId/elements/:elementId',
            component: ChangelogElement,
            authorization: {
                [RESOURCES.CHANGELOG]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
    },
    users: {
        index: {
            path: "/users",
            component: UserIndex,
            class: "users-index",
            excelExport: true,
            icon: "fa fa-user",
            title: "User management",
            authorization: {
                [RESOURCES.USER]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        settings: {
            path: "/user-settings",
            component: UserSettings,
            iconName: "cog",
            authorization: {
                [RESOURCES.USER_SETTINGS]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        add: {
            path: "/users/add",
            class: "users-add",
            component: UsersAddIndex,
            icon: "fa fa-user",
            authorization: {
                [RESOURCES.USER]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
            },
        },
        add_user_from_existing_iam_account: {
            path: "/users/add/from-existing-iam-account/:email",
            class: "users-add",
            component: UserTabsLayout,
            icon: "fa fa-user",
            title: "Add new user",
            resource: RESOURCES.USER,
            privileges: CONST.SECURITY_PRIVILEGES_CREATE,
        },
        edit: {
            path: "/users/:id",
            class: "users-add",
            component: UserTabsLayout,
            icon: "fa fa-user",
            title: "Edit user",
            authorization: {
                [RESOURCES.USER]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        edit_resources: {
            path: "/users/:id/resources",
            class: "users-add",
            component: UserTabsLayout,
            icon: "fa fa-user",
            title: "Edit user",
            authorization: {
                [RESOURCES.USER]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        logout: {
            path: "/auth/logout",
            public: true,
        }
    },
    roles: {
        index: {
            path: "/roles",
            component: RolesIndex,
            class: "roles-index",
            excelExport: true,
            icon: "fa fa-user",
            title: "Role management",
            authorization: {
                [RESOURCES.ROLE]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        add: {
            path: "/roles/add",
            class: "roles-add",
            component: RoleTabsLayout,
            icon: "fa fa-user",
            authorization: {
                [RESOURCES.ROLE]: CONST.SECURITY_PRIVILEGES_CREATE_READ,
            },
        },
        edit: {
            path: "/roles/:id",
            class: "roles-edit",
            component: RoleTabsLayout,
            authorization: {
                [RESOURCES.ROLE]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        resources: {
            index: {
                path: "/roles/:id/resources",
                class: "contact-person-index",
                component: RoleTabsLayout,
                icon: "fa fa-user",
                title: "Resources",
                authorization: {
                    [RESOURCES.ROLE]: CONST.SECURITY_PRIVILEGES_READ,
                },
            },
        },
    },
    products: {
        index: {
            path: "/products",
            component: ProductIndex,
            excelExport: true,
            authorization: {
                [RESOURCES.PRODUCT]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        edit: {
            path: "/products/:id",
            component: ProductLayout,
            authorization: {
                [RESOURCES.PRODUCT]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
    },
    livecontrol : {
        index: {
            path: '/live-control',
            component: LiveControlIndex,
            authorization: {
                [RESOURCES.LIVE_CONTROL]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
    },
    ipBlacklisting: {
        index: {
            path: "/ip-blacklisting",
            component: IpBlacklistingIndex,
            excelExport: true,
            authorization: {
                [RESOURCES.BLACKLISTED_IP]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
        add: {
            path: "/ip-blacklisting/add",
            component: IpBlacklistingIndex,
            authorization: {
                [RESOURCES.BLACKLISTED_IP]: CONST.SECURITY_PRIVILEGES_CREATE_READ
            }
        },
        edit: {
            path: "/ip-blacklisting/:id",
            component: IpBlacklistingIndex,
            authorization: {
                [RESOURCES.BLACKLISTED_IP]: CONST.SECURITY_PRIVILEGES_READ
            }
        },
    },
    countries: {
        index: {
            path: '/countries',
            component: CountriesIndex,
            authorization: {
                [RESOURCES.COUNTRY]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        generalInformation: {
            path: '/countries/:id',
            component: CountryTabsLayout,
            authorization: {
                [RESOURCES.COUNTRY]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        subdivisions: {
            path: '/countries/:id/subdivisions',
            component: CountryTabsLayout,
            authorization: {
                [RESOURCES.COUNTRY]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
        postalCodes: {
            path: '/countries/:id/postal-codes',
            component: CountryTabsLayout,
            authorization: {
                [RESOURCES.COUNTRY]: CONST.SECURITY_PRIVILEGES_READ,
            },
        },
    },
    notFound: {
        path: "*",
        class: "notFound",
        component: NotFound,
        icon: "",
        title: "",
        public: true
    }
};

export default routes;
