import classnames from "classnames";
import PropTypes from "prop-types";
import * as React from "react";
import {Field} from "redux-form";
import {Form as SemanticForm, Radio} from "semantic-ui-react";

import CountriesSelection from "./ReduxFormControlsComponents/CountriesSelection";
import FilePreview from "./ReduxFormControlsComponents/FilePreview";
import FileUpload from "./ReduxFormControlsComponents/FileUpload";
import FormChangelog from "./FormChangelog";
import ReduxInput from "./ReduxFormControlsComponents/ReduxInput";
import ReduxSelect from "./ReduxFormControlsComponents/ReduxSelect";
import SemanticCheckboxList from "./ReduxFormControlsComponents/SemanticCheckboxList";
import SemanticCheckboxTree from "./ReduxFormControlsComponents/SemanticCheckboxTree";
import SemanticFileInput from "./ReduxFormControlsComponents/SemanticFileInput";
import SemanticInput from "./ReduxFormControlsComponents/SemanticInput";
import SemanticSelect from "./ReduxFormControlsComponents/SemanticSelect";
import SemanticTextArea from "./ReduxFormControlsComponents/SemanticTextArea";

import {sortByTextValue} from '../../../utils/sorters';

import {REQUIRE_THREE_LETTERS_PLACEHOLDER} from "../constants/variables";

/**
 *  New form
 */
export class Form{
    /**
     * Create html form tag
     *
     * @property {Function} onSubmit Function that will be called on submitting form, first parameter will be values from the form
     * @property {Boolean} loading Boolean value to show loader on form that user can't interact with form
     *
     * @example
     * <Form.Create
     * onSubmit={ this.callOnSubmit(values) }
     * loading={ true | false }
     * >
     */
    static Create = SemanticForm;

    /**
     * Render input
     *
     * @type {SemanticInput}
     * @see {@link SemanticInput}
     */
    static SemanticInput = SemanticInput;

    static ReduxInput = ReduxInput;

    /**
     * Render file input
     *
     * @type {SemanticFileInput}
     * @see {@link RenderSemanticFileInput}
     */
    static SemanticFileInput = SemanticFileInput;

    /**
     * Render file preview
     *
     * @type {FilePreview}
     * @see {@link FilePreview}
     */
    static FilePreview = FilePreview;

    /**
     * Render file upload
     *
     * @type {FileUpload}
     * @see {@link FileUpload}
     */
    static FileUpload = FileUpload;

    /**
     * Render select
     *
     * @type {SemanticSelect}
     * @see {@link SemanticSelect}
     */
    static SemanticSelect = SemanticSelect;

    static ReduxSelect = ReduxSelect;

    /**
     * Render textarea
     *
     * @type {SemanticTextArea}
     */
    static SemanticTextArea = SemanticTextArea;

    /**
     * Render dropdown for countries
     *
     * @type {CountriesSelection}
     */
    static CountriesSelection = CountriesSelection;

    /**
     * Render list of checkboxes
     *
     * @type {SemanticCheckboxList}
     * @see {@link SemanticCheckboxList}
     *
     * @example
     * <Form.FormRow
     *  required
     *  label="Content"
     *  name="contents"
     *  hidden={ this.state.includesAllContent }
     *  component={ Form.SemanticCheckboxList }
     *  options={ this.state.propertyLicenceContentOptions }
     *  defaultValue={ this.state.propertyLicenceContentSelected }
     * />
     */
    static SemanticCheckboxList = SemanticCheckboxList;

    /**
     * Render list of checkboxes in tree representation
     *
     * @type {SemanticCheckboxTree}
     * @see {@link SemanticCheckboxTree}
     */
    static SemanticCheckboxTree = SemanticCheckboxTree;

    static RenderRadio = (params) => {
        const additionalProps = {};

        if (params.label) {
            additionalProps.label = params.label
        }

        if (params.disabled) {
            additionalProps.disabled = params.disabled
        }

        if (params.className) {
            additionalProps.className = params.className
        }

        const onChange = () => {
            params.handleChange && params.handleChange(params)
        };

        return (<Radio
            checked={params.selected}
            onChange={onChange}
            {...additionalProps}
        />);
    };

    /**
     * @ignore
     */
    static IdField = (params) => {
        const elementProps = {
            ...params,
            label: params.label || null,
            type: params.type || "hidden",
            name: params.name || "id",
            component: SemanticInput
        };

        return (<Field {...elementProps}/>);
    };

    /**
     * @ignore
     */
    static InlineDateGroup = (props) => {
        const extendedProps = {
            className: classnames('inlineDateGroup', props.className || '')
        };

        if (props.gridColumnsWidth) {
            props.gridColumnsWidth.forEach(
                (el) => {
                    if (typeof el !== 'number') {
                        throw new Error('props gridColumnsWidth must be an array of numbers');
                    }

                    return el;
                }
            );

            extendedProps.style = {
                gridTemplateColumns: props.gridColumnsWidth.join('px ') + 'px'
            };
        }

        return (
            <div {...extendedProps}>
                {props.children}
            </div>
        );
    };

    /**
     * Function for getting row with label and redux form field
     *
     * All passed properties will be send to component that's is in component property
     *
     * @return
     * < div className="formRow">
     *     [COMPONENT]
     * </ div>
     */
    static FormRow = (props) => (
        <div className={classnames('formRow', {'hidden': props.hidden})}>
            <Field {...props}/>
        </div>
    );

    static FormMultipleRow = (props) => {
        const fieldsForRender = [];

        props.multipleData.forEach((fieldProps) => {
            fieldsForRender.push(
                <Field data={fieldProps.data}
                    key={fieldProps.key}
                    {...fieldProps.fieldDefaultValue}
                    {...fieldProps.props}
                    {...fieldProps.options}
                    {...fieldProps.fieldSpecificProps}
                    disabled={!fieldProps.isAuthorized}
                />
            )
        });

        return (
            <div className={classnames('formRow multiple-inline', {'hidden': props.hidden})}>
                {fieldsForRender}
            </div>
        );
    };

    /**
     *
     * @type {{return()}}
     */
    static FormField = (props) => (
        <Field {...props}/>
    );

    /**
     * @ignore
     */
    static FormRowName = (props) => {
        const elementProps = {
            ...props,
            type: "text",
            label: props.label || "Name",
            name: props.name || "name",
            placeholder: props.placeholder || "Add name",
            component: props.component || Form.SemanticInput
        };

        return Form.FormRow(elementProps);
    };


    /**
     * @ignore
     */
    static FormRowAddress = (props) => {
        const elementProps = {
            ...props,
            label: props.label || "Address",
            name: props.name || "address",
            rows: props.rows || 2,
            component: props.component || Form.SemanticTextArea
        };

        return Form.FormRow(elementProps);
    };

    /**
     * @ignore
     */
    static FormRowCountry = (props) => {
        const elementProps = {
            ...props,
            defaultValue: (props.value.country === null)
                ? ""
                : props.value.country.id,
            type: "select",
            label: props.label || "Country",
            name: props.name || "country_id",
            placeholder: props.placeholder || "Select country",
            component: props.component || Form.CountriesSelection
        };

        return Form.FormRow(elementProps);
    };

    /**
     * @ignore
     */
    static FormRowEncodingDatacenter = (props) => {
        const elementProps = {
            ...props,
            options: sortByTextValue(props.options),
            defaultValue: (props.value.encoding_datacenter === null)
                ? ""
                : props.value.encoding_datacenter.id,
            type: "select",
            label: props.label || "Encoding datacenter",
            name: props.name || "encoding_datacenter",
            placeholder: props.placeholder || "Select encoding datacenter",
            component: props.component || Form.CountriesSelection
        };

        return Form.FormRow(elementProps);
    };

    /**
     * @ignore
     */
    static FormRowBookmaker = (props) => {
        const elementProps = {
            ...props,
            defaultValue: (props.value.bookmaker === null)
                ? ""
                : props.value.bookmaker.id,
            type: "select",
            label: props.label || "Bookmaker",
            name: props.name || "bookmaker",
            placeholder: props.placeholder || REQUIRE_THREE_LETTERS_PLACEHOLDER,
            minCharacters: 3,
            component: props.component || Form.SemanticSelect,
            onSearchChangeSelect: props.onSearchChange
        };

        return Form.FormRow(elementProps);
    };

    /**
     * @ignore
     */
    static FormRowEmail = (props) => {
        const elementProps = {
            ...props,
            type: "email",
            label: props.label || "Email address",
            name: props.name || "email_address",
            placeholder: props.placeholder || "Add email address",
            component: props.component || Form.SemanticInput
        };

        return Form.FormRow(elementProps);
    };

    /**
     * @ignore
     */
    static FormRowPhoneNumber = (props) => {
        const elementProps = {
            ...props,
            type: "tel",
            label: props.label || "Phone number",
            name: props.name || "phone_number",
            placeholder: props.placeholder || "Add phone number",
            component: props.component || Form.SemanticInput
        };

        return Form.FormRow(elementProps);
    };

    /**
     * @ignore
     */
    static FormRowVatNumber = (props) => {
        const elementProps = {
            ...props,
            type: "text",
            label: props.label || "VAT number",
            name: props.name || "vat_number",
            placeholder: props.placeholder || "Add VAT number",
            component: props.component || Form.SemanticInput
        };

        return Form.FormRow(elementProps);
    };

    static FormRowText = (params) => Form.FormRow({
        ...params,
        type: "text",
        name: params.name || "",
        label: params.label || "",
        placeholder: params.placeholder || "",
        component: params.component || (params => (
            <div className="input-container">
                <label>{params.label}</label>
                <span>{params.defaultValue}</span>
            </div>
        )),
    });

    /**
     * @ignore
     */
    static FormRowNotes = (props) => {
        const elementProps = {
            ...props,
            label: props.label || "Notes",
            name: props.name || "notes",
            component: props.component || Form.SemanticTextArea
        };

        return Form.FormRow(elementProps);
    };

    /**
     * @ignore
     */
    static FormRowChangelog = (props) => <FormChangelog {...props} isForForm={true}/>;

    /**
     * @ignore
     */
    static FormRowFile = (params) => {
        const adaptFileEventToValue = delegate => e => {
            delegate(e.target.files[0]);
        };

        const FileInput = ({
            input: {
                onChange,
                ...inputProps
            },
            meta: omitMeta,
            ...props
        }) => {
            return (
                <div>
                    <input
                        onChange={adaptFileEventToValue(onChange)}
                        onBlur={adaptFileEventToValue(onChange)}
                        type="file"
                        {...inputProps}
                        {...props}
                    />
                    {/* eslint-disable-next-line react/prop-types */}
                    <div className="error">{omitMeta.touched && !omitMeta.valid ? omitMeta.error : ''}</div>
                </div>
            );
        };
        FileInput.propTypes = {
            input: PropTypes.shape({
                onChange: PropTypes.func,
            }),
            meta: PropTypes.shape({
                omitMeta: PropTypes.shape({
                    error: PropTypes.string,
                    touched: PropTypes.bool,
                    valid: PropTypes.bool,
                })
            }),
        };

        return (<Field component={FileInput} name={params.name} />);
    };

    static TextPlaceholder = ({label, defaultValue = '', className = '', isLabelVisible = true}) => (
        <div className={className}>
            {isLabelVisible && <label>{label}</label>}
            {defaultValue}
        </div>
    );

    static EmailPlaceholder = ({label, defaultValue = '', className = '', isLabelVisible = true}) => (
        <div className={className}>
            {isLabelVisible && <label>{label}</label>}
            <a href={'mailto:' + defaultValue} target='blank'>{defaultValue}</a>
        </div>
    );
}

export default Form;
