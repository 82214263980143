import Form from '@appComponents/ReduxFormControls';
import {EVENT_STATUS_PLANNED} from '@constants/variables';

import * as formUtils from '../utils/formHelper';

export function getEventDefaultFields() {
    return {
        start_datetime: {
            dataMapKey: 'event.start_datetime',
            props: {
                component: Form.SemanticInput,
                configuration: {
                    showTimeSelect: true,
                    timeFormat: 'HH:mm',
                    timeIntervals: 5,
                },
                type: 'date',
                label: 'Start time',
                required: true,
                name: 'start_datetime',
                selected: null,
                disabled: true,
            },
        },
        end_datetime: {
            dataMapKey: 'event.end_datetime',
            props: {
                configuration: {
                    showTimeSelect: true,
                    timeFormat: 'HH:mm',
                    timeIntervals: 5,
                },
                type: 'date',
                component: Form.SemanticInput,
                label: 'End time',
                name: 'end_datetime',
                selected: null,
                disabled: true,
            },
        },
        multiple: [
            {
                description: {
                    dataMapKey: 'event.description',
                    props: {
                        component: Form.SemanticInput,
                        label: 'Description',
                        name: 'description',
                        type: 'input',
                    },
                },
            },
            {
                color: {
                    dataMapKey: 'event.color',
                    props: {
                        component: Form.SemanticInput,
                        name: 'color',
                        type: 'color',
                    },
                    defaultValue: '3a87ad',
                },
            },
        ],
        status: {
            dataMapKey: 'event.event_status.id',
            optionsKey: 'event_status',
            props: {
                required: false,
                component: Form.SemanticSelect,
                name: 'event_status',
                label: 'Status',
            },
            defaultValue: EVENT_STATUS_PLANNED + '',
        },
        coverage: {
            props: {
                component: Form.SemanticCheckboxList,
                hidden: true,
                type: 'checkbox',
                name: 'coverage',
                label: 'Coverage',
                allowChildless: true,
                className: 'inlineCheckboxList',
            },
            defaultValue: [],
        },
        clip_provider: {
            dataMapKey: 'event.clip_provider.id',
            optionsKey: 'clip_provider',
            props: {
                hidden: true,
                component: Form.SemanticSelect,
                label: 'Clip provider',
                name: 'clip_provider',
                required: true,
            },
        },
        notes: {
            dataMapKey: 'event.notes',
            props: {
                component: Form.SemanticTextArea,
                label: 'Notes',
                name: 'notes',
            },
        },
    };
}

export function applyPresetFieldsForModel(isEdit = false) {
    const fields = {
        event_preset: {
            dataMapKey: 'event.event_preset.id',
            props: {
                component: formUtils.renderEventPresetDropdown,
                label: 'Apply preset',
                disabled: true,
                allowClear: true,
                name: 'event_preset',
                placeholder: 'Select preset',
                type: 'select',
            },
        },
    };

    if (true === isEdit) {
        fields.as_additional_contents = {
            dataMapKey: 'as_additional_contents',
            props: {
                component: Form.SemanticInput,
                type: 'toggle',
                name: 'as_additional_contents',
                label: 'Add contents as additional contents',
                hidden: true,
            },
        };
    }

    return fields;
}

export function presetFieldsForModel() {
    return {
        preset_save: {
            dataMapKey: null,
            props: {
                component: Form.SemanticInput,
                type: 'toggle',
                name: 'preset_save',
                label: 'Save as preset',
            },
        },
        preset_name: {
            dataMapKey: null,
            props: {
                component: Form.SemanticInput,
                hidden: true,
                required: true,
                type: 'text',
                name: 'preset_name',
                label: 'Preset name',
            },
        },
        preset_id: {
            dataMapKey: null,
            props: {
                component: Form.SemanticInput,
                hidden: true,
                name: 'preset_id',
            },
        },
        preset_overwrite: {
            dataMapKey: null,
            props: {
                component: Form.SemanticInput,
                hidden: true,
                type: 'checkbox',
                name: 'preset_overwrite',
                label: 'Overwrite applied preset?',
            },
        },
    };
}
