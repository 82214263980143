import React, {useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {Dimmer, Loader} from 'semantic-ui-react';
import {isEmpty as _isEmpty} from 'lodash';

import {createForm} from '@utils/forms';
import {convertToInt} from '@utils/helpers';
import ClientVplLicenceKeyForm from '@modules/client/forms/ClientVplLicenceKeyForm';
import ClientVplLicenceKeyModel from '@modules/client/forms/ClientVplLicenceKeyModel';
import {GetClientVplLicenceKeyForForm} from '@graphql/clientVplLicenceKey/query';
import {showErrorModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';

const ClientVplLicenceKeyEdit = ({clientId, clientVplLicenceKeyId = null}) => {
    const [domains, setDomains] = useState([]);
    const {data, loading, error} = useQuery(GetClientVplLicenceKeyForForm, {
        fetchPolicy: 'cache-and-network',
        variables: {id: clientVplLicenceKeyId},
        skip: !clientVplLicenceKeyId,
        onCompleted: (data) => {
            const clientVplLicenceKey = data.clientVplLicenceKey;
            setDomains(clientVplLicenceKey.client_vpl_domains);
        },
    });

    useEffect(() => {
        if (!_isEmpty(error)) {
            showErrorModal({
                header: 'Error',
                redirectLink: getLink('clients.edit', {id: clientId}),
                text: 'There was an error while fetching the data.',
                errorButtonIcon: 'arrow circle left',
                errorButtonText: 'Back to the client',
            });
        }
    }, [error]);

    const formData = data?.clientVplLicenceKey || {};

    const Form = useMemo(() => {
        return createForm(
            () => ClientVplLicenceKeyModel(domains, setDomains),
            ClientVplLicenceKeyForm, {
                id: clientVplLicenceKeyId,
                domains,
                setDomains,
                dataRequest: false,
                formData: formData,
                optionsVariables: {
                    client: convertToInt(clientId),
                },
            }
        );
    }, [domains, formData]);

    return (
        <>
            {loading ? <Dimmer data-testid={'dimmer-loader'} inverted active><Loader/></Dimmer> : <Form/>}
        </>
    );
};

ClientVplLicenceKeyEdit.propTypes = {
    clientId: PropTypes.number.isRequired,
    clientVplLicenceKeyId: PropTypes.string,
};

export default ClientVplLicenceKeyEdit;
