import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {Button, Grid} from 'semantic-ui-react';

import Form from '@appComponents/ReduxFormControls';
import * as Buttons from '@appComponents/ButtonCollection';
import validate from '@modules/client/forms/validatorClientVplDomains';
import {convertToInt} from '@utils/helpers';

const ClientVplDomainsForm = (props) => {
    const {
        formType,
        formData,
        domains,
        setDomains,
        onDeactivateEditableCell,
    } = props;

    const [domain, setDomain] = useState('');

    const handleOnChange = (event, value) => {
        const localValue = event?.target?.value || value;

        if (!localValue) {
            return;
        }

        setDomain(localValue);
    };

    const handleAddDomain = (e) => {
        e.preventDefault();

        if (domain && isValidDomain(domain)) {
            const newDomain = {
                domain: domain,
                is_disabled: false,
            };

            setDomains([newDomain, ...domains]);
        }

        setDomain('');
    };

    const isValidDomain = (domain) => {
        return !/^https?:\/\//.test(domain) && 100 >= domain.length;
    };

    const handleUpdateDomain = (e, id, editedDomain) => {
        e.preventDefault();

        if (domains && isValidDomain(editedDomain)) {
            const updatedDomains = domains.map((domain, index) => {
                if (index === convertToInt(id)) {
                    return {
                        domain: editedDomain,
                        is_disabled: domain.is_disabled,
                    };
                }

                return domain;
            });

            setDomains(updatedDomains);
        }
    };

    const renderClientVplDomainInput = (formType, handleOnChange) => {
        const isAddForm = 'form_cvd_add' === formType;
        const commonProps = {
            name: isAddForm ? 'client_vpl_domains' : 'client_vpl_domains_edit',
            className: isAddForm ? '--align-self-baseline' : '--align-self-baseline --max-width-600-edit',
            allowClear: true,
            component: Form.SemanticInput,
            onChange: handleOnChange,
            defaultValue: formData?.domain,
        };

        return (
            <Grid verticalAlign="top">
                <Grid.Row>
                    <Grid.Column width={13}>
                        <Form.FormRow
                            {...commonProps}
                            label={isAddForm ? 'Domains' : null}
                        />
                    </Grid.Column>
                    <Grid.Column width={3}>
                        {renderButtons(formType)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    const renderButtons = (formType) => {
        if ('form_cvd_add' === formType) {
            return (
                <Buttons.ButtonAdd
                    onClick={handleAddDomain}
                >
                    Add
                </Buttons.ButtonAdd>
            );
        } else {
            return (
                <Button.Group>
                    {domain && <Button
                        data-id={formData?.id}
                        data-testid='save-button'
                        icon='checkmark'
                        key='update-button'
                        positive
                        onClick={(e) => handleUpdateDomain(e, formData?.id, domain)}
                    />}
                    <Button
                        data-cancel-id={formData?.id}
                        data-testid='cancel-button'
                        icon='ban'
                        key='cancel-button'
                        negative
                        onClick={onDeactivateEditableCell}
                    />
                </Button.Group>
            );
        }
    };

    return (
        <>
            {renderClientVplDomainInput(formType, handleOnChange)}
        </>
    );
};

ClientVplDomainsForm.propTypes = {
    formType: PropTypes.string,
    formData: PropTypes.object,
    domains: PropTypes.array,
    setDomains: PropTypes.func,
    onDeactivateEditableCell: PropTypes.func,
};

const ClientVplDomainsFormRedux = reduxForm({
    form: 'ClientVplDomainsForm',
    validate: validate,
})(ClientVplDomainsForm);

export default ClientVplDomainsFormRedux;
