import { getLink } from '@appComponents/Link';
import { renderModalError } from '@utils/forms';
import { showModal } from '@utils/modal';

export const setLinkToIndexPage = (thisRef) => (thisRef.props.history.push(getLink('wnsStreams.index')));

export const handleErrorCallback = (error, entityLabel) => {
    showModal({
        isVisible: true,
        content: renderModalError(entityLabel, getLink('wnsStreams.index'), error.message, 'Back to list'),
    });
};
